import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { verifyEmail } from '../../state/account/actions'

interface Props {
  token: string
  user: any
  dispatch?: any
}

const mapStateToProps = (_state: any, ownProps: any) => {
  return {
    token: ownProps.match.params.token,
  }
}

const VerifyEmail: React.FC<Props> = ({ token, dispatch }) => {
  dispatch(verifyEmail(token))
  return <>{<Redirect to={{ pathname: '/login' }} />}</>
}

export default connect(mapStateToProps, null)(VerifyEmail)
