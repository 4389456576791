import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system'

import { Checkbox } from '@atlaskit/checkbox'
import Form, { Field } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { State } from '../../types'
import { LandingRightCol, LandingLeftCol, FormCol } from '../../components/styled/Column'
import { OrangeButton } from '../../components/styled/Button'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'
import { logout, getProfile } from '../../state/account/actions'

interface Props {
  user?: any
  dispatch?: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const user = state.account.login.user
  return {
    user,
  }
}

const Onboarding: React.FC<Props> = ({ user, dispatch }) => {
  const updateUserDetails = (values: { first_name: string; last_name: string }) => {
    axios({
      // Activate the invitation
      method: 'patch',
      url: `${process.env.REACT_APP_ACCMAN_URL}/me/${user.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        ...values,
      },
    })
      .then(async () => {
        await dispatch(getProfile())
      })
      .catch((error) => {
        dispatch(logout())
      })
  }
  return (
    <>
      {!user && <Redirect to={{ pathname: '/login' }} />}
      {user.first_name && <Redirect to={{ pathname: '/' }} />}
      <Container fluid style={{ width: '100vw', padding: 'unset' }}>
        <Row nowrap={true}>
          <LandingRightCol>
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto', padding: '100px' }} alt="Laava Manage" />
            <h1 style={{ textAlign: 'center', paddingBottom: '60px' }}>Login</h1>
            <FormCol>
              <Form onSubmit={updateUserDetails}>
                {({ formProps }) => (
                  <form {...formProps}>
                    <Field name="first_name" defaultValue="" label="First Name" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <TextField {...fieldProps} />
                        </Fragment>
                      )}
                    </Field>
                    <br />
                    <Field name="last_name" defaultValue="" label="Last Name" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <TextField {...fieldProps} />
                        </Fragment>
                      )}
                    </Field>
                    <br />

                    <div style={{ display: 'flex' }}>
                      <Field name="checkbox" defaultValue="" label="" isRequired>
                        {({ fieldProps }) => (
                          <Fragment>
                            <Row>
                              <Col sm={2}>
                                <Checkbox {...fieldProps} />
                              </Col>
                              <Col sm={10}>
                                <p>
                                  By creating an account, you agree to our
                                  <a
                                    href="https://laava.id/terms-and-conditions/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {' '}
                                    terms and conditions{' '}
                                  </a>
                                  and have read and understood the{' '}
                                  <a href="https://laava.id/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                    privacy policy
                                  </a>
                                  .
                                </p>
                                <br />
                              </Col>
                            </Row>
                          </Fragment>
                        )}
                      </Field>
                    </div>

                    <OrangeButton appearance="primary" type="submit">
                      Submit
                    </OrangeButton>
                  </form>
                )}
              </Form>
            </FormCol>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ height: '100vh', minHeight: '720px' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(Onboarding)
