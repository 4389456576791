/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import { Invitation, State, User } from '../../types'
import { getProfile, companiesInvalidate, invitationsInvalidate, logout } from '../../state/account/actions'
import { projectsInvalidate } from '../../state/projects/actions'

interface Props {
  token: string
  loginState: State['account']['login']
  currentUser?: State['account']['login']['user']
  dispatch: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    token: ownProps.match.params.token,
    loginState: state.account.login,
    currentUser: state.account.login?.user,
  }
}

const JoinCompany: React.FC<Props> = ({ token, loginState, currentUser, dispatch }) => {
  const [redirect, setRedirect] = useState<any>(null)
  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/${token}`,
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response: { data: { invitation: Invitation; recipient: User } }) => {
        const invitation = response.data.invitation
        const invitationRecipient = response.data.recipient
        if (!invitation.is_active) throw new Error()
        if (invitation.email === currentUser?.email) { // RECIPIENT IS ALREADY LOGGED IN
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/${token}/activate`,
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then(() => {
              // INVALIDATE STATE BECAUSE OF ROLE CHANGE
              dispatch(getProfile())
              dispatch(companiesInvalidate())
              dispatch(invitationsInvalidate())
              dispatch(projectsInvalidate())
              setRedirect(<Redirect to={{ pathname: `/login` }} />)
            })
        } else {
          if (loginState.loggedIn) { // RECIPIENT IS LOGGED INTO DIFFERENT ACCOUNT
            dispatch(logout())
          }
          if (invitationRecipient?.id) { // ACCOUNT FOR RECIPIENT EXISTS, THEY CAN LOG IN TO JOIN COMPANY
            setRedirect(
              <Redirect to={{ pathname: `/login`, state: { token, invitation } }} />,
            )
          } else { // ACCOUNT FOR RECIPIENT DOESN'T EXIST, THEY NEED TO REGISTER TO JOIN COMPANY
            setRedirect(<Redirect to={{ pathname: `/register`, state: { invitation } }} />)
          }
        }
      })
      .catch((_error) => {
        setRedirect(<Redirect to={{ pathname: '/login', state: { error: true } }} />)
      })
  }, [])

  return redirect
}

export default connect(mapStateToProps, null)(JoinCompany)
