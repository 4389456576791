import { PROJECTS_REQUEST, PROJECTS_RECEIVE, PROJECTS_INVALIDATE, PROJECTS_ERROR } from './actions'

const initialProjectsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

export const projects = (state = initialProjectsState, action: any) => {
  switch (action.type) {
    case PROJECTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case PROJECTS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.projects,
        lastFetched: Date.now(),
        didInvalidate: false,
      })
    case PROJECTS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case PROJECTS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      })
    default:
      return state
  }
}
