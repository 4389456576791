import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col } from 'react-grid-system'
import styled from 'styled-components'

import { Checkbox } from '@atlaskit/checkbox'
import Form, { Field, ErrorMessage as FormErrorMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'

import { Invitation, State } from '../../types'
import { LandingRightCol, LandingLeftCol, FormCol } from '../../components/styled/Column'
import { OrangeButton } from '../../components/styled/Button'
import { emailValidator, passwordValidator } from '../../helpers'
import { register, handleCompanyInviteRegister } from '../../state/account/actions'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'
import ErrorMessage from '../../components/react/error-message'

const RegisterButton = styled(OrangeButton)`
  margin: auto;
  display: block !important;
`

const PrefilledSectionMessage: React.FC<any> = ({ invitation }) => {
  let sectionTitle: string
  if (invitation?.project?.name) {
    sectionTitle = `Register your details to join the ${invitation?.project?.name} project`
  } else {
    sectionTitle = `Register your details to join the ${invitation?.company?.name} company`
  }
  return (
    <SectionMessage
      title={sectionTitle}
      appearance="information"
      actions={<SectionMessageAction href={'/logout'}>Go back</SectionMessageAction>}>
    </SectionMessage>
  )
}

interface Props {
  email?: string
  loggedIn?: boolean
  registerSuccess?: boolean
  registerError?: boolean
  history?: {
    invitation: Invitation
  }
  dispatch?: any
}

interface FormValues {
  first_name: string
  last_name: string
  email: string
  password: string
}

const mapStateToProps = (state: State, ownProps: any) => {
  const loggedIn = state.account.login.loggedIn
  const registerSuccess = state.account.register.registerSuccess
  const registerError = state.account.register.registerError

  return {
    loggedIn,
    registerSuccess,
    registerError,
    history: ownProps.history.location.state,
  }
}

const Register: React.FC<Props> = ({ loggedIn, registerSuccess, registerError, history, dispatch }) => {
  const isPrefilled = history ? true : false // Tells us if the user arrived via an invite link

  if (!isPrefilled) {
    // Sign up is disabled for new customers, however this page needs to be available for invited users
    // This page is made available when working locally, otherwise defaults to the Laava register page.
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') window.location.href = 'https://laava.id/register'
  }

  // This state is used purely for redirecting to the verification sent page with the registered email
  const [registeredEmail, setRegisteredEmail] = useState('')

  const handleRegister = (data: FormValues) => {
    setRegisteredEmail(data.email)
    dispatch(register(data))
  }

  // If the user arrived via an invitation link, we handle their invitation and sign them up
  const handleCompanyInvite = (data: FormValues) => {
    setRegisteredEmail(data.email)
    let values = {
      // Get form values
      ...data,
      company_id: history?.invitation.company_id, // Pulled from values on redirect
    }
    dispatch(handleCompanyInviteRegister(values, history?.invitation.token))
  }
  return (
    <>
      {registerSuccess && !isPrefilled && (
        <Redirect to={{ pathname: '/verification-sent', state: { email: registeredEmail } }} />
      )}
      {(loggedIn || (isPrefilled && registerSuccess)) && <Redirect to={{ pathname: '/' }} />}
      <Container fluid style={{ width: '100vw', padding: 'unset' }}>
        <Row nowrap={true}>
          <LandingRightCol>
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto', padding: '100px' }} alt="Laava Manage" />
            <h1 style={{ textAlign: 'center', paddingBottom: '60px' }}>Sign Up</h1>
            <FormCol>
              {registerError && <ErrorMessage title={'Error'} description={'Your invitation may have expired, or is no longer valid.'} />}
              <Form onSubmit={isPrefilled ? handleCompanyInvite : handleRegister}>
                {({ formProps }) => (
                  <form {...formProps}>
                    {isPrefilled && (
                        <PrefilledSectionMessage invitation={history?.invitation} />
                    )}
                    <Field name="first_name" defaultValue="" label="First Name" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <TextField {...fieldProps} />
                        </Fragment>
                      )}
                    </Field>
                    <Field name="last_name" defaultValue="" label="Last Name" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <TextField {...fieldProps} />
                        </Fragment>
                      )}
                    </Field>
                    {isPrefilled ? (
                      // If the user arrived via an invitation link, we prefill the email field and disable it.
                      <Field name="email" defaultValue={history?.invitation.email} label="Email" isDisabled>
                        {({ fieldProps }) => (
                          <Fragment>
                            <TextField {...fieldProps} />
                          </Fragment>
                        )}
                      </Field>
                    ) : (
                      // Otherwise we render the normal email field.
                      <Field name="email" defaultValue="" label="Email" isRequired validate={emailValidator}>
                        {({ fieldProps, error }) => (
                          <Fragment>
                            <TextField {...fieldProps} />
                            {error === 'INVALID' && <FormErrorMessage>Invalid email</FormErrorMessage>}
                          </Fragment>
                        )}
                      </Field>
                    )}
                    <Field name="password" defaultValue="" label="Password" isRequired validate={passwordValidator} >
                      {({ fieldProps, error }) => (
                        <Fragment >
                          <div style={{ marginBottom: '25px' }}>
                          <TextField {...fieldProps} type="password"  />
                          {error === 'TOO_SHORT' && <FormErrorMessage>Must be 8 or more characters</FormErrorMessage>}
                          {error === 'NO_NUMBERS' && <FormErrorMessage>Must include a number</FormErrorMessage>}
                            {error === 'NO_LETTERS' && <FormErrorMessage>Must include a letter</FormErrorMessage>}
                            </div>
                        </Fragment>
                      )}
                    </Field>

                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                      <Field name="checkbox" defaultValue="" label="" isRequired>
                        {({ fieldProps }) => (
                          <Fragment>
                            <Row>
                              <Col sm={2}>
                                <Checkbox {...fieldProps} />
                              </Col>
                              <Col sm={10}>
                                <p>
                                  By creating an account, you agree to our{' '}
                                  <a
                                    href="https://laava.id/terms-and-conditions/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    terms and conditions{' '}
                                  </a>
                                  and have read and understood the{' '}
                                  <a href="https://laava.id/privacy-policy/" target="_blank" rel="noopener noreferrer">
                                    privacy policy.
                                  </a>
                                </p>
                              </Col>
                            </Row>
                          </Fragment>
                        )}
                      </Field>
                    </div>

                    <RegisterButton appearance="primary" type="submit" style={{marginBottom: '30px'}}>
                      Create account
                    </RegisterButton>

                    <p style={{ textAlign: 'center' }}>
                      Already have an account? <a href="/login">Log in</a> instead.
                    </p>
                  </form>
                )}
              </Form>
            </FormCol>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ height: '100vh', minHeight: '720px' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(Register)
