import axios from 'axios'
import FormData from 'form-data'
import { loadCsv, parseCsv } from '../../helpers'

import { MetadataDownload, MetadataDownloadItem, MetadataDownloadStatus } from '../../types'

export const REQUEST_TAG_METADATA_UPLOAD = 'REQUEST_TAG_METADATA_UPLOAD'
export const TAG_METADATA_UPLOAD_ERROR = 'TAG_METADATA_UPLOAD_ERROR'
export const TAG_METADATA_UPLOAD_SUCCESS = 'TAG_METADATA_UPLOAD_SUCCESS'
export const TAG_METADATA_DOWNLOAD_ADD = 'TAG_METADATA_DOWNLOAD_ADD'
export const TAG_METADATA_DOWNLOADS_UPDATE = 'TAG_METADATA_DOWNLOADS_UPDATE'

export const requestTagMetadataUpload = () => {
  return {
    type: REQUEST_TAG_METADATA_UPLOAD,
  }
}

const tagMetadataUploadError = () => {
  return {
    type: TAG_METADATA_UPLOAD_ERROR,
  }
}

const tagMetadataUploadSuccess = () => {
  return {
    type: TAG_METADATA_UPLOAD_SUCCESS,
  }
}

interface UploadTagMetadataParams {
  file: File
  projectId: string
  collectionId: string
}

// Metadata can be updated via sequence numbers or individually. Here we check the file column headers to determine.
const checkUploadMethod = (csvString: string, delim = ',') => {
  const headers = csvString.slice(0, csvString.indexOf('\n')).split(delim)
  if (headers.includes('tag_id') || headers.includes('tagId') || headers.includes('tagid')) {
    return 'INDIVIDUAL'
  } else if (headers.includes('tag_index_start') && headers.includes('tag_index_end')) {
    return 'RANGE'
  } else {
    throw Error('Unable to find headers: [tag_id] or [tag_index_start, tag_index_end]')
  }
}

export const uploadTagMetadata = ({ file, projectId, collectionId }: UploadTagMetadataParams) => {
  return async (dispatch: any) => {
    try {
      dispatch(requestTagMetadataUpload())
      let uploadRequestPromises = []
      const csvString = await loadCsv(file)
      const uploadMethod = checkUploadMethod(csvString)

      switch (uploadMethod) {
        case 'INDIVIDUAL':
          var data = new FormData()
          data.append('tagMetadata', file)

          uploadRequestPromises.push(
            axios({
              method: 'put',
              url: `${process.env.REACT_APP_TMAPI_BASE_URL}/tag-metadata`,
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                project_id: projectId,
                'Content-Type': 'application/json',
              },
              data,
            }),
          )
          break

        case 'RANGE':
          const parsedCsv: any = parseCsv(csvString)
          for (const row of parsedCsv) {
            if (row.tag_index_start && row.tag_index_end) {
              let offset = row.tag_index_start
              let limit = row.tag_index_end - offset

              // Get other fields to be used as metadata
              let fields = row
              delete fields.tag_index_start
              delete fields.tag_index_end

              uploadRequestPromises.push(
                axios({
                  method: 'put',
                  url: `${
                    process.env.REACT_APP_TMAPI_BASE_URL
                  }/tag-metadata?collectionId=${collectionId}&offset=${offset}&limit=${limit}&data=${JSON.stringify(
                    fields,
                  )}`,
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    project_id: projectId,
                    'Content-Type': 'application/json',
                  },
                }),
              )
            }
          }
          break
        default:
          break
      }

      await Promise.all(uploadRequestPromises)
        .then((result) => {
          dispatch(tagMetadataUploadSuccess())
        })
        .catch((error) => {
          console.log(error)
          dispatch(tagMetadataUploadError())
        })
    } catch (error) {
      console.log(error)
      dispatch(tagMetadataUploadError())
    }
  }
}

//
// METADATA DOWNLOADING
//

export const addMetadataDownload = (newMetadataDownload: MetadataDownload) => {
  return {
    type: TAG_METADATA_DOWNLOAD_ADD,
    newMetadataDownload,
  }
}

export const updateMetadataDownload = (metadataDownload: MetadataDownloadItem) => {
  return (dispatch: any, getState: any) => {
    const currentMetadataDownloads: any = getState().tagMetadata.downloads.items
    const updatedMetadataDownloads = currentMetadataDownloads.map((currentMetadataDownload: any) => {
      if (currentMetadataDownload.id === metadataDownload.id) {
        const newMetadataDownload = Object.assign({}, currentMetadataDownload, {
          tagsComplete: [...currentMetadataDownload.tagsComplete, metadataDownload.tagsComplete],
          status: metadataDownload.status,
        })
        return newMetadataDownload
      } else {
        return currentMetadataDownload
      }
    })
    dispatch(updateMetadataDownloads(updatedMetadataDownloads))
  }
}

interface UpdateMetadataDownloadStatusParams {
  metadataDownloadId: string
  status: MetadataDownloadStatus
}

export const updateMetadataDownloadStatus = ({ metadataDownloadId, status }: UpdateMetadataDownloadStatusParams) => {
  return (dispatch: any, getState: any) => {
    const currentMetadataDownloads: any = getState().tagMetadata.downloads.items
    const updatedMetadataDownloads = currentMetadataDownloads.map((currentMetadataDownload: MetadataDownloadItem) => {
      if (currentMetadataDownload.id === metadataDownloadId) {
        const newMetadataDownload = Object.assign({}, currentMetadataDownload, {
          status: status,
        })
        return newMetadataDownload
      } else {
        return currentMetadataDownload
      }
    })
    dispatch(updateMetadataDownloads(updatedMetadataDownloads))
  }
}

interface UpdateMetadataDownloadTagsCompleteParams {
  metadataDownloadId: string
  tagsComplete: number
}

export const updateDownloadMetadataComplete = ({
  metadataDownloadId,
  tagsComplete,
}: UpdateMetadataDownloadTagsCompleteParams) => {
  return (dispatch: any, getState: any) => {
    const currentMetadataDownloads: any = getState().tagMetadata.downloads.items

    const updatedMetadataDownloads = currentMetadataDownloads.map((currentMetadataDownload: MetadataDownloadItem) => {
      if (currentMetadataDownload.id === metadataDownloadId) {
        const newMetadataDownload = Object.assign({}, currentMetadataDownload, {
          tagsComplete,
        })
        return newMetadataDownload
      } else {
        return currentMetadataDownload
      }
    })
    dispatch(updateMetadataDownloads(updatedMetadataDownloads))
  }
}

export const updateMetadataDownloads = (metadataDownloads: MetadataDownloadItem[]) => {
  return {
    type: TAG_METADATA_DOWNLOADS_UPDATE,
    metadataDownloads,
  }
}
