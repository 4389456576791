import React, { useState } from 'react'

import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { OrangeButton } from '../../../styled/Button'

interface NewCollectionModalProps {
  onSubmit: any
}

const NewCollectionModal: React.FC<NewCollectionModalProps> = ({ onSubmit }) => {
  // Modal open/close states
  const [isOpen, setIsOpen] = useState(false)
  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  const onFormSubmit = (data: Object) => {
    onSubmit(data)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Create
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div>
      <OrangeButton appearance="primary" onClick={open}>
        Create a New Collection
      </OrangeButton>
      <ModalTransition>
        {isOpen && (
          <ModalDialog
            heading="Create a new collection"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onFormSubmit}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: footer,
            }}
          >
            <Field name="collectionName" defaultValue="" label="Collection Name" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  )
}

export default NewCollectionModal
