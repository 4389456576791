import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Container, Row } from 'react-grid-system'

import { State } from '../../types'
import { LandingRightCol, LandingLeftCol } from '../../components/styled/Column'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'

interface Props {
  email?: string
}

const mapStateToProps = (state: State, ownProps: any) => {
  const email = state.account.login.user?.email || ownProps.location?.state.email
  return {
    email,
  }
}

const VerificationSent: React.FC<Props> = ({ email }) => {
  return (
    <>
      {!email && <Redirect to={{ pathname: '/login' }} />}
      <Container fluid style={{ width: '100vw', padding: 'unset' }}>
        <Row nowrap={true}>
          <LandingRightCol style={{ textAlign: 'center' }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto' }} alt="Laava Manage" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>Please verify your email.</h1>
            <br />
            <br />
            <br />
            <p>You're almost there! We sent an email to {email}.</p>
            <br />
            <p>
              Just click the link in that email to complete your signup. If you don't see it, you may need to check your
              spam folder.
            </p>
            <br />
            <p>
              Still can't find the email? <a href="mailto:hello@laava.id">Reach out to us</a> or,{' '}
              <a href="/logout">go back.</a>
            </p>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ height: '100vh', minHeight: '720px' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(VerificationSent)
