import { State } from '../../types'
import { TAG_ORDERS_REQUEST, TAG_ORDERS_RECEIVE, TAG_ORDERS_INVALIDATE, TAG_ORDERS_ERROR } from './actions'

const initialTagOrdersState: State['tagOrders'] = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

export const tagOrders = (state = initialTagOrdersState, action: any) => {
  switch (action.type) {
    case TAG_ORDERS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case TAG_ORDERS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.tagOrders,
        lastFetched: Date.now(),
        didInvalidate: false,
      })
    case TAG_ORDERS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case TAG_ORDERS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      })
    default:
      return state
  }
}
