import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import axios, { AxiosResponse } from 'axios'
import JSZip from 'jszip'
import _ from 'lodash'
import { saveAs } from 'file-saver'

// import { Col, Row } from 'react-grid-system'
import { ButtonGroup } from '@atlaskit/button'
// import InlineMessage from '@atlaskit/inline-message'
import Form, { Field, FormSection } from '@atlaskit/form'
// import CheckIcon from '@atlaskit/icon/glyph/check'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import UploadIcon from '@atlaskit/icon/glyph/upload'
import Page, { Grid, GridColumn } from '@atlaskit/page'
import PageHeader from '@atlaskit/page-header'
import Flag, { FlagGroup, FlagProps } from '@atlaskit/flag'
import InfoIcon from '@atlaskit/icon/glyph/info'
import SuccessIcon from '@atlaskit/icon/glyph/check-circle'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import Lozenge from '@atlaskit/lozenge'
import DynamicTable from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import ProgressBar from '@atlaskit/progress-bar'
import SectionMessage from '@atlaskit/section-message'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import { Restricted } from '../../providers/permission'

import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { uploadTagMetadata } from '../../state/tag-metadata/actions'
import { collectionsInvalidate } from '../../state/collections/actions'
import {
  Project,
  Collection,
  State,
  Tag,
  TagDownloadStatus,
  MetadataDownload,
  MetadataDownloadItem,
  MetadataDownloadFilters,
} from '../../types'
import { OrangeButton } from '../../components/styled/Button'
import NewTagMetadataDownloadModal, {
  NewMetaDownloadParams,
} from '../../components/react/modals/new-tag-metadata-download'
import {
  addMetadataDownload,
  updateMetadataDownloadStatus,
  updateDownloadMetadataComplete,
} from '../../state/tag-metadata/actions'
import {
  getCsvMetadataRangeString,
  getCsvMetadataString,
  getCsvMetadataStringWithoutSequence,
} from '../../helpers/tagMetadata'

const StyledDropzone = styled.div`
  width: 100%;
  height: 50px;
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  p {
    margin: 0;
  }

  span {
    vertical-align: middle;
    margin: 0 5px;
  }
`

const SubmitButton = styled(OrangeButton)`
  margin: 0;
`

const StyledTable = styled.table`
  font-size: 12px;

  th,
  td,
  tbody {
    padding: 2px;
    border: 1px solid black;
    border-collapse: collapse;
  }
`

interface Props {
  user: any
  collection: Collection
  project: Project
  tagMetadata: State['tagMetadata']
  dispatch: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const collection = state.collections.items.filter(
    (collection: Collection) => collection.id === ownProps.match.params.collectionId,
  )[0]
  const project = state.projects.items.filter((project: Project) => project.id === ownProps.match.params.projectId)[0]

  return {
    user: state.account.login.user,
    collection,
    project,
    tagMetadata: {
      ...state.tagMetadata,
      downloads: {
        items: state.tagMetadata.downloads.items.filter(
          (downloadItem) => downloadItem.collectionId === ownProps.match.params.collectionId,
        ),
      },
    },
  }
}

const CollectionMetadata: React.FC<Props> = ({ user, collection, project, tagMetadata, dispatch }) => {
  const [csvDataFile, setCsvDataFile] = useState<File>()
  const [isMetadataDownloadModalOpen, setIsMetadataDownloadModalOpen] = useState(false)
  const [flags, setFlags] = React.useState<Array<FlagProps>>([])

  useEffect(() => {
    dispatch(collectionsInvalidate())
  }, [collection.id, dispatch, project.id])

  const DropZoneComp: React.FC = () => {
    const onDrop = useCallback((acceptedFiles) => {
      setCsvDataFile(acceptedFiles[0])
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: ['text/csv', 'text/plain', 'application/vnd.ms-excel', '.csv'],
    })

    return (
      <div {...getRootProps()}>
        <StyledDropzone>
          <input {...getInputProps()} />
          <p>
            <UploadIcon size="medium" primaryColor="#bbb" label="" />
            {isDragActive ? <>Drop the file here ... </> : <>Drop a file here, or click to select a file.</>}
          </p>
        </StyledDropzone>
      </div>
    )
  }

  const onSubmitCsvDataFileUpload = () => {
    if (csvDataFile) {
      dispatch(uploadTagMetadata({ collectionId: collection.id, projectId: project.id, file: csvDataFile }))
      setCsvDataFile(undefined)
    }
  }

  const addFlag = (flag: FlagProps) => {
    const newFlags = flags.slice()
    newFlags.splice(0, 0, {
      ...flag,
    })
    setFlags(newFlags)
  }
  const handleDismiss = () => {
    setFlags(flags.slice(1))
  }

  useEffect(() => {
    // Toast based on state
    const currentTime = new Date().toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    if (tagMetadata.uploads.isUploading) {
      addFlag({
        id: flags.length + 1,
        appearance: 'info',
        title: `${currentTime}: Metadata uploading...`,
        icon: <InfoIcon label="Info" secondaryColor={'#42526E'} />,
      })
    } else if (tagMetadata.uploads.uploadSuccess) {
      addFlag({
        id: flags.length + 1,
        appearance: 'success',
        title: `${currentTime}: Metadata upload success`,

        icon: <SuccessIcon label="Success" secondaryColor={'#00875A'} />,
      })
    } else if (tagMetadata.uploads.uploadError) {
      addFlag({
        id: flags.length + 1,
        appearance: 'error',
        title: `${currentTime}: Metadata upload error`,
        icon: <ErrorIcon label="Error" secondaryColor={'#DE350B'} />,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagMetadata.uploads]) // Only run when tagMetadata state changes

  const prepareMetadataDownloads = (data: NewMetaDownloadParams) => {
    const { filenamePrefix, startTagOffset, tagCount, downloadMethod, filters } = data
    const tagsToDownload = Math.min(tagCount, collection.tagCount - startTagOffset)
    const status: TagDownloadStatus = 'queued'
    const downloadArchive: MetadataDownload = {
      fileName: `${filenamePrefix}.zip`,
      collectionId: collection.id,
      startTagOffset,
      tagCount: tagsToDownload,
      tagsComplete: 0,
      status,
      downloadMethod,
      filters,
    }

    dispatch(addMetadataDownload(downloadArchive))
  }

  const getTagData = async (
    startTagOffset: number,
    tagCount: number,
    metadataDownloadId: string,
    filters: MetadataDownloadFilters,
  ): Promise<Tag[]> => {
    return new Promise(async (resolve, reject) => {
      const CONCURRENT_CONNECTIONS = 10
      const DATA_PAGE_SIZE = 1000

      let tagPromises = []
      const tagData: AxiosResponse[] = []
      const tagsToProcess = tagCount

      for (var i = startTagOffset; i < startTagOffset + tagsToProcess; i += DATA_PAGE_SIZE * CONCURRENT_CONNECTIONS) {
        tagPromises = []

        for (var c = 0; c < CONCURRENT_CONNECTIONS; c += 1) {
          let offset = i + c * DATA_PAGE_SIZE
          const tagsLeft = tagsToProcess - (offset - startTagOffset)
          let limit = tagsLeft < DATA_PAGE_SIZE ? tagsLeft : DATA_PAGE_SIZE

          if (offset - startTagOffset < tagsToProcess) {
            let url = `${process.env.REACT_APP_TMAPI_BASE_URL}/tags?collectionIds[]=${collection.id}&fields=id,tagMetadata,createdAt,updatedAt&offset=${offset}&limit=${limit}`

            if (!_.isEmpty(filters?.metadata)) {
              url = url + '&metadata=' + JSON.stringify(filters.metadata)
            }

            const promise = axios.get(url, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                project_id: project.id,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            })
            tagPromises.push(promise)
          }
        }

        // eslint-disable-next-line no-loop-func
        tagPromises.forEach((promise) => {
          promise.then((response: AxiosResponse) => {
            dispatch(
              updateDownloadMetadataComplete({
                metadataDownloadId,
                tagsComplete: tagData.length * DATA_PAGE_SIZE + response.data.length,
              }),
            )
            tagData.push(response)
          })
        })
        await Promise.all(tagPromises)
      }

      const tags = tagData.reduce((arr, value) => {
        return arr.concat(value.data)
      }, [])

      resolve(tags)
    })
  }

  const onPrepareMetadataDownloads = async (newDownloadParams: NewMetaDownloadParams) => {
    let { filenamePrefix, tagCount, startTagOffset, downloadMethod, filters } = newDownloadParams
    if (!filenamePrefix) filenamePrefix = Math.random().toString(36).slice(2)
    if (!tagCount) tagCount = collection?.tagCount
    prepareMetadataDownloads({ filenamePrefix, tagCount, startTagOffset, downloadMethod, filters })
  }

  const processMetadataDownloads = async () => {
    const cache = await caches.open('laava-manage-metadata-download')

    if (tagMetadata.downloads.items) {
      for (var i = 0; i < tagMetadata.downloads.items.length; i += 1) {
        const { id, fileName, startTagOffset, tagCount, tagsComplete, downloadMethod, filters } =
          tagMetadata.downloads.items[i]

        if (tagsComplete === 0) {
          dispatch(updateMetadataDownloadStatus({ metadataDownloadId: id, status: 'downloading data' }))
          const tags = await getTagData(startTagOffset, tagCount, id, filters)
          dispatch(updateMetadataDownloadStatus({ metadataDownloadId: id, status: 'archiving' }))

          let csvString: string
          if (!_.isEmpty(filters.metadata)) {
            // Filters means the sequence numbers are wrong and need to be omitted
            csvString = getCsvMetadataStringWithoutSequence(tags)
          } else if (downloadMethod === 'individual') {
            csvString = getCsvMetadataString(tags)
          } else {
            csvString = getCsvMetadataRangeString(tags)
          }

          var zip = new JSZip()
          zip.file('manifest.csv', csvString)
          dispatch(updateMetadataDownloadStatus({ metadataDownloadId: id, status: 'complete' }))

          zip
            .generateAsync({
              type: 'blob',
              compression: 'DEFLATE',
              compressionOptions: {
                level: 9,
              },
            })
            .then(async (content) => {
              const response = new Response(content)
              await cache.put(fileName, response)
            })
        }
      }
    }
  }

  const downloadFile = async (metadataDownloadId: string) => {
    try {
      if (tagMetadata.downloads.items) {
        const fileName: string = tagMetadata.downloads.items.filter(
          (metadataDownload: { id: string }) => metadataDownload.id === metadataDownloadId,
        )[0].fileName
        const cache = await caches.open('laava-manage-metadata-download')
        const archiveResponse = await cache.match(fileName)
        const archiveBlob = await archiveResponse?.blob()
        archiveBlob && saveAs(archiveBlob, fileName)
      }
    } catch (e) {
      throw new Error(e as any)
    }
  }

  const tagDownloadsHead = {
    cells: [
      {
        content: 'File Name',
        key: 'fileName',
        isSortable: true,
        height: 36,
        width: 20,
      },
      {
        content: 'Number of Fingerprints',
        width: 15,
      },
      {
        content: 'Progress',
        width: 30,
      },
      {
        content: 'Status',
        width: 15,
      },
      {
        width: 10,
      },
    ],
  }

  const metadataDownloadsCells =
    tagMetadata.downloads.items &&
    tagMetadata.downloads.items.map((metadataDownload: MetadataDownloadItem) => {
      const progressBar = (metadataDownload: MetadataDownloadItem) => {
        const { tagCount, tagsComplete, status } = metadataDownload

        const progress = tagsComplete / tagCount

        switch (status) {
          case 'archiving':
            return <ProgressBar isIndeterminate />
          default:
            return <ProgressBar value={progress} />
        }
      }

      return {
        cells: [
          {
            content: metadataDownload.fileName,
            key: metadataDownload.fileName,
            height: 36,
          },
          {
            content: metadataDownload.tagCount,
            key: `${metadataDownload.id}_numberOfTags`,
          },
          {
            content: progressBar(metadataDownload),
          },
          {
            content: <Lozenge appearance={'default'}>{metadataDownload.status}</Lozenge>,
          },
          {
            content: (
              <>
                {metadataDownload.status === 'complete' && (
                  <Button
                    onClick={() => downloadFile(metadataDownload.id)}
                    iconBefore={<DownloadIcon label="download" size="medium" />}
                  />
                )}
              </>
            ),
          },
        ],
      }
    })

  const actions = (
    <ButtonGroup>
      {
        <NewTagMetadataDownloadModal
          key={'new-tag-setup-button'}
          onSubmit={onPrepareMetadataDownloads}
          isOpen={isMetadataDownloadModalOpen}
          setIsOpen={setIsMetadataDownloadModalOpen}
        />
      }
      {tagMetadata.downloads.items.filter((download) => download.status === 'queued').length > 0 && (
        <Button key={'new-metadata-download-button'} onClick={processMetadataDownloads}>
          Prepare All
        </Button>
      )}
    </ButtonGroup>
  )

  return (
    <>
      <PageHeader>Metadata</PageHeader>

      <Page>
        <Grid layout="fluid">
          <GridColumn medium={12}>
            <PageHeader actions={actions}>Download</PageHeader>
            <div className="">
              Download a csv file with all of your fingerprints and their metadata. This file can be used as a starting
              point when uploading your own values.
            </div>
            <br />
            <DynamicTable
              head={tagDownloadsHead}
              rows={metadataDownloadsCells}
              loadingSpinnerSize="large"
              isFixedSize
              defaultSortKey="fileName"
              defaultSortOrder="ASC"
              emptyView={<h3>Set up a metadata download to get started.</h3>}
              key={'tag-downloads-table'}
            />
          </GridColumn>

          <Restricted to="tag-metadata:update" type="projects">
            <GridColumn medium={12}>
              <PageHeader>Upload</PageHeader>
              <SectionMessage title="How do I format my metadata?" appearance="discovery">
                <p>You can attach metadata to your fingerprints here.</p>
                <p>
                  The uploaded file should be a CSV formatted text file. Refer to the example below for how to format
                  the file.
                </p>
                <p>
                  {' '}
                  <StyledTable>
                    <tbody>
                      <tr>
                        <th>tag_id</th>
                        <th>carton_id</th>
                        <th>status</th>
                      </tr>
                      <tr>
                        <td>abc-def-ghi-jkl-mno</td>
                        <td>1</td>
                        <td>In transit</td>
                      </tr>
                    </tbody>
                  </StyledTable>
                </p>
              </SectionMessage>
              <Form onSubmit={onSubmitCsvDataFileUpload}>
                {({ formProps }) => (
                  <form {...formProps}>
                    <FormSection>
                      <Field label="CSV Data File" name="csvDataFile">
                        {({ fieldProps }) => <DropZoneComp />}
                      </Field>
                    </FormSection>
                    {csvDataFile && (
                      <FormSection>
                        <Field label="File to upload" name="fileToUpload">
                          {({ fieldProps }) => (
                            <>
                              <br />
                              {csvDataFile?.name} ({Math.round(csvDataFile?.size / 1024)}k)
                            </>
                          )}
                        </Field>
                      </FormSection>
                    )}

                    <br />
                    <SubmitButton appearance="primary" type="submit">
                      Upload
                    </SubmitButton>
                  </form>
                )}
              </Form>
            </GridColumn>
          </Restricted>
          <FlagGroup onDismissed={handleDismiss}>
            {flags.map((flag) => {
              return (
                <Flag
                  appearance={flag.appearance}
                  id={flag.id}
                  icon={flag.icon}
                  key={flag.id}
                  title={
                    <>
                      {flag.title}
                      <div style={{ float: 'right' }}>
                        <CrossIcon size="medium" primaryColor="#fff" label="" onClick={handleDismiss} />
                      </div>
                    </>
                  }
                />
              )
            })}
          </FlagGroup>
        </Grid>
      </Page>
    </>
  )
}

export default connect(mapStateToProps)(CollectionMetadata)
