import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-grid-system'
import { chunk } from 'lodash'
import { useDropzone } from 'react-dropzone'
import useInterval from 'use-interval'

import UploadIcon from '@atlaskit/icon/glyph/upload'
import ModalDialog, { ModalTransition, ModalFooter } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import Tooltip from '@atlaskit/tooltip'
import Textfield from '@atlaskit/textfield'
import SearchIcon from '@atlaskit/icon/glyph/search'
import Select from '@atlaskit/select'

import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag'
import ErrorIcon from '@atlaskit/icon/glyph/error'

import { State, Project, Asset } from '../../../../types'
import { OrangeButton } from '../../../styled/Button'
import { uploadAsset, fetchAssetsIfNeeded } from '../../../../state/content/actions'

const AssetBox = styled.div`
  font-size: 0.7rem;
  text-align: center;
  border-radius: 4px;
  padding: 10px 20px 10px 20px;
  text-overflow: ellipsis;
  height: 130px;
  overflow: hidden;
  overflow-wrap: anywhere;
  &.not-selected {
    border: 2px solid #e0e0e0;
  }
  &.selected {
    border: 2px solid #0052cc;
  }
`

const ContainerStyled = styled(Container)`
  border-radius: 4px;
  padding: 10px 5px 0 0;
  position: relative;
`

const Dropzone = styled.div`
  width: 100%;
  height: 50px;
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  p {
    margin: 0;
  }

  span {
    vertical-align: middle;
    margin: 0 5px;
  }
`

const FileName = styled.p`
  margin: 5px;
  display: inline;
`

const CopyIconContainer = styled.div`
  position: absolute;
  right: 10%;
  color: #aaa;
`

const AssetImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: block;
  margin: auto;
  // margin-right: 20px
  outline: 2px solid lightgray;
  background-image: linear-gradient(45deg, hsl(0deg 0% 85%) 25%, transparent 25%),
    linear-gradient(-45deg, hsl(0deg 0% 85%) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, hsl(0deg 0% 85%) 75%),
    linear-gradient(-45deg, transparent 75%, hsl(0deg 0% 85%) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-color: #ebebeb;
`

const AssetImageContainer = styled.div`
  margin-right: 20px;
`

const AssetInfo = styled.div``

const AssetNoImage = styled.div`
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: flex;
  margin: auto;
  outline: 2px solid lightgray;
  align-items: center;
  justify-content: center;
`

const AssetButtonContainer = styled.div`
Button {
  margin-top:10px
  margin-right: 5px
}
`

const sortOptions = [
  { label: 'Newest', value: 'newest' },
  { label: 'Oldest', value: 'oldest' },
]

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    assets: state.content.assets.items,
  }
}

interface AssetSelectorProps {
  id: number
  currentAssetId: string
  project: Project
  assets?: Asset[]
  onSubmitAsset?: any
  setIsOpen: any
  setIsClosed: any
  isOpen: boolean
  dispatch?: any
}

const sortAssets = (assets: any, sortOption: any) => {
  switch (sortOption.value) {
    case 'newest':
      return assets?.sort((first: any, next: any) => {
        return new Date(next.created_at).getTime() - new Date(first.created_at).getTime()
      })
    case 'oldest':
      return assets?.sort((first: any, next: any) => {
        return new Date(first.created_at).getTime() - new Date(next.created_at).getTime()
      })
    default:
      return assets
  }
}

const AssetSelector: React.FC<AssetSelectorProps> = ({
  id,
  currentAssetId,
  assets,
  project,
  onSubmitAsset,
  setIsOpen,
  setIsClosed,
  isOpen,
  dispatch,
}) => {
  const currentAsset = assets?.filter((asset: any) => asset.id === currentAssetId)[0]
  const [selectedAssetId, setSelectedAssetId] = useState(currentAsset?.id)

  useEffect(() => {
    dispatch(fetchAssetsIfNeeded(project?.id))
  })

  // Search box logic
  const [searchText, setSearchText] = useState<string>('')
  if (searchText) {
    assets = assets?.filter((asset: any) => asset.name.toUpperCase().includes(searchText.toUpperCase()))
  }

  const close = () => {
    setSearchText('')
    setIsClosed()
  }

  const onFormSubmit = () => {
    // Returns the selected asset id
    onSubmitAsset(selectedAssetId, id)
    close()
  }

  const onRemoveAsset = () => {
    if (window.confirm('Are you sure you want to remove this image?')) {
      onSubmitAsset(null, id)
    }
  }

  // Sorting logic
  const [sortOption, setSortOption] = useState(sortOptions[0])
  const handleSortOptionChange = (value: any) => {
    setSortOption(value)
  }

  // Used for the URL copy tooltip
  const [copyUrlText, setCopyUrlText] = useState('Copy URL to clipboard')
  useInterval(
    () => {
      setCopyUrlText('Copy URL to clipboard')
    },
    copyUrlText !== 'Copy URL to clipboard' ? 1000 : null,
  )

  // Used for displaying errors
  const [flags, setFlags] = React.useState<Array<number>>([])
  const addFlag = () => {
    const newFlagId = flags.length + 1
    const newFlags = flags.slice()
    newFlags.splice(0, 0, newFlagId)
    setFlags(newFlags)
  }
  const handleDismiss = () => {
    setFlags(flags.slice(1))
  }

  assets = sortAssets(assets, sortOption)
  const rows = chunk(assets, 4) // Chunk the assets to columnise the assets

  const footer = (props: { showKeyline?: boolean }) => (
    <>
      {isOpen && (
        <ModalTransition key={'transition-footer'}>
          <ModalFooter showKeyline={props.showKeyline} key={'modal-footer'}>
            <span />
            <ButtonGroup key={'button-group'}>
              <Button appearance="subtle" onClick={close}>
                Cancel
              </Button>
              <OrangeButton appearance="primary" onClick={onFormSubmit}>
                Submit
              </OrangeButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalTransition>
      )}
    </>
  )

  const DropZoneComp: React.FC = () => {
    const onDrop = useCallback((acceptedFiles) => {
      !(acceptedFiles.length > 0) && addFlag()
      acceptedFiles[0] &&
        dispatch(uploadAsset({ assetFile: acceptedFiles[0], name: acceptedFiles[0].name, projectId: project.id }))
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      maxSize: 1000000,
      accept: ['image/png', 'image/jpeg', '.png', '.jpg', '.jpeg'],
    })

    return (
      <Dropzone
        {...getRootProps()}
        style={{ outline: 'unset', display: 'table', textAlign: 'center' }}
        key={'dropzone'}
      >
        <input {...getInputProps()} />
        <br />
        <p>
          <UploadIcon size="medium" primaryColor="#bbb" label="" />
          {isDragActive ? (
            <>Drop the files here ... </>
          ) : (
            <>Drop an image file here to use, or click to select a file.</>
          )}
        </p>
        <p style={{ fontWeight: 'lighter', margin: 0 }}>png, jpeg. Max 1MB</p>
        <br />
      </Dropzone>
    )
  }

  return (
    <div key={'asset-selector'}>
      {/* <AssetSelectorContainer> */}
        <ContainerStyled key={'asset-preview-container'}>
          <div style={{ justifyContent: 'flex-start', position: 'relative', display: 'flex' }} key={'asset-preview-row'}>
            {(currentAsset?.formats.thumbnail?.url || currentAsset?.url) && (
              <>
                <AssetImageContainer>
                  <AssetImage
                    key={'asset-preview-image'}
                    src={
                      currentAsset?.formats.thumbnail?.url ||
                      currentAsset?.url ||
                      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
                    }
                    alt=""
                  />
                </AssetImageContainer>
                <AssetInfo>
                  <p>
                    <b>{currentAsset.name}</b>
                    <br />
                    {currentAsset.width}x{currentAsset.height}
                    <br />
                    <AssetButtonContainer>
                      <Button onClick={() => setIsOpen(true)}>Edit</Button>
                      <Button onClick={() => onRemoveAsset()}>Remove</Button>
                    </AssetButtonContainer>
                  </p>
                </AssetInfo>
              </>
            )}

            {!currentAsset?.formats.thumbnail?.url && !currentAsset?.url && (
              <>
                <AssetNoImage onClick={() => setIsOpen(true)}>
                  <AddIcon label="" key={'edit-icon'} />
                </AssetNoImage>
              </>
            )}

            {/* {!currentAsset?.url && (
            <div key={'empty-asset-preview'}>
              <p style={{ fontWeight: 'lighter', margin: 0, paddingLeft: '5px' }}>Click here to select an asset.</p>
              <br />
              <br />
            </div>
          )} */}
            {/* <EditIconContainer key={'edit-icon-container'}>
            <EditFilledIcon label="" key={'edit-icon'} />
          </EditIconContainer> */}
          </div>
          
        </ContainerStyled>
      {/* </AssetSelectorContainer> */}

      <ModalTransition key={'transition'}>
        {isOpen && (
          <ModalDialog components={{ Footer: footer }} heading="Select an asset" key={'modal'} width={'x-large'}>
            <DropZoneComp key={'dropzone'} />
            <br />
            {(rows.length > 0 || searchText) && (
              <Row>
                <Col sm={6} md={6}>
                  <Textfield
                    placeholder="Search"
                    width="100%"
                    onChange={(event: any) => {
                      const element = event.currentTarget as HTMLInputElement
                      setSearchText(element.value)
                    }}
                    elemAfterInput={
                      <div style={{ padding: '5px' }}>
                        <SearchIcon label="search" />
                      </div>
                    }
                  />
                </Col>
                <Col style={{ display: 'flex', placeItems: 'center' }} sm={6} md={6}>
                  <p style={{ width: '10%' }}>Sort by: </p>
                  <div style={{ width: '90%' }}>
                    <Select
                      onChange={handleSortOptionChange}
                      key={'sort_options'}
                      name="sort_options"
                      options={sortOptions}
                      defaultValue={sortOption}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <br />
            {rows?.map((cols, rowIndex) => (
              <Row key={rowIndex}>
                {cols.map((col: any, colIndex) => (
                  <Col
                    onClick={() => {
                      setSelectedAssetId(col?.id)
                    }}
                    style={{ maxWidth: 'unset', width: 'unset' }}
                    sm={3}
                    md={3}
                    key={`asset-col-${rowIndex}-${colIndex}`}
                  >
                    <AssetBox
                      key={`asset-box-${rowIndex}-${colIndex}`}
                      className={col?.id === selectedAssetId ? 'selected' : 'not-selected'}
                    >
                      <CopyIconContainer>
                        <Tooltip content={copyUrlText}>
                          <CopyIcon
                            label=""
                            size={'medium'}
                            key={`copy-icon-${colIndex}`}
                            onClick={(event) => {
                              event.stopPropagation() // Stops setSelected handler of box firing
                              navigator.clipboard.writeText(col.url)
                              setCopyUrlText('Copied!')
                            }}
                          />
                        </Tooltip>
                      </CopyIconContainer>

                      <AssetImage key={`asset-image-${rowIndex}-${colIndex}`} src={col?.url} alt="" />
                      <FileName key={`asset-name-${rowIndex}-${colIndex}`}>{col?.name}</FileName>
                    </AssetBox>
                    <br />
                  </Col>
                ))}
              </Row>
            ))}
            <FlagGroup onDismissed={handleDismiss}>
              {flags.map((flagId) => {
                return (
                  <AutoDismissFlag
                    appearance="error"
                    id={flagId}
                    icon={<ErrorIcon label="Error" secondaryColor={'#DE350B'} />}
                    key={flagId}
                    title={`Upload error`}
                    description="File type must be png, jpeg. Max 1MB"
                  />
                )
              })}
            </FlagGroup>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  )
}

export default connect(mapStateToProps)(AssetSelector)
