import React, { useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Spinner from '@atlaskit/spinner'
import PageHeader from '@atlaskit/page-header'
import CanvasIcon from '@atlaskit/icon/glyph/canvas'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import {
  SideNavigation,
  NavigationHeader,
  Header,
  NavigationContent,
  CustomItem,
  CustomItemComponentProps,
} from '@atlaskit/side-navigation'
import DocumentIcon from '@atlaskit/icon/glyph/document'
import DocumentsIcon from '@atlaskit/icon/glyph/documents'
import MediaServicesSpreadsheetIcon from '@atlaskit/icon/glyph/media-services/spreadsheet'
import { Main, LeftSidebar } from '@atlaskit/page-layout'

import ErrorMessage from '../../components/react/error-message'
import { Project, Collection as ICollection, State } from '../../types'
import CollectionFingerprints from './fingerprints'
import CollectionMetadata from './metadata'
import ResultScreens from './result-views'
import Download from './download'
import { fetchCollectionsIfNeeded } from '../../state/collections/actions'
import { collectionViewsInvalidate } from '../../state/content/actions'
import { fetchProjectsIfNeeded } from '../../state/projects/actions'
import { PermissionProvider } from '../../providers/permission'

const Wrapper = styled.div`
  padding: 8px 40px;
`

const LoadingContainer = styled.div`
  margin-top: 35vh;
  width: 10%;
  margin-left: auto;
  margin-right: auto;
`

const Breadcrumbs = styled.span`
  margin: 24px 0 16px 0;
  line-height: 24px;
  a {
    color: #6b778c !important;
  }
`

const BreadcrumbSeparator = styled.span`
  width: 8px;
  padding: 0 10px;
  line-height: 24px;
  color: #6b778c !important;
`

const CollectionDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const CollectionDetailsItem = styled.div`
  display: flex;
  align-items: center;
`

const CollectionDetailsHeading = styled.h5`
  margin-right: 10px;
`

interface Props {
  collection: ICollection
  project: Project
  isFetching: boolean
  dispatch: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const collection = state.collections.items.filter(
    (collection: ICollection) => collection.id === ownProps.match.params.collectionId,
  )[0]
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]
  const isFetching = state.projects.isFetching || state.collections.isFetching

  return {
    collection,
    project,
    isFetching,
  }
}

const Collection: React.FC<Props> = ({ collection, project, isFetching, dispatch }) => {
  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
    dispatch(fetchCollectionsIfNeeded(project?.id))
    dispatch(collectionViewsInvalidate())
  })

  const breadcrumbs = (
    <Breadcrumbs>
      <Link to="/projects">Projects</Link>
      <BreadcrumbSeparator>/</BreadcrumbSeparator>
      <Link to={`/projects/${project?.id}/collections`}>{project?.name}</Link>
    </Breadcrumbs>
  )

  type CustomProps = CustomItemComponentProps & { href: string }

  const CustomLink = forwardRef<HTMLAnchorElement, CustomProps>((props: CustomProps, ref) => {
    const { children, ...rest } = props

    return (
      <Link to={props.href} ref={ref} {...rest}>
        {children}
      </Link>
    )
  })

  const location = useLocation()

  return (
    <>
      {(!collection || !project) && (
        <Main key={'main'}>
          <Wrapper>
            {isFetching && (
              <LoadingContainer>
                <Spinner size="xlarge" />
              </LoadingContainer>
            )}
            {!isFetching && (
              <ErrorMessage title={'Error'} description={'Collection not found.'} goBackLink={'/projects'} />
            )}
          </Wrapper>
        </Main>
      )}
      {collection && project && (
        <PermissionProvider project={project}>
          <LeftSidebar isFixed={true} width={240}>
            <SideNavigation label="Collection navigation" testId="side-navigation">
              <NavigationHeader>
                <Header iconBefore={<DocumentsIcon label="" />} description={`Collection in ${project?.name}`}>
                  {collection?.name}
                </Header>
              </NavigationHeader>

              <NavigationContent>
                <CustomItem
                  href={`/projects/${project?.id}/collections/${collection?.id}/fingerprints`}
                  component={CustomLink}
                  iconBefore={<DocumentIcon label="" />}
                  isSelected={location.pathname.endsWith('/fingerprints')}
                >
                  Fingerprints
                </CustomItem>
                <CustomItem
                  href={`/projects/${project?.id}/collections/${collection?.id}/download`}
                  component={CustomLink}
                  iconBefore={<DownloadIcon label="" />}
                  isSelected={location.pathname.endsWith('/download')}
                >
                  Download Fingerprints
                </CustomItem>
                <CustomItem
                  href={`/projects/${project?.id}/collections/${collection?.id}/metadata`}
                  component={CustomLink}
                  iconBefore={<MediaServicesSpreadsheetIcon label="" />}
                  isSelected={location.pathname.endsWith('/metadata')}
                >
                  Metadata
                </CustomItem>
                <CustomItem
                  href={`/projects/${project?.id}/collections/${collection?.id}/result-views`}
                  component={CustomLink}
                  iconBefore={<CanvasIcon label="" />}
                  isSelected={location.pathname.endsWith('/result-views')}
                >
                  Assign Result Views
                </CustomItem>
              </NavigationContent>
            </SideNavigation>
          </LeftSidebar>

          <Main>
            <Wrapper>
              <PageHeader breadcrumbs={breadcrumbs}>
                <br />
                <CollectionDetails>
                  <CollectionDetailsItem>
                    <CollectionDetailsHeading>Collection Name: </CollectionDetailsHeading>
                    {collection?.name}
                  </CollectionDetailsItem>
                  <CollectionDetailsItem>
                    <CollectionDetailsHeading>Collection ID: </CollectionDetailsHeading>
                    {collection?.id}
                  </CollectionDetailsItem>
                  <CollectionDetailsItem>
                    <CollectionDetailsHeading>Total Smart Fingerprints: </CollectionDetailsHeading>
                    {collection?.tagCount}
                  </CollectionDetailsItem>
                </CollectionDetails>
              </PageHeader>
              <hr style={{ border: '1px solid #DFE1E6' }} />
              <Route
                path="/projects/:projectId/collections/:collectionId/fingerprints"
                component={CollectionFingerprints}
              />
              <Route path="/projects/:projectId/collections/:collectionId/metadata" component={CollectionMetadata} />
              <Route path="/projects/:projectId/collections/:collectionId/result-views" component={ResultScreens} />
              <Route path="/projects/:projectId/collections/:collectionId/download" component={Download} />
            </Wrapper>
          </Main>
        </PermissionProvider>
      )}
    </>
  )
}

export default connect(mapStateToProps)(Collection)
