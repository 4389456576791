import axios from 'axios'

export const SCAN_EVENTS_REQUEST = 'SCAN_EVENTS_REQUEST'
export const SCAN_EVENTS_RECEIVE = 'SCAN_EVENTS_RECEIVE'
export const SCAN_EVENTS_INVALIDATE = 'SCAN_EVENTS_RECEIVE'
export const SCAN_EVENTS_ERROR = 'SCAN_EVENTS_ERROR'

const scanEventsRequest = () => {
  return {
    type: SCAN_EVENTS_REQUEST,
  }
}

const scanEventsReceive = (scanEvents: any) => {
  return {
    type: SCAN_EVENTS_RECEIVE,
    scanEvents,
  }
}

export const scanEventsInvalidate = () => {
  return {
    type: SCAN_EVENTS_RECEIVE,
  }
}

export const scanEventsError = () => {
  return {
    type: SCAN_EVENTS_ERROR,
  }
}

interface fetchScanEventsOptions {
  projectId?: string
  createdAfter?: string
  createdBefore?: string
  include?: string
}

export const fetchScanEvents = ({ projectId, createdAfter, createdBefore, include }: fetchScanEventsOptions) => {
  return async (dispatch: any) => {
    let url = `${process.env.REACT_APP_SCAN_EVENTS_BASE_URL}/scan-events?`

    let headers: any = {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    if (projectId) headers.project_id = projectId
    if (createdAfter) url = url + 'createdAfter=' + (createdAfter as string) + '&'
    if (createdBefore) url = url + 'createdBefore=' + (createdBefore as string) + '&'
    if (include) url = url + 'include=' + (include as string) + '&'

    let isFetching = true
    let offset = 0
    const limit = 500

    let resultData: any[] = []

    dispatch(scanEventsRequest())

    try {
      while (isFetching) {
        const limitAndOffset = `&limit=${limit}&offset=${offset}`

        const result: any = await axios.get(url + limitAndOffset, {
          headers,
        })

        if (result.data) {
          resultData = resultData.concat(result.data)
        }

        if (result.data.length === limit) {
          offset += limit
        } else {
          isFetching = false
        }
      }

      const seenClientScanIds = new Set()

      const filteredScanEvents = resultData.filter((scan) => {
        const hasTagId = !!scan.tagId
        const isDuplicate = seenClientScanIds.has(scan.clientScanId)

        if ((!isDuplicate && hasTagId) || !hasTagId) {
          seenClientScanIds.add(scan.clientScanId)
          return true
        }

        return false
      })

      dispatch(scanEventsReceive(filteredScanEvents))
    } catch (error) {
      console.error(error)
      dispatch(scanEventsError())
    }
  }
}

const shouldFetchScanEvents = (state: any): boolean => {
  const lastFetched = state.scanEvents.lastFetched
  const isFetching = state.scanEvents.isFetching

  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.scanEvents.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchScanEventsIfNeeded = (projectId?: string) => {
  return (dispatch: any, getState: any) => {
    if (shouldFetchScanEvents(getState())) {
      dispatch(fetchScanEvents({ projectId }))
    }
  }
}
