import React from 'react'
import styled from 'styled-components'

// Define a styled component for the icons
// Add the clientType prop to the styled component
const ClientIcon = styled.img.attrs((props: { clientType: string }) => ({
  'data-client-type': props.clientType,
}))<{ clientType: string; size?: number }>` // Add size prop here
  width: ${(props) => (props.size ? `${props.size}px` : '13px')}; // Use size prop for width
  height: ${(props) => (props.size ? `${props.size}px` : '13px')}; // Use size prop for height

  /* Assuming your SVGs are inlined or styled to accept CSS variables for coloring */
  &[data-client-type='WHATSAPP'] {
    --icon-color: #25d366; /* WhatsApp green */
  }
  &[data-client-type='WECHAT'] {
    --icon-color: #09b83e; /* WeChat green */
  }
  &[data-client-type='NATIVESCANNER_IOS'] {
    --icon-color: #000000; /* Apple black */
  }
  &[data-client-type='NATIVESCANNER_ANDROID'] {
    --icon-color: #a4c639; /* Android green */
  }
  &[data-client-type='WEBSCANNER'] {
    --icon-color: #61dafb; /* Web blue */
  }
  &[data-client-type='OTHER'] {
    --icon-color: #606060; /* Other grey */
  }
`

// Function to get the icon source based on the client type
const getClientIconSrc = (clientType: string) => {
  switch (clientType) {
    case 'WHATSAPP':
      return `${process.env.PUBLIC_URL}/icons/whatsapp.svg`
    case 'WECHAT':
      return `${process.env.PUBLIC_URL}/icons/wechat.svg`
    case 'NATIVESCANNER_IOS':
      return `${process.env.PUBLIC_URL}/icons/apple.svg`
    case 'NATIVESCANNER_ANDROID':
      return `${process.env.PUBLIC_URL}/icons/android2.svg`
    case 'WEBSCANNER':
      return `${process.env.PUBLIC_URL}/icons/browser-safari.svg`
    default:
      return `${process.env.PUBLIC_URL}/icons/question-circle-fill.svg`
  }
}

interface ClientIconComponentProps {
  clientType: string;
  size?: number;
}

const ClientIconComponent: React.FC<ClientIconComponentProps> = ({ clientType, size }) => {
  const iconSrc = getClientIconSrc(clientType);

  return <ClientIcon src={iconSrc} clientType={clientType} alt={clientType} size={size} />; // Pass size prop here
};

export default ClientIconComponent
