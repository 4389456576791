import styled from 'styled-components'

const LogoHolder = styled.div`
  height: 100px;
  width: 100px;
  text-align: center;
  position: absolute;
  top: 100px;
  left: 100px;
  background: white;
  border-radius: 50%;
  overflow: hidden;
`

const PreviewHolder = styled.div`
  position: relative;
  height: 300px;
  width: 300px;
  margin: auto;
`

const UploadedPreview = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`

export { LogoHolder, PreviewHolder, UploadedPreview }
