import { ResultScreenLanguage } from '../types'

export const resultScreenLanguageLabels: { [key in ResultScreenLanguage]: string } = {
  en: 'English',
  de: 'German',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  pt: 'Portuguese',
  nl: 'Dutch',
  ja: 'Japanese',
  vi: 'Vietnamese',
  zh: 'Chinese (Simplified)',
  pl: 'Polish',
  sv: 'Swedish',
}
