import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Container, Row } from 'react-grid-system'
import { FaFingerprint } from 'react-icons/fa'

const Box = styled.div`
  min-width: 0;
  text-align: center;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
`

interface Props {
  data: Object[]
}

const UniqueCountBox: React.FC<Props> = ({ data }) => {
  const uniqueData = _.uniqBy(data, 'tagId')
  const uniqueCount = uniqueData.length
  return (
    <Box>
      <Container>
        <Row style={{ display: 'unset' }}>
          <h1>{uniqueCount}</h1>
        </Row>
        <Row style={{ display: 'unset' }}>
          <h3>
            Unique Fingerprints Scanned <FaFingerprint />
          </h3>
        </Row>
      </Container>
    </Box>
  )
}

export default UniqueCountBox
