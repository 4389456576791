import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { chunk } from 'lodash'
import { useDropzone } from 'react-dropzone'
import useInterval from 'use-interval'

import UploadIcon from '@atlaskit/icon/glyph/upload'
import PageHeader from '@atlaskit/page-header'
import InlineMessage from '@atlaskit/inline-message'
import Spinner from '@atlaskit/spinner'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import SearchIcon from '@atlaskit/icon/glyph/search'
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import Tooltip from '@atlaskit/tooltip'

import { Restricted } from '../../providers/permission'
import { State, Project } from '../../types'
import { uploadAsset, fetchAssetsIfNeeded } from '../../state/content/actions'

const AssetBox = styled.div`
  font-size: 0.7rem;
  text-align: center;
  border-radius: 4px;
  padding: 10px 20px 10px 20px;
  text-overflow: ellipsis;
  height: 130px;
  overflow: hidden;
  overflow-wrap: anywhere;
  border: 2px solid #e0e0e0;
  :hover {
    opacity: 50%;
  }
`

const Dropzone = styled.div`
  width: 100%;
  height: 50px;
  border: 1px dashed #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;

  p {
    margin: 0;
  }

  span {
    vertical-align: middle;
    margin: 0 5px;
  }
`

const SpinnerContainer = styled.div`
  width: 10%;
  margin-left: auto;
  margin-right: auto;
`

const CopyIconContainer = styled.div`
  position: absolute;
  right: 10%;
  color: #aaa;
`

const AssetImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: block;
  margin: auto;
  background-image: linear-gradient(
    45deg
    , hsl(0deg 0% 85%) 25%, transparent 25%), linear-gradient(
    -45deg
    , hsl(0deg 0% 85%) 25%, transparent 25%), linear-gradient(
    45deg
    , transparent 75%, hsl(0deg 0% 85%) 75%), linear-gradient(
    -45deg
    , transparent 75%, hsl(0deg 0% 85%) 75%);
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`

const sortOptions = [
  { label: 'Newest', value: 'newest' },
  { label: 'Oldest', value: 'oldest' },
]

interface Props {
  isFetching?: boolean
  assets?: any
  project: Project
  didInvalidate: boolean
  dispatch: any
}

const sortAssets = (assets: any, sortOption: any) => {
  switch (sortOption.value) {
    case 'newest':
      return assets?.sort((first: any, next: any) => {
        return new Date(next.created_at).getTime() - new Date(first.created_at).getTime()
      })
    case 'oldest':
      return assets?.sort((first: any, next: any) => {
        return new Date(first.created_at).getTime() - new Date(next.created_at).getTime()
      })
    default:
      return assets
  }
}

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]

  return {
    isFetching: state.projects.isFetching || state.content.assets?.isFetching,
    assets: state.content.assets.items,
    project,
    didInvalidate: state.content.assets.didInvalidate,
  }
}

const ProjectAssets: React.FC<Props> = ({ isFetching, project, assets, didInvalidate, dispatch }) => {
  // Sorting logic
  const [sortOption, setSortOption] = useState(sortOptions[0])
  const handleSortOptionChange = (value: any) => {
    setSortOption(value)
  }

  // Used for displaying errors
  const [flags, setFlags] = React.useState<Array<number>>([])
  const addFlag = () => {
    const newFlagId = flags.length + 1
    const newFlags = flags.slice()
    newFlags.splice(0, 0, newFlagId)
    setFlags(newFlags)
  }
  const handleDismiss = () => {
    setFlags(flags.slice(1))
  }

  // Search box logic
  const [searchText, setSearchText] = useState<string>('')
  if (searchText) {
    assets = assets?.filter((asset: any) => asset.name.toUpperCase().includes(searchText.toUpperCase()))
  }

  useEffect(() => {
    dispatch(fetchAssetsIfNeeded(project?.id))
  })

  assets = sortAssets(assets, sortOption)
  const rows = chunk(assets, 4) // Chunk the assets to columnise the assets

  const DropZoneComp: React.FC = () => {
    const onDrop = useCallback((acceptedFiles) => {
      !(acceptedFiles.length > 0) && addFlag()
      acceptedFiles[0] &&
        dispatch(
          uploadAsset({ assetFile: acceptedFiles[0], name: acceptedFiles[0].name, projectId: project.id }),
        )
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      maxSize: 1000000,
      accept: ['image/png', 'image/jpeg','.png', '.jpg', '.jpeg'],
    })

    return (
      <Dropzone
        {...getRootProps()}
        style={{ outline: 'unset', display: 'table', textAlign: 'center' }}
        key={'dropzone'}
      >
        <input {...getInputProps()} />
        <br />
        <p>
          <UploadIcon size="medium" primaryColor="#bbb" label="" />
          {isDragActive ? (
            <>Drop the files here ... </>
          ) : (
            <>Drop an image file here to use, or click to select a file.</>
          )}
        </p>
        <p style={{ fontWeight: 'lighter', margin: 0 }}>png, jpeg. Max 1MB</p>
        <br />
      </Dropzone>
    )
  }

  // Used for the URL copy tooltip
  const [copyUrlText, setCopyUrlText] = useState('Copy URL to clipboard')
  useInterval(
    () => {
      setCopyUrlText('Copy URL to clipboard')
    },
    copyUrlText !== 'Copy URL to clipboard' ? 1000 : null,
  )

  return (
    <>
      <PageHeader key={'page-header'}>Assets</PageHeader>

      <InlineMessage type="info" secondaryText="What are assets?">
        <p>
          You can upload assets to use in you result views. Assets that have been uploaded in this project will
          appear here.
        </p>
      </InlineMessage>

      <br />
      <br />
      <Restricted to="asset:create" type="projects">
        <DropZoneComp key={'dropzone'} />
      </Restricted>
      
      <br />
      {(isFetching || didInvalidate) && (
        <SpinnerContainer>
          <Spinner size="xlarge" />
        </SpinnerContainer>
      )}

      {(rows.length > 0 || searchText) && (
        <Row>
          <Col sm={6} md={6}>
            <Textfield
              placeholder="Search"
              width="100%"
              onChange={(event) => {
                const element = event.currentTarget as HTMLInputElement
                setSearchText(element.value)
              }}
              elemAfterInput={
                <div style={{ padding: '5px' }}>
                  <SearchIcon label="search" />
                </div>
              }
            />
          </Col>
          <Col style={{ display: 'flex', placeItems: 'center' }} sm={6} md={6}>
            <p style={{ width: '10%' }}>Sort by: </p>
            <div style={{ width: '90%' }}>
              <Select
                onChange={handleSortOptionChange}
                key={'sort_options'}
                name="sort_options"
                options={sortOptions}
                defaultValue={sortOption}
              />
            </div>
          </Col>
        </Row>
      )}
      <br />
      {rows?.map((cols, rowIndex) => (
        <Row key={rowIndex}>
          {cols.map((col: any, colIndex) => (
            <Col style={{ maxWidth: 'unset', width: 'unset' }} sm={3} md={3} key={`asset-col-${rowIndex}-${colIndex}`}>
              <AssetBox key={`asset-box-${rowIndex}-${colIndex}`}>
                <CopyIconContainer>
                  <Tooltip content={copyUrlText}>
                    <CopyIcon
                      label=""
                      size={'medium'}
                      key={`copy-icon-${colIndex}`}
                      onClick={(event) => {
                        event.stopPropagation() // Stops setSelected handler of box firing
                        navigator.clipboard.writeText(col.url)
                        setCopyUrlText('Copied!')
                      }}
                    />
                  </Tooltip>
                </CopyIconContainer>
                <AssetImage key={`asset-image-${rowIndex}-${colIndex}`} src={col?.url} alt="" />
                <p key={`asset-name-${rowIndex}-${colIndex}`}>{col?.name}</p>
              </AssetBox>
              <br />
            </Col>
          ))}
        </Row>
      ))}
      {!(rows.length > 0) && !isFetching && !searchText && !didInvalidate && (
        <h2 style={{ textAlign: 'center' }}>No assets found for this project.</h2>
      )}
      <FlagGroup onDismissed={handleDismiss}>
        {flags.map((flagId) => {
          return (
            <AutoDismissFlag
              appearance="error"
              id={flagId}
              icon={<ErrorIcon label="Error" secondaryColor={'#DE350B'} />}
              key={flagId}
              title={`Upload error`}
              description="File type must be png, jpeg. Max 1MB"
            />
          )
        })}
      </FlagGroup>
    </>
  )
}

export default connect(mapStateToProps)(ProjectAssets)
