import React, { useState, useCallback } from 'react'
import styled from 'styled-components'

import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormSection, ErrorMessage, HelperMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import { RadioGroup } from '@atlaskit/radio'
import { OptionsPropType } from '@atlaskit/radio/types'

import AssetSelector from '../asset-selector'
import { OrangeButton } from '../../../styled/Button'
import FingerprintPreview from '../../fingerprint-preview'
import { Project, Asset, TagDownloadHubImageOption, TagDownloadImageFormatOption } from '../../../../types'
import { isNumber, isNumberGreaterThanZero } from '../../../../helpers'

const HubImageOptionHolder = styled.div`
  display: flex;
  justify-content: space-between;
  // height: 150px;
`

const PDFOptionHolder = styled.div`
  display: flex;
  justify-content: space-between;

  div:first-child {
    padding-right: 15px;
  }

  div {
    flex: 1;
  }
`

interface NewTagDownloadModalProps {
  project: Project
  onSubmit: any
  isOpen: boolean
  setIsOpen: any
  assets: any[]
}

const NewTagDownloadModal: React.FC<NewTagDownloadModalProps> = ({ project, onSubmit, isOpen, setIsOpen, assets }) => {
  const [hubImageAsset, setHubImageAsset] = useState<Asset | null>(null)
  const [hubImageAssetSelectorIsOpen, setHubImageAssetSelectorIsOpen] = useState(false)

  const [hubImageAssetId, setHubImageAssetId] = useState(null)
  const [hubImageOption, setHubImageOption] = useState<TagDownloadHubImageOption>('no-hub-image')
  const [tagImageFormatOption, setTagImageFormatOption] = useState<TagDownloadImageFormatOption>('EPS')

  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }
  interface onFormSubmitParams {
    filenamePrefix?: string
    hubImageOption: TagDownloadHubImageOption
    imageFormatOption: TagDownloadImageFormatOption
    startTagOffset: number
    tagCount: number
    tagsPerArchive: number
    pdfTagSize?: number
    pdfTagMargin?: number
  }

  const onFormSubmit = (data: onFormSubmitParams) => {
    const { filenamePrefix, tagCount, startTagOffset, tagsPerArchive, imageFormatOption, pdfTagSize, pdfTagMargin } =
      data

    let submitData = {
      hubImageOption,
      imageFormatOption,
      filenamePrefix,
      hubImageAssetId,
      pdfTagSize: Number(pdfTagSize),
      pdfTagMargin: Number(pdfTagMargin),
      tagCount: Number(tagCount),
      startTagOffset: Number(startTagOffset),
      tagsPerArchive: Number(tagsPerArchive),
    }

    onSubmit(submitData)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Order
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  const handleHubImageSelection = (assetId: any) => {
    setHubImageAssetId(assetId)
    const hubImageAsset = assets.filter((asset: any) => asset.id === assetId)[0]
    setHubImageAsset(hubImageAsset)
  }

  const hubImageRadioOptions: OptionsPropType = [
    { name: 'hubImage', value: 'no-hub-image', label: 'No Logo' },
    { name: 'hubImage', value: 'laava-hub-image', label: 'Laava Logo', isDisabled: true },
    { name: 'hubImage', value: 'custom-hub-image', label: 'Custom Logo' },
  ]

  const tagImageFormatRadioOptions: OptionsPropType = [
    { name: 'tagImageFormat', value: 'EPS', label: 'EPS' },
    { name: 'tagImageFormat', value: 'PNG', label: 'PNG' },
    { name: 'tagImageFormat', value: 'PDF', label: 'PDF' },
  ]

  const onChangeHubImageLogoRadio = useCallback((event: any) => {
    const hubImageOption: TagDownloadHubImageOption = event.currentTarget.value
    setHubImageOption(hubImageOption)
  }, [])

  const onChangeTagImageFormatRadio = useCallback((event: any) => {
    const tagImageFormatOption: TagDownloadImageFormatOption = event.currentTarget.value
    setTagImageFormatOption(tagImageFormatOption)
  }, [])

  return (
    <div key={'new-tag-order-container'}>
      <OrangeButton onClick={open} appearance="primary">
        Set Up New Tag Download
      </OrangeButton>
      {isOpen && (
        <ModalTransition>
          <ModalDialog
            heading="Start New Tag Download"
            components={{
              Container: ({ children, className }) => (
                <>
                  <Form onSubmit={onFormSubmit}>
                    {({ formProps }) => (
                      <form {...formProps} className={className}>
                        {children}
                      </form>
                    )}
                  </Form>
                </>
              ),
              Footer: footer,
            }}
          >
            {
              <>
                <HubImageOptionHolder>
                  <Field name="hubImageOption" label="Hub Image" isRequired defaultValue={hubImageOption}>
                    {({ fieldProps, error }) => (
                      <>
                        <RadioGroup
                          value={hubImageOption}
                          isDisabled={false}
                          options={hubImageRadioOptions}
                          onChange={onChangeHubImageLogoRadio}
                          aria-labelledby="radiogroup-label"
                        />
                      </>
                    )}
                  </Field>

                  {hubImageOption === 'custom-hub-image' && (
                    // <Field
                    //   name="tagImageFormatOption"
                    //   label="Tag Image Format"
                    //   isRequired
                    //   defaultValue={tagImageFormatOption}
                    // >
                    //   {({ fieldProps, error }) => (
                    <>
                      <AssetSelector
                        key={'product_image_asset_id'}
                        onSubmitAsset={(assetId: string) => {
                          handleHubImageSelection(assetId)
                        }}
                        currentAssetId={hubImageAssetId}
                        project={project}
                        isOpen={hubImageAssetSelectorIsOpen}
                        setIsOpen={setHubImageAssetSelectorIsOpen}
                        setIsClosed={() => {
                          setHubImageAssetSelectorIsOpen(false)
                        }}
                      />
                    </>
                    //   )}
                    // </Field>
                  )}
                </HubImageOptionHolder>

                <FormSection>
                  <Field
                    name="imageFormatOption"
                    label="Tag Image Format"
                    isRequired
                    defaultValue={tagImageFormatOption}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <RadioGroup
                          value={tagImageFormatOption}
                          isDisabled={false}
                          options={tagImageFormatRadioOptions}
                          onChange={onChangeTagImageFormatRadio}
                          aria-labelledby="radiogroup-label"
                        />
                      </>
                    )}
                  </Field>
                </FormSection>

                {tagImageFormatOption === 'PDF' && (
                  <FormSection>
                    <PDFOptionHolder>
                      <Field
                        name="pdfTagSize"
                        label="Diameter of Tag in mm"
                        validate={isNumberGreaterThanZero}
                        isRequired
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <TextField {...fieldProps} />
                            {tagImageFormatOption === 'PDF' && error === 'NOT_A_NUMBER_GREATER_THAN_ZERO' && (
                              <ErrorMessage>Number greater than zero required</ErrorMessage>
                            )}
                            <HelperMessage>The width of the tag in mm when exported to a PDF.</HelperMessage>
                          </>
                        )}
                      </Field>

                      <Field name="pdfTagMargin" label="Tag Margin in mm" validate={isNumberGreaterThanZero} isRequired>
                        {({ fieldProps, error }) => (
                          <>
                            <TextField {...fieldProps} />
                            {tagImageFormatOption === 'PDF' && error === 'NOT_A_NUMBER_GREATER_THAN_ZERO' && (
                              <ErrorMessage>Number greater than zero required</ErrorMessage>
                            )}
                            <HelperMessage>
                              The space between the tag and the edge of the PDF document in mm.
                            </HelperMessage>
                          </>
                        )}
                      </Field>
                    </PDFOptionHolder>
                  </FormSection>
                )}

                <FormSection>
                  <Field
                    name="tagCount"
                    label="Number of Tags to Download"
                    validate={isNumberGreaterThanZero}
                    isRequired
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error === 'NOT_A_NUMBER_GREATER_THAN_ZERO' && (
                          <ErrorMessage>Number greater than zero required</ErrorMessage>
                        )}
                        <HelperMessage>This is the total number of tags to be produced.</HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormSection>
                  <Field
                    name="startTagOffset"
                    label="Index of First Tag"
                    defaultValue={0}
                    validate={isNumber}
                    isRequired
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error === 'NOT_A_NUMBER' && <ErrorMessage>Number of 0 or more required</ErrorMessage>}
                        <HelperMessage>
                          This is the number of tags to skip. e.g. a value of 100 will skip the first 100 tags and start
                          from the 101st tag.
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormSection>
                  <Field
                    name="tagsPerArchive"
                    label="Number of tags per archive file"
                    defaultValue={1000}
                    validate={isNumberGreaterThanZero}
                    isRequired
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error === 'NOT_A_NUMBER_GREATER_THAN_ZERO' && (
                          <ErrorMessage>Number greater than zero required</ErrorMessage>
                        )}
                        <HelperMessage>The number of tags to put in each archive file.</HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormSection>
                  <Field name="filenamePrefix" label="File Name Prefix" isRequired>
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormSection>
                  <FingerprintPreview
                    url={hubImageAsset?.formats.thumbnail?.url || hubImageAsset?.url}
                    hubImageOption={hubImageOption}
                    key={'fp-preview'}
                  />
                </FormSection>
              </>
            }
          </ModalDialog>
        </ModalTransition>
      )}
    </div>
  )
}

export default NewTagDownloadModal
