import { Col } from 'react-grid-system';
import styled from 'styled-components';

// Used in landing pages
export const LandingRightCol = styled(Col)`
    background: white;
    min-width: 400px !important;
    width: 50vw !important;
`;

// Used in landing pages
export const LandingLeftCol = styled(Col)`
    text-align: end;
    background: white;
    padding: unset !important;
    width: 50vw !important;
`;

export const FormCol = styled(Col)`
    margin: auto !important;
    width: 30vw !important;
    min-width: 300px;
`;