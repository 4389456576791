import React, { useState } from 'react'
// import styled from 'styled-components'
// import { Row, Col } from 'react-grid-system'

import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormSection, ErrorMessage, HelperMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
// import Select, { OptionType, ValueType } from '@atlaskit/select'
// import SectionMessage from '@atlaskit/section-message'
// import Toggle from '@atlaskit/toggle'
// import Textfield from '@atlaskit/textfield'
// import AddIcon from '@atlaskit/icon/glyph/add'
// import EditorHorizontalRuleIcon from '@atlaskit/icon/glyph/editor/horizontal-rule'

import { OrangeButton } from '../../../styled/Button'
import { MetadataDownloadFilters, MetadataDownloadMethod } from '../../../../types'
import { isNumber } from '../../../../helpers'

// const StyledTable = styled.table`
//   font-size: 12px;

//   th,
//   td,
//   tbody {
//     padding: 2px;
//     border: 1px solid black;
//     border-collapse: collapse;
//   }
// `

// const SubmitButton = styled(OrangeButton)`
//   margin: 0;
// `

interface onFormSubmitParams {
  filenamePrefix?: string
  tagCount: number
  startTagOffset: number
}

// const NewMetaFilterInput: React.FC<any> = ({ onAdd }) => {
//   const [newMetaFilterKey, setNewMetaFilterKey] = useState<string>()
//   const [newMetaFilterValue, setNewMetaFilterValue] = useState<string>()
//   return (
//     <Row style={{ alignItems: 'center' }}>
//       <Col sm={5}>
//         <Field label="Metadata Key Name" name="newMetaFilterKey">
//           {({ fieldProps }: any) => (
//             <>
//               <Textfield
//                 {...fieldProps}
//                 value={newMetaFilterKey}
//                 onChange={(e: any) => setNewMetaFilterKey(e.currentTarget?.value)}
//               />
//               <HelperMessage>e.g. variety</HelperMessage>
//             </>
//           )}
//         </Field>
//       </Col>
//       <Col sm={5}>
//         <Field label="Metadata Value" name="newMetaFilterValue">
//           {({ fieldProps }: any) => (
//             <>
//               <Textfield
//                 {...fieldProps}
//                 value={newMetaFilterValue}
//                 onChange={(e: any) => setNewMetaFilterValue(e.currentTarget?.value)}
//               />
//               <HelperMessage>e.g. pink lady</HelperMessage>
//             </>
//           )}
//         </Field>
//       </Col>
//       <Col sm={2} style={{ marginTop: '10px' }}>
//         <SubmitButton
//           style={{ height: '40px' }}
//           appearance="primary"
//           onClick={(e) => {
//             newMetaFilterKey && onAdd({ newMetaFilterKey, newMetaFilterValue })
//           }}
//           iconBefore={<AddIcon label="Add" size="medium" />}
//           shouldFitContainer={true}
//         />
//       </Col>
//     </Row>
//   )
// }

export interface NewMetaDownloadParams extends onFormSubmitParams {
  downloadMethod: MetadataDownloadMethod
  filters: MetadataDownloadFilters
}

interface NewTagMetadataDownloadModalProps {
  onSubmit: (data: NewMetaDownloadParams) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const NewTagMetadataDownloadModal: React.FC<NewTagMetadataDownloadModalProps> = ({ onSubmit, isOpen, setIsOpen }) => {
  interface DownloadMethodOption {
    label: string
    value: MetadataDownloadMethod
  }
  const [downloadMethod, setDownloadMethod] = useState<DownloadMethodOption>()
  // const [filtersChecked, setFiltersChecked] = useState<boolean>(false)
  const [metaFilters, setMetaFilters] = useState<any>({})

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setMetaFilters({})
    // setFiltersChecked(false)
    setDownloadMethod(undefined)
    setIsOpen(false)
  }

  const onFormSubmit = (data: onFormSubmitParams) => {
    const { filenamePrefix, tagCount, startTagOffset } = data

    let submitData: NewMetaDownloadParams = {
      filenamePrefix,
      tagCount: Number(tagCount),
      startTagOffset: Number(startTagOffset),
      downloadMethod: downloadMethod?.value || 'individual',
      filters: {
        metadata: metaFilters,
      },
    }
    onSubmit(submitData)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Submit
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div key={'new-tag-metadata-download-container'}>
      <OrangeButton onClick={open} appearance="primary">
        Set Up New Metadata Download
      </OrangeButton>
      {isOpen && (
        <ModalTransition>
          <ModalDialog
            heading="Start New Metadata Download"
            width={'large'}
            components={{
              Container: ({ children, className }) => (
                <>
                  <Form<onFormSubmitParams> onSubmit={onFormSubmit}>
                    {({ formProps }) => (
                      <form {...formProps} className={className}>
                        {children}
                      </form>
                    )}
                  </Form>
                </>
              ),
              Footer: footer,
            }}
          >
            {
              <>
                <FormSection>
                  
                  <p>Data will download as a CSV file.</p><p>Please note that field columns may not be in the same order in which they were uploaded.</p>

                  <Field
                    name="tagCount"
                    label="Number of Tags to Download"
                    validate={isNumber}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error === 'NOT_A_NUMBER' && <ErrorMessage>Not a valid number</ErrorMessage>}
                        <HelperMessage>
                          This is the total number of Fingerprints to find metadata for. Leave blank to get all.
                        </HelperMessage>
                      </>
                    )}
                  </Field>

                  <Field name="startTagOffset" label="Index Of First Tag" defaultValue={0} validate={isNumber}>
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error === 'NOT_A_NUMBER' && <ErrorMessage>Not a valid number</ErrorMessage>}
                        <HelperMessage>
                          This is the number of Fingerprints to skip. e.g. a value of 100 will skip the first 100
                          Fingerprints and start from the 101st Fingerprint.
                        </HelperMessage>
                      </>
                    )}
                  </Field>

                  <Field name="filenamePrefix" label="File Name Prefix">
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        <HelperMessage>
                          If provided, filenames will use this. Leave blank for random filenames.
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>
              </>
            }
          </ModalDialog>
        </ModalTransition>
      )}
    </div>
  )
}

export default NewTagMetadataDownloadModal
