import { User } from '../../../types'

export enum ProjectRoles {
  SUPER_USER = 'SUPER_USER',
  ADMIN = 'ADMIN',
  GUEST = 'GUEST',
}

export const ProjectRolesLabels: any = {
  'SUPER_USER': 'Laava Administrator',
  'ADMIN':'Administrator',
  'GUEST': 'Guest',
}

export enum SystemRoles {
  SUPER_USER = 'SUPER_USER',
  ADMIN = 'ADMIN',
}

export function systemRoleCanAccess(userRole: string, roleRequired: string) {
  switch (roleRequired) {
    case SystemRoles.SUPER_USER:
      return userRole === SystemRoles.SUPER_USER
    case SystemRoles.ADMIN:
      return userRole === SystemRoles.SUPER_USER || userRole === SystemRoles.ADMIN
    default:
      break
  }
}

export function projectRoleCanAccess({
  roleRequired,
  userRole,
  user,
}: {
  roleRequired?: string
  userRole?: string
  user?: User
  }) {
  if (user?.role?.name === SystemRoles.SUPER_USER) return true
  switch (roleRequired) {
    case ProjectRoles.SUPER_USER:
      return userRole === ProjectRoles.SUPER_USER
    case ProjectRoles.ADMIN:
      return userRole === ProjectRoles.SUPER_USER || userRole === ProjectRoles.ADMIN
    case ProjectRoles.GUEST:
      return userRole === ProjectRoles.GUEST || userRole === ProjectRoles.SUPER_USER || userRole === ProjectRoles.ADMIN
    default:
      return false
  }
}
