import React, { useState, Fragment } from 'react'
import axios from 'axios'
import { Container, Row } from 'react-grid-system'
import styled from 'styled-components'

import Form, { Field, ErrorMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { LandingRightCol, LandingLeftCol, FormCol } from '../../components/styled/Column'
import { OrangeButton } from '../../components/styled/Button'
import { emailValidator } from '../../helpers'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'

const SubmitButton = styled(OrangeButton)`
  margin: auto;
  display: block !important;
`

const ForgotYourPassword: React.FC = () => {
  const [requestSent, setRequestSent] = useState(false)
  const handleSubmit = (values: { email: string }) => {
    const email = values.email
    axios({
      // Activate the invitation
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/reset-password/${email}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setRequestSent(true)
      })
      .catch((error) => {
        console.log(error)
        setRequestSent(true)
      })
  }
  return (
    <>
      <Container fluid style={{ width: '100vw', padding: 'unset' }}>
        <Row nowrap={true}>
          <LandingRightCol>
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto' }} alt="Laava Manage" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ textAlign: 'center' }}>Request a password reset</h1>
            <br />
            <br />
            <br />
            <FormCol>
              {!requestSent && (
                <Form onSubmit={handleSubmit}>
                  {({ formProps }) => (
                    <form {...formProps}>
                      <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Field name="email" defaultValue="" label="Email" isRequired validate={emailValidator}>
                          {({ fieldProps, error }) => (
                            <Fragment>
                              <TextField {...fieldProps} />
                              {error === 'INVALID' && <ErrorMessage>Invalid email</ErrorMessage>}
                            </Fragment>
                          )}
                        </Field>
                      </Row>
                      <br />
                      <SubmitButton type="submit" appearance="primary">
                        Submit
                      </SubmitButton>
                      <br />
                      <br />
                      <p style={{ textAlign: 'center' }}>
                        Don't have an account? <a href="https://laava.id/register">Sign up</a> instead.
                      </p>
                    </form>
                  )}
                </Form>
              )}
              {requestSent && (
                <p style={{ textAlign: 'center' }}>
                  Your password reset request has been sent. Check your e-mail for further instructions.
                  <br />
                  <a href="/">Go back</a>
                </p>
              )}
            </FormCol>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ height: '100vh', minHeight: '720px' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </>
  )
}

export default ForgotYourPassword
