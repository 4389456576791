export function isNumber(value: any) {
    if (value && (isNaN(value) || Number(value) < 0)) {
        return 'NOT_A_NUMBER'
      }
}

export function isNumberGreaterThanZero(value: any) {
  if (value && (isNaN(value) || Number(value) <= 0)) {
      return 'NOT_A_NUMBER_GREATER_THAN_ZERO'
    }
}