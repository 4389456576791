import React from 'react';
import { connect } from "react-redux"
import { Redirect } from 'react-router-dom';
import { logout } from "../../state/account/actions"

interface Props {
    dispatch?: any;
}
  
const Logout: React.FC<Props> = ({ dispatch }) => {
    dispatch(logout())
    return (
        <>
            { <Redirect to={ { pathname: '/login' } } /> }
        </>
    )
}

export default connect(null, null)(Logout)

