import {
  // VIEWS
  VIEWS_REQUEST,
  VIEWS_RECEIVE,
  VIEWS_INVALIDATE,

  // COLLECTION VIEWS
  COLLECTION_VIEWS_REQUEST,
  COLLECTION_VIEWS_RECEIVE,
  COLLECTION_VIEWS_INVALIDATE,

  // ASSETS
  ASSETS_REQUEST,
  ASSETS_RECEIVE,
  ASSETS_INVALIDATE,

  // DRAFT VIEWS
  VIEW_SET_DRAFT,
} from './actions'

const initialViewsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
  draft: {},
}

const initialCollectionViewsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

const initialAssetsViewsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

const initialContentState = {
  views: initialViewsState,
  collectionViews: initialCollectionViewsState,
  assets: initialAssetsViewsState,
}

export const content = (state = initialContentState, action: any) => {
  switch (action.type) {
    // VIEWS
    case VIEWS_REQUEST:
      return Object.assign({}, state, {
        views: {
          isFetching: true,
        },
      })
    case VIEWS_RECEIVE:
      return Object.assign({}, state, {
        views: {
          isFetching: false,
          items: action.views,
          lastFetched: Date.now(),
          didInvalidate: false,
        },
      })
    case VIEWS_INVALIDATE:
      return Object.assign({}, state, {
        views: {
          didInvalidate: true,
        },
      })

    // DRAFT VIEWS
    case VIEW_SET_DRAFT:
      return Object.assign({}, state, {
        views: {
          ...state.views,
          draft: action.view,
        },
      })

    // COLLECTION VIEWS
    case COLLECTION_VIEWS_REQUEST:
      return Object.assign({}, state, {
        collectionViews: {
          ...state.collectionViews,
          isFetching: true,
        },
      })
    case COLLECTION_VIEWS_RECEIVE:
      return Object.assign({}, state, {
        collectionViews: {
          isFetching: false,
          items: action.collectionViews,
          lastFetched: Date.now(),
          didInvalidate: false,
        },
      })
    case COLLECTION_VIEWS_INVALIDATE:
      return Object.assign({}, state, {
        collectionViews: {
          ...state.collectionViews,
          didInvalidate: true,
        },
      })

    // ASSETS
    case ASSETS_REQUEST:
      return Object.assign({}, state, {
        assets: {
          ...state.assets,
          isFetching: true,
        },
      })
    case ASSETS_RECEIVE:
      return Object.assign({}, state, {
        assets: {
          isFetching: false,
          items: action.assets,
          lastFetched: Date.now(),
          didInvalidate: false,
        },
      })
    case ASSETS_INVALIDATE:
      return Object.assign({}, state, {
        assets: {
          ...state.assets,
          didInvalidate: true,
        },
      })

    default:
      return state
  }
}
