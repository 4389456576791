import React, { useState } from 'react'

import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, ErrorMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'

import { OrangeButton } from '../../../styled/Button'

const validateNumberOfTags = (value: any) => {
  if (isNaN(value)) {
    return 'NOT_A_NUMBER'
  }
  if (!Number.isInteger(Number(value))) {
    return 'NOT_AN_INTEGER'
  }
  return
}

interface Props {
  onSubmit: ({ name, domain, description, maxFingerprints }: {name: string, domain: string, description: string, maxFingerprints: number}) => void;
}

const NewCompanyModal: React.FC<Props> = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  const onFormSubmit = (data: {name: string, domain: string, description: string, maxFingerprints: number}) => {
    onSubmit({
      ...data,
      maxFingerprints: Number(data.maxFingerprints)
    })
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Create
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div>
      <OrangeButton
        appearance="primary"
        onClick={open}
        iconBefore={<AddCircleIcon primaryColor="white" secondaryColor="#FF5500" label="manage" />}
      >
        Create new company
      </OrangeButton>

      <ModalTransition>
        {isOpen && (
          <ModalDialog
            heading="Create new company"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onFormSubmit}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: footer,
            }}
          >
            <Field name="name" defaultValue="" label="Company name" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} maxLength={100} />}
            </Field>

            <Field name="domain" defaultValue="" label="Company website" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} maxLength={100} />}
            </Field>

            <Field name="description" defaultValue="" label="Company description" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} maxLength={200} />}
            </Field>

            <Field
              name="maxFingerprints"
              validate={validateNumberOfTags}
              defaultValue={0}
              label="Company Fingerprint limit"
              isRequired
            >
              {({ fieldProps, error }) => (
                <div>
                  <TextField {...fieldProps} maxLength={12} />
                  {error === 'NOT_A_NUMBER' && <ErrorMessage>Not a valid number</ErrorMessage>}
                  {error === 'NOT_AN_INTEGER' && <ErrorMessage>Not a valid integer</ErrorMessage>}
                </div>
              )}
            </Field>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  )
}

export default NewCompanyModal
