import React from 'react'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import PageHeader from '@atlaskit/page-header'

import BacklogIcon from '@atlaskit/icon/glyph/backlog'
import MediaServicesTextIcon from '@atlaskit/icon/glyph/media-services/text'
import MediaServicesRectangleIcon from '@atlaskit/icon/glyph/media-services/rectangle'
import ImageBorderIcon from '@atlaskit/icon/glyph/image-border'
import ImageIcon from '@atlaskit/icon/glyph/image'
import RecentIcon from '@atlaskit/icon/glyph/recent'
import MediaServicesVideoIcon from '@atlaskit/icon/glyph/media-services/video'
import LikeIcon from '@atlaskit/icon/glyph/like'
import BulletListIcon from '@atlaskit/icon/glyph/bullet-list'
import EmojiFoodIcon from '@atlaskit/icon/glyph/emoji/food'
import EditorTextStyleIcon from '@atlaskit/icon/glyph/editor/text-style'

const NewContentBlockDraggable = (props: any) => {
  const Block = styled.div`
    height: 50px;
    width: 295px;
    background-color: white;
    border: 2px solid gray;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-right: 20px;
  `

  return <Block>{props.children}</Block>
}

const SectionAndBlockPalette = (props: any) => {
  return (
    <>
      <PageHeader key={'page-header-add-content'}>Add content</PageHeader>
      <Droppable droppableId={'sectionDroppable'} isDropDisabled={true} type="SECTIONS">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              outline: snapshot.isDraggingOver ? '2px dashed black' : 'none',
            }}
            // isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            <h3>Sections</h3>
            <br />
            <Draggable key={'new-section'} draggableId={'new-section'} index={0}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={provided.draggableProps.style}
                >
                  {
                    <NewContentBlockDraggable>
                      <div style={{ margin: '0 15px' }}>
                        <BacklogIcon label="" key={'edit-icon'} />
                      </div>
                      <span>Section</span>
                    </NewContentBlockDraggable>
                  }
                </div>
              )}
            </Draggable>
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Droppable droppableId={'blocksDroppable'} isDropDisabled={true} type="CONTENT">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              outline: snapshot.isDraggingOver ? '2px dashed black' : 'none',
            }}
            // isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            <br />
            <h3>Content Blocks</h3>
            <br />
            <div style={{}}>
              <Draggable key={'new-content-block-text'} draggableId={'new-content-block-text'} index={0}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <MediaServicesTextIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Text</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-styles'} draggableId={'new-content-block-styles'} index={0}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <EditorTextStyleIcon label="" key={'styles-icon'} />
                        </div>
                        <span>Custom CSS</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-spacer'} draggableId={'new-content-block-spacer'} index={1}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <ImageBorderIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Spacer</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-button'} draggableId={'new-content-block-button'} index={1}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <MediaServicesRectangleIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Button</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-image'} draggableId={'new-content-block-image'} index={2}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <ImageIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Image</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-timeline'} draggableId={'new-content-block-timeline'} index={3}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <RecentIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Timeline</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-bulletList'} draggableId={'new-content-block-bulletList'} index={4}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <BulletListIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Bullet List</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-video'} draggableId={'new-content-block-video'} index={5}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <MediaServicesVideoIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Video</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-socialLinks'} draggableId={'new-content-block-socialLinks'} index={6}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <LikeIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Social Links</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
              <Draggable key={'new-content-block-recipe'} draggableId={'new-content-block-recipe'} index={7}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                  >
                    {
                      <NewContentBlockDraggable>
                        <div style={{ margin: '0 15px' }}>
                          <EmojiFoodIcon label="" key={'edit-icon'} />
                        </div>
                        <span>Recipe</span>
                      </NewContentBlockDraggable>
                    }
                  </div>
                )}
              </Draggable>
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </>
  )
}

export { SectionAndBlockPalette }
