import React, { useEffect, useState } from 'react'
import PreviewFingerprint from '../../../assets/preview-fingerprint.png'
import { LogoHolder, PreviewHolder, UploadedPreview } from './styles'
import { TagDownloadHubImageOption } from '../../../types'

interface FingerprintPreviewProps {
  file?: File
  url?: string
  hubImageOption?: TagDownloadHubImageOption
}

// This component renders a preview of a Fingerprint, with a custom logo if provided
const FingerprintPreview: React.FC<FingerprintPreviewProps> = ({ url, file, hubImageOption }) => {
  const [hubImageUrl, setHubImageUrl] = useState<string>(url || '')

  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file)
      setHubImageUrl(fileUrl)
    }
  }, [file])

  return (
    <PreviewHolder key={'preview-holder'}>
      <img
        src={PreviewFingerprint}
        style={{ width: '300px', position: 'absolute' }}
        alt="preview-fingerprint"
        key={'fp-preview-img'}
      />
      {(hubImageOption === 'custom-hub-image') && (
        <LogoHolder>
          {hubImageUrl && <UploadedPreview src={hubImageUrl} />}
        </LogoHolder>
      )}
      {(hubImageOption === 'no-hub-image') && (
        <LogoHolder />
      )}
    </PreviewHolder>
  )
}

export default FingerprintPreview
