import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { ButtonGroup } from '@atlaskit/button'
import Textfield from '@atlaskit/textfield'
import PageHeader from '@atlaskit/page-header'
import DynamicTable from '@atlaskit/dynamic-table'
import SearchIcon from '@atlaskit/icon/glyph/search'

import { Project, Collection, TagOrder, State } from '../../types'
import { dateFormatter } from '../../helpers'
import OrderStatus from '../../components/react/order-status'
import NewCollectionModal from '../../components/react/modals/new-collection-modal'
import { fetchProjectsIfNeeded } from '../../state/projects/actions'
import { createCollection, fetchCollectionsIfNeeded, collectionsInvalidate } from '../../state/collections/actions'
import { fetchTagOrdersIfNeeded, tagOrdersInvalidate } from '../../state/tag-orders/actions'
import { Restricted } from '../../providers/permission'

interface Props {
  user: any,
  isFetching?: boolean
  collections?: Collection[]
  tagOrders?: TagOrder[]
  project: Project
  dispatch?: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]

  return {
    isFetching: state.projects.isFetching || state.collections.isFetching || state.tagOrders.isFetching,
    collections: state.collections.items,
    tagOrders: state.tagOrders.items,
    user: state.account.login.user,
    project,
  }
}

const ProjectCollections: React.FC<Props> = ({ user, isFetching, project, collections, tagOrders, dispatch }) => {
  const [searchText, setSearchText] = useState<string>('')
  useEffect(() => {
    dispatch(collectionsInvalidate())
    dispatch(tagOrdersInvalidate())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
    dispatch(fetchCollectionsIfNeeded(project?.id))
    dispatch(fetchTagOrdersIfNeeded(project?.id))
  })

  interface ReturnedNewCollectionData {
    collectionName: string
  }

  const onSubmitNewCollection = (data: ReturnedNewCollectionData) => {
   dispatch(createCollection({ name: data.collectionName, project_id: project.id }))
  }

  if (searchText) {
    collections = collections?.filter((collection: Collection) =>
      collection.name.toUpperCase().includes(searchText.toUpperCase()),
    )
  }

  const collectionsHead = {
    cells: [
      {
        content: 'Name',
        shouldTruncate: true,
        key: 'name',
        isSortable: true,
      },
      {
        content: 'Total Fingerprints',
        key: 'tagCount',
        isSortable: true,
      },
      {
        content: 'Top-up Status',
        width: 12,
      },
      {
        content: 'Date Created',
        key: 'createdAt',
        isSortable: true,
      },
    ],
  }

  const collectionsCells = collections?.map((collection: Collection) => {
    const tagOrdersForCollection = tagOrders?.filter((tagOrder: TagOrder) => tagOrder.collectionId === collection.id)
    const totalNumberOfTags = tagOrdersForCollection?.reduce((count: any, tagOrder: TagOrder) => {
      return count + tagOrder.numberOfTags
    }, 0)
    const totalNumberOfTagsProcessing = totalNumberOfTags - collection?.tagCount || 0

    return {
      cells: [
        {
          content: (
            <Link to={`/projects/${project.id}/collections/${collection.id}/fingerprints`}>{collection.name}</Link>
          ),
          key: collection.name,
          height: 36,
        },
        {
          content: (
            <>
              <p style={{ float: 'left', margin: 0 }}>{collection.tagCount}</p>
              {totalNumberOfTagsProcessing > 0 && (
                <p style={{ float: 'right', fontWeight: 'lighter', margin: 0 }}>
                  +{totalNumberOfTagsProcessing} processing
                </p>
              )}
            </>
          ),
          key: collection.tagCount,
        },
        {
          content: (
            <OrderStatus
              totalNumberOfTagsProcessing={totalNumberOfTagsProcessing}
              totalNumberOfTags={totalNumberOfTags}
            />
          ),
        },
        {
          content: dateFormatter(collection.createdAt),
        },
      ],
    }
  })

  const actions = (
    <ButtonGroup>
      <Restricted to="collection:create" type="projects">
        {user.system_role > 1 && <NewCollectionModal onSubmit={onSubmitNewCollection} />}
      </Restricted>
    </ButtonGroup>
  )

  const bottomBar = (
    <Textfield
      placeholder="Search"
      width="250"
      onChange={(event) => {
        const element = event.currentTarget as HTMLInputElement
        setSearchText(element.value)
      }}
      elemAfterInput={<SearchIcon label="search" />}
    />
  )
  return (
    <>
      <PageHeader actions={actions} bottomBar={bottomBar}>
        Collections
      </PageHeader>
      <p style={{ fontWeight: 'lighter' }}>
        Showing {collections?.length} {collections?.length === 1 ? 'collection' : 'collections'}
      </p>
      <DynamicTable
        head={collectionsHead}
        rows={collectionsCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching}
        isFixedSize
        defaultSortKey="createdAt"
        defaultSortOrder="DESC"
        emptyView={<h2>This project is empty.</h2>}
      />
    </>
  )
}

export default connect(mapStateToProps)(ProjectCollections)
