import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux'
import { SystemRoles } from '../../components/accounts/roles';
import { Project, Role, State, User } from "../../types";
import RolePermission from '../../types/RolePermission';
import PermissionContext from "./context";

type Props = {
    project?: Project
    user: User
    dispatch: any
}

const fetchRoles = axios.get(`${process.env.REACT_APP_ACCMAN_URL}/roles`, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })

const mapStateToProps = (state: State, ownProps: any) => {
    return {
      user: state.account.login.user,
    }
  }

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({ project, user, dispatch, children }) => {

    // Creates a method that returns whether the requested permission is available in the list of permissions
    // passed as parameter
    const isAllowedTo = async (permission?: string, type?: 'projects' | 'system'): Promise<boolean> => {
        if (user?.role?.name === SystemRoles.SUPER_USER) return true
        const roles = await fetchRoles
        switch (type) {
            case 'projects':
                const userProject = project?.userProjects.filter(userProject => userProject.user.id === user.id)[0]
                const userProjectRole = roles.data.filter((role: Role) => role.id === userProject?.role_id)[0]
                return userProjectRole.rolePermissions.some((rolePermission: RolePermission) => rolePermission.permission.name === permission)
            case 'system':
                return user.role?.rolePermissions.some((rolePermission: RolePermission) => rolePermission.permission.name === permission) || false
            default:
                return false
        }
    };

    // This component will render its children wrapped around a PermissionContext's provider whose
    // value is set to the method defined above
    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default connect(mapStateToProps)(PermissionProvider)