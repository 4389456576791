import React, { useState } from 'react'
import TextField from '@atlaskit/textfield'
import TextArea from '@atlaskit/textarea'
import { Field, HelperMessage, ErrorMessage, FormSection } from '@atlaskit/form'
import { Label } from '@atlaskit/field-base'
import Select from '@atlaskit/select'
import { Checkbox } from '@atlaskit/checkbox'
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import { v4 as uuidv4 } from 'uuid'

import AssetSelector from '../modals/asset-selector'

import styled from 'styled-components'
import { HexColorPicker } from 'react-colorful'
import { OrangeButton } from '../../../components/styled/Button'
import { getSchemaForType, BlockSchemaDataField } from '@laava/result-view'

const FieldRow = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
`

const getContentBlockWithId = (id: string, view: any) => {
  const contentBlocks = view.content.blocks || view.content.sections

  for (var i = 0; i < contentBlocks.length; i += 1) {
    const section = contentBlocks[i]

    if (section.id === id) {
      return section
    }

    const sectionContent = section.data?.content || section.content

    for (var j = 0; j < sectionContent.length; j += 1) {
      const contentBlock = sectionContent[j]

      if (contentBlock.id === id) {
        return contentBlock
      }
    }
  }
}

const getAssetUrl = (assetId: string, assets: any[]) => {
  for (var i = 0; i < assets.length; i = i + 1) {
    const asset = assets[i]
    if (asset.id === assetId) {
      const url = asset.formats.small?.url || asset.formats.thumbnail?.url || asset.url

      return url
    }
  }
}

const EditPanel = (props: any) => {
  const { id, draftView, onUpdate, onClose, project, assets } = props
  const [openAssetSelectorId, setOpenAssetSelectorId] = useState('')

  let content: any

  const onFieldChange = (event: any) => {
    const updatedFieldName = event.target.name
    const updatedFieldValue = event.target.value

    let updatedContent: any = { data: { ...content.data } }

    Object.keys(content).forEach((key) => {
      let currentKeyValue

      if (key === updatedFieldName) {
        currentKeyValue = updatedFieldValue
      } else {
        currentKeyValue = content[key]
      }

      if (key === 'id' || key === 'type') {
        updatedContent[key] = currentKeyValue
      } else if (key !== 'data') {
        updatedContent.data[key] = currentKeyValue
      }
    })

    updatedContent.data[updatedFieldName] = updatedFieldValue

    onUpdate(id, updatedContent)
  }

  const getFormElement = (
    field: BlockSchemaDataField,
    value: any,
    customId: string = uuidv4(),
    onChange: any = onFieldChange,
  ) => {
    const { displayName, propertyName, description, type, itemFields, selectOptions } = field

    const fieldName = displayName.toString()
    const fieldProperty = propertyName.toString()
    const fieldValue = value || ''

    switch (type) {
      case 'string':
        return (
          <FieldRow>
            <div style={{ width: '100%', paddingBottom: '20' }}>
              <Field name={fieldProperty} label={fieldName}>
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      placeholder=""
                      {...fieldProps}
                      onSubmit={onChange}
                      onBlur={onChange}
                      onChange={(event) => onChange(event)}
                      value={fieldValue}
                    />

                    {!error && <HelperMessage>{description}</HelperMessage>}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </div>
          </FieldRow>
        )

      case 'text':
        return (
          <FieldRow>
            <div style={{ width: '100%', paddingBottom: '20' }}>
              <Field name={fieldProperty} label={fieldName}>
                {({ fieldProps, error }) => (
                  <>
                    <TextArea
                      resize="vertical"
                      placeholder=""
                      {...fieldProps}
                      onSubmit={onChange}
                      onBlur={onChange}
                      onChange={(event) => onChange(event)}
                      value={fieldValue}
                    />

                    {!error && <HelperMessage>{description}</HelperMessage>}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </div>
          </FieldRow>
        )

      case 'color':
        return (
          <>
            <div>
              <FieldRow>
                <div style={{ width: '200px' }}>
                  <Field
                    key={`${fieldName}-color-text`}
                    name={fieldName}
                    label={fieldName}
                    isRequired
                    // defaultValue={fieldValue}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField
                          placeholder=""
                          {...fieldProps}
                          value={fieldValue}
                          onChange={(event: any) => {
                            const value = event.target.value
                            const colorChangeEvent = { target: { name: fieldProperty, value } }
                            onChange(colorChangeEvent)
                          }}
                        />
                        {!error && <HelperMessage>{description}</HelperMessage>}
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </>
                    )}
                  </Field>
                </div>
              </FieldRow>
              <div style={{ margin: '20px 0' }}>
                <HexColorPicker
                  key={`${fieldName}-color`}
                  color={fieldValue}
                  onChange={(color) => {
                    const colorChangeEvent = { target: { name: fieldProperty, value: color } }
                    onChange(colorChangeEvent)
                  }}
                />
              </div>
            </div>
          </>
        )

      case 'style':
        const styles = draftView.content.styles

        const styleOptions = Object.keys(styles).map((style: any) => {
          return { label: style, value: style }
        })

        return (
          <>
            <FieldRow>
              <div style={{ width: '200px' }}>
                <Field key={fieldName} name={fieldName} label={displayName} isRequired defaultValue={fieldValue}>
                  {({ fieldProps, error }) => (
                    <>
                      <Select
                        label={displayName}
                        defaultValue={{
                          label: fieldValue,
                          value: fieldValue,
                        }}
                        onChange={(data: any) => {
                          const { value } = data
                          const styleChangeEvent = { target: { name: fieldProperty, value: value } }
                          onChange(styleChangeEvent)
                        }}
                        name={fieldName}
                        placeholder="Select a style"
                        options={styleOptions}
                      />
                      {!error && <HelperMessage>{description}</HelperMessage>}
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>
            </FieldRow>
          </>
        )

      case 'boolean':
        const booleanValue = value ? value : false

        return (
          <>
            <FieldRow>
              <div>
                <h1>{fieldValue}</h1>
                <Field key={fieldName} name={fieldName} isRequired defaultValue={fieldValue}>
                  {({ fieldProps, error }) => (
                    <>
                      {
                        <>
                          <br />
                          <p>{description}</p>
                        </>
                      }
                      <Checkbox
                        isChecked={booleanValue}
                        onChange={(booleanValue) => {
                          const isChecked = booleanValue.target.checked
                          const booleanChangeEvent = {
                            target: { name: fieldProperty, value: isChecked },
                          }
                          onChange(booleanChangeEvent)
                        }}
                        label={fieldName}
                        value={fieldName}
                        name={fieldName}
                      />

                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>
            </FieldRow>
          </>
        )

      case 'image':
        return (
          <div style={{ width: '250px' }}>
            <AssetSelector
              id={customId}
              key={'product_image_asset_id'}
              onSubmitAsset={(assetId: any, selectorId: string) => {
                const assetUrl = getAssetUrl(assetId, assets)
                const imageChangeEvent = {
                  target: { name: fieldProperty, value: { id: assetId, url: assetUrl } },
                }

                onChange(imageChangeEvent)
              }}
              currentAssetId={fieldValue?.id}
              project={project}
              isOpen={openAssetSelectorId === customId}
              setIsOpen={() => {
                setOpenAssetSelectorId(customId)
              }}
              setIsClosed={() => {
                setOpenAssetSelectorId('')
              }}
            />
          </div>
        )

      case 'object':
        const onFieldChange = (event: any) => {
          const updatedFieldName = event.target.name
          const updatedFieldValue = event.target.value

          let updatedContent: any = { data: { ...content.data } }

          Object.keys(content).forEach((key) => {
            let currentKeyValue

            currentKeyValue = content[key]

            if (key === 'id' || key === 'type') {
              updatedContent[key] = currentKeyValue
            } else if (key !== 'data') {
              updatedContent.data[key] = currentKeyValue
            }
          })

          updatedContent.data[fieldProperty][updatedFieldName] = updatedFieldValue
          onUpdate(id, updatedContent)
        }

        return (
          <div>
            {itemFields &&
              fieldValue &&
              itemFields.map((field, fieldIndex) => {
                return getFormElement(
                  field,
                  fieldValue[field.propertyName.toString()],
                  `field.displayName_${fieldIndex}`,
                  onFieldChange,
                )
              })}
          </div>
        )

      case 'list': {
        const onFieldChange = (event: any, fieldIndex: number) => {
          const changedItemName = event.target.name
          const changedItemValue = event.target.value

          const updatedFieldValue = fieldValue
          updatedFieldValue[fieldIndex][changedItemName] = changedItemValue

          const listChangeEvent = {
            target: { name: fieldProperty, value: updatedFieldValue },
          }

          onChange(listChangeEvent)
        }

        const addNewTimelineItem = () => {
          let updatedFieldValue = fieldValue

          if (!updatedFieldValue) {
            updatedFieldValue = [{}] // For nested lists
          } else {
            updatedFieldValue.splice(updatedFieldValue.length + 1, 0, {})
          }

          const listChangeEvent = {
            target: { name: fieldProperty, value: updatedFieldValue },
          }

          onChange(listChangeEvent)
        }

        return (
          <div>
            <Label label={fieldName} />
            {itemFields &&
              fieldValue &&
              fieldValue.map((field: any, fieldIndex: number) => {
                // now step through the schema to show each item's field

                const removeListItem = (index: number) => {
                  if (window.confirm('Are you sure you want to remove this list item?')) {
                    const updatedFieldValue = fieldValue
                    updatedFieldValue.splice(index, 1)

                    const listChangeEvent = {
                      target: { name: fieldProperty, value: updatedFieldValue },
                    }

                    onChange(listChangeEvent)
                  }
                }

                const duplicateListItem = (index: number) => {
                  const updatedFieldValue = fieldValue
                  const itemToCopy = fieldValue[index]
                  const copiedItem = Object.assign({}, itemToCopy)
                  updatedFieldValue.splice(index + 1, 0, copiedItem)

                  const listChangeEvent = {
                    target: { name: fieldProperty, value: updatedFieldValue },
                  }

                  onChange(listChangeEvent)
                }

                const actions = (
                  <ButtonGroup>
                    <Button onClick={() => duplicateListItem(fieldIndex)}>Duplicate</Button>
                    <Button onClick={() => removeListItem(fieldIndex)}>Remove</Button>
                  </ButtonGroup>
                )

                return (
                  <FormSection>
                    <div style={{ border: '1px solid lightgray', padding: '0 20px 20px' }}>
                      <PageHeader actions={actions} key={'page-header'}>
                        {fieldName} #{fieldIndex + 1}
                      </PageHeader>
                      {itemFields.map((itemField, index) => {
                        return getFormElement(
                          itemField,
                          field[itemField.propertyName.toString()],
                          `timeline-asset-${fieldIndex + 1}`,
                          (event: any) => {
                            onFieldChange(event, fieldIndex)
                          },
                        )
                      })}
                    </div>
                  </FormSection>
                )
              })}

            <FormSection>
              <div style={{ border: '1px solid lightgray', padding: '20px' }}>
                <ButtonGroup>
                  <Button onClick={() => addNewTimelineItem()}>Add New Item</Button>
                </ButtonGroup>
              </div>
            </FormSection>
          </div>
        )
      }

      case 'select':
        const defaultLabel = fieldValue ? `${fieldValue.charAt(0).toUpperCase()}${fieldValue.substring(1)}` : ''



        return (
          <>
            <FieldRow>
              <div style={{ width: '200px' }}>
                <Field key={fieldName} name={fieldName} label={displayName} isRequired defaultValue={fieldValue}>
                  {({ fieldProps, error }) => (
                    <>
                      <Select
                      value={{label: defaultLabel, value:fieldValue}}
                        label={displayName}
                        defaultValue={{
                          label: defaultLabel,
                          value: fieldValue,
                        }}
                        onChange={(data: any) => {
                          const { value } = data

                          const selectChangeEvent = { target: { name: fieldProperty, value: value } }
                          onChange(selectChangeEvent)
                        }}
                        name={fieldName}
                        placeholder={'Select'}
                        options={selectOptions}
                      />

                      {!error && <HelperMessage>{description}</HelperMessage>}
                      {error && <ErrorMessage>{error}</ErrorMessage>}
                    </>
                  )}
                </Field>
              </div>
            </FieldRow>
          </>
        )

      default: {
        return <>UNSUPPORTED FIELD TYPE</>
      }
    }
  }

  if (id) {
    content = getContentBlockWithId(id, draftView)

    const { type } = content
    const schema = getSchemaForType(type)

    const actions = (
      <ButtonGroup>
        <OrangeButton appearance="primary" onClick={onClose}>
          Done
        </OrangeButton>
      </ButtonGroup>
    )

    return (
      <>
        <PageHeader actions={actions} key={'page-header'}>
          Editing {type}
        </PageHeader>

        {schema?.map((field: any) => {
          const componentData = content.data || content
          const value = componentData[field.propertyName.toString()]
          const customId = `field-${field.propertyName.toString()}`
          return getFormElement(field, value, customId)
        })}
      </>
    )
  } else {
    return <h1>wut</h1>
  }
}

export { EditPanel }
