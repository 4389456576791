import styled from 'styled-components'
import Button from '@atlaskit/button'

export const OrangeButton = styled(Button)`
  margin: 0 4px;
  background-color: #ff5500 !important;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    color: white !important;
    opacity: 0.5;
  }
`
