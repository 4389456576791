import { isValidEmailAddress } from './is-valid-email-address'

export function passwordValidator(input?: string) {
  if (!input) {
    return
  }

  if (input.length < 8) {
    return 'TOO_SHORT'
  }

  if (!/[a-zA-Z]/.test(input)) {
    return 'NO_LETTERS'
  }

  if (!/\d/.test(input)) {
    return 'NO_NUMBERS'
  }

  return
}

export function emailValidator(input?: string) {
  if (!input) {
    return
  }
  if (!isValidEmailAddress(input)) {
    return 'INVALID'
  }
  return
}
