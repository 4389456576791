import axios from 'axios'
import { State, TagOrder } from '../../types'

export const TAG_ORDERS_REQUEST = 'REQUEST_TAG_ORDERS'
export const TAG_ORDERS_RECEIVE = 'TAG_ORDERS_RECEIVE'
export const TAG_ORDERS_INVALIDATE = 'TAG_ORDERS_INVALIDATE'
export const TAG_ORDERS_ERROR = 'TAG_ORDERS_ERROR'

const tagOrdersRequest = () => {
  return {
    type: TAG_ORDERS_REQUEST,
  }
}

const tagOrdersError = () => {
  return {
    type: TAG_ORDERS_ERROR,
  }
}

const tagOrdersReceive = (tagOrders: TagOrder[]) => {
  return {
    type: TAG_ORDERS_RECEIVE,
    tagOrders,
  }
}

export const tagOrdersInvalidate = () => {
  return {
    type: TAG_ORDERS_INVALIDATE,
  }
}

const fetchTagOrders = (project_id: string) => {
  return (dispatch: any) => {
    dispatch(tagOrdersRequest())
    // Our offset is based on how many we already have in our state
    axios
      .get(`${process.env.REACT_APP_TMAPI_BASE_URL}/tag-orders`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          project_id: project_id,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          fields: 'id,collectionId,updatedAt,createdAt,numberOfTags,status',
        },
      })
      .then((result) => {
        dispatch(tagOrdersReceive(result?.data))
      })
      .catch((error) => {
        console.log(error)
        return dispatch(tagOrdersError())
      })
  }
}

const shouldFetchTagOrders = (state: State): boolean => {
  const lastFetched = state.tagOrders.lastFetched
  const isFetching = state.tagOrders.isFetching
  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.tagOrders.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchTagOrdersIfNeeded = (project_id: string) => {
  return (dispatch: any, getState: any) => {
    if (project_id && shouldFetchTagOrders(getState())) {
      return dispatch(fetchTagOrders(project_id))
    }
  }
}

interface createTagOrderParams {
  numberOfTags: number
  collectionId: string
  projectId: string
}

export const createTagOrder = ({ numberOfTags, collectionId, projectId }: createTagOrderParams) => {
  return (dispatch: any) => {

    const tagVersion = 1
    
    const data = {
      collectionId,
      tagVersion,
      numberOfTags,
    }

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_TMAPI_BASE_URL}/tag-order`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: projectId,
        'Content-Type': 'application/json',
      },
      data,
    })
      .then((_result) => {
        // Error/success handling goes here?
        return dispatch(tagOrdersInvalidate())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
