import React from 'react'
import styled from 'styled-components'
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts'

import { FaMobileAlt } from 'react-icons/fa'

import { ScanEvent } from '../../../../types'

const Box = styled.div`
  min-width: 0;
  text-align: center;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  height: 500px;
  width: 100%;
`

interface Props {
  scanEvents: ScanEvent[]
}

const colors = [
  '#f5c84c',
  '#f57f31',
  '#fa4639',
  '#ff3d77',
  '#fc88c0',
  '#c888fc',
  '#5430c9',
  '#a8a5b0',
  '#8884d8',
  '#83a6ed',
  '#8dd1e1',
  '#82ca9d',
  '#a4de6c',
  '#d0ed57',
  '#ebdf65',
]

const ScansByScannerTypeBox: React.FC<Props> = ({ scanEvents }) => {
  let dataByScannerType = [
    ...scanEvents
      .reduce((accumulator: any, currentValue: any) => {
        if (!accumulator.has(currentValue.clientAgent))
          accumulator.set(currentValue.clientAgent, {
            ...currentValue,
            name: `${currentValue.clientAgent}`,
            value: 0,
          })
        accumulator.get(currentValue.clientAgent).value++
        return accumulator
      }, new Map())
      .values(),
  ]

  if (dataByScannerType.length > colors.length) {
    // We don't want too many values in our legend
    dataByScannerType = dataByScannerType.sort((a, b) => parseFloat(b.value) - parseFloat(a.value)) // Sort data
    let leftoverScanCount = 0
    dataByScannerType.slice(colors.length + 1).map((scannerType) => {
      return (leftoverScanCount += scannerType.value)
    }) // Get scan count for excess
    dataByScannerType.length = colors.length + 1 // Remove excess
    dataByScannerType.push({ name: 'Other', value: leftoverScanCount }) // Add consolidated excess
  }

  return (
    <Box>
      <h3 style={{ paddingTop: '10px' }}>
        Scans By Scanner Type <FaMobileAlt />
      </h3>
      <ResponsiveContainer>
        <PieChart>
          <Pie dataKey="value" isAnimationActive={false} data={dataByScannerType} label labelLine minAngle={2}>
            {dataByScannerType.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.name !== 'Unknown' ? colors[index] : '#dfe1e5'} />
            ))}
          </Pie>
          <Tooltip />
          <Legend align="right" verticalAlign="middle" layout="vertical" wrapperStyle={{ maxWidth: '200px' }} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default ScansByScannerTypeBox
