import axios from 'axios'
import { Project, State, User } from '../../types'
import { invitationsInvalidate } from '../account/actions'

// Projects
export const PROJECTS_REQUEST = 'PROJECTS_REQUEST'
export const PROJECTS_RECEIVE = 'PROJECTS_RECEIVE'
export const PROJECTS_INVALIDATE = 'PROJECTS_INVALIDATE'
export const PROJECTS_ERROR = 'PROJECTS_ERROR'

const projectsRequest = () => {
  return {
    type: PROJECTS_REQUEST,
  }
}

const projectsReceive = (projects: Project[]) => {
  return {
    type: PROJECTS_RECEIVE,
    projects,
  }
}

export const projectsInvalidate = () => {
  return {
    type: PROJECTS_INVALIDATE,
  }
}

const projectsError = () => {
  return {
    type: PROJECTS_ERROR,
  }
}

export const fetchProjects = () => {
  return async (dispatch: any) => {
    dispatch(projectsRequest())
    const url = `${process.env.REACT_APP_ACCMAN_URL}/projects`

    await axios({
      // Get all projects available for this user
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((result) => {
        return dispatch(projectsReceive(result.data))
      })
      .catch((error) => {
        console.log(error)
        return dispatch(projectsError())
      })
  }
}

const shouldFetchProjects = (state: State): boolean => {
  const lastFetched = state.projects.lastFetched
  const isFetching = state.projects.isFetching
  const canFetch = state.account.login.user.role?.rolePermissions.some(
    (rolePermission) => rolePermission.permission.name === 'project:read',
  )

  if (!isFetching && canFetch) {
    if (!lastFetched) {
      return true
    } else {
      return state.projects.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchProjectsIfNeeded = () => {
  return (dispatch: any, getState: any) => {
    if (shouldFetchProjects(getState())) {
      return dispatch(fetchProjects())
    }
  }
}

export const fetchFingerprintLimitForProject = (project: Project) => {
  return async (dispatch: any) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/companies/${project.company_id}/tags`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        project_id: project.id,
      },
    })
      .then((result: any) => {
        return dispatch(projectsInvalidate())
      })
      .catch((error: any) => {
        console.log(error)
      })
  }
}

interface CreateProjectParams {
  name: string
  users: { id: number }[]
  companyId: number
}

export const createProject = (data: CreateProjectParams) => {
  return (dispatch: any, getState: any) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/projects`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        name: data.name,
        users: data.users,
        companyId: data.companyId
      },
    })
      .then((result) => {
        dispatch(projectsInvalidate())
        dispatch(fetchProjects())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

interface AddProjectUsersParams {
  users: ({ id: number; projectRoleId: number } | { email: string; projectRoleId: number })[]
  projectId: string
}

export const addUsersToProject = (props: AddProjectUsersParams) => {
  return (dispatch: any) => {
    axios({
      // Add users to project
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/projects/${props.projectId}/users`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: { users: props.users },
    })
      .then((result) => {
        dispatch(invitationsInvalidate())
        dispatch(projectsInvalidate())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

interface DeleteProjectUserParams {
  user: User
  projectId: string
}

export const deleteProjectUser = (props: DeleteProjectUserParams) => {
  return (dispatch: any) => {
    const { user, projectId } = props
    axios({
      // Add users to project
      method: 'delete',
      url: `${process.env.REACT_APP_ACCMAN_URL}/projects/${projectId}/user/${user.id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      }
    })
      .then((result) => {
        dispatch(projectsInvalidate())
        dispatch(fetchProjects())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
