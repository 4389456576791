import styled from 'styled-components'

const StyledFloatingControls = styled.div`
  width: 100%;
  height: 20px;
  position: absolute;
  text-align: ${(props) => (props.type === 'section' ? 'start' : 'end')};
  padding: 0 5px;
  button {
    margin-top: 5px;
    margin-right: 5px;
  }
  z-index: 9999;
`

export { StyledFloatingControls }
