import uuid from 'react-uuid'

import { TAG_DOWNLOAD_ADD, TAG_DOWNLOADS_UPDATE } from './actions'

const initialTagDownloadsState = {
  items: [],
}

export const tagDownloads = (state = initialTagDownloadsState, action: any) => {
  switch (action.type) {
    case TAG_DOWNLOAD_ADD:
      const tagDownloadWithId = Object.assign(
        {
          id: uuid(),
        },
        action.newTagDownload,
      )

      return {
        ...state,
        items: [...state.items, tagDownloadWithId],
      }
    case TAG_DOWNLOADS_UPDATE:
      return {
        ...state,
        items: action.tagDownloads,
      }
    default:
      return state
  }
}
