import { SCAN_EVENTS_REQUEST, SCAN_EVENTS_RECEIVE, SCAN_EVENTS_INVALIDATE, SCAN_EVENTS_ERROR } from './actions'

const initialScanEventsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

export const scanEvents = (state = initialScanEventsState, action: any) => {
  switch (action.type) {
    case SCAN_EVENTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case SCAN_EVENTS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.scanEvents,
        lastFetched: Date.now(),
        didInvalidate: false,
      })
    case SCAN_EVENTS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case SCAN_EVENTS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      })
    default:
      return state
  }
}
