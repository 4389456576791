import axios from 'axios'

export const COLLECTIONS_REQUEST = 'COLLECTIONS_REQUEST'
export const COLLECTIONS_RECEIVE = 'COLLECTIONS_RECEIVE'
export const COLLECTIONS_INVALIDATE = 'COLLECTIONS_INVALIDATE'
export const COLLECTIONS_ERROR = 'COLLECTIONS_ERROR'

const collectionsRequest = () => {
  return {
    type: COLLECTIONS_REQUEST,
  }
}

const collectionsReceive = (collections: any) => {
  return {
    type: COLLECTIONS_RECEIVE,
    collections,
  }
}

export const collectionsInvalidate = () => {
  return {
    type: COLLECTIONS_INVALIDATE,
  }
}

export const collectionsError = () => {
  return {
    type: COLLECTIONS_ERROR,
  }
}

export const fetchCollections = (project_id: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(collectionsRequest())

    let isDone = false

    let continuationToken: string = ''
    const collections: any[] = []

    do {
      const result = await axios.get(
        `${process.env.REACT_APP_TMAPI_BASE_URL}/collections?continue=${continuationToken}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            project_id: project_id,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          params: {
            fields: 'id,name,updatedAt,createdAt',
          },
        },
      )

      if (!result.data.continuationToken) isDone = true
      else continuationToken = result.data.continuationToken
      collections.push(...result.data.data)
    } while (!isDone)

    dispatch(collectionsReceive(collections))
  }
}

const shouldFetchCollections = (state: any): boolean => {
  const lastFetched = state.collections.lastFetched
  const isFetching = state.collections.isFetching

  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.collections.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchCollectionsIfNeeded = (project_id: string) => {
  return (dispatch: any, getState: any) => {
    if (project_id && shouldFetchCollections(getState())) {
      dispatch(fetchCollections(project_id))
    }
  }
}

interface CreateCollectionParams {
  name: string
  project_id: string
}

export const createCollection = ({ name, project_id }: CreateCollectionParams) => {
  return (dispatch: any) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_TMAPI_BASE_URL}/collections`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: project_id,
        'Content-Type': 'application/json',
      },
      data: { name },
    })
      .then((result) => {
        dispatch(collectionsInvalidate())
        dispatch(fetchCollections(project_id))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
