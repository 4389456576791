import React, { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { faEdit, faClone, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StyledFloatingControls } from './styles'

const FloatingControls = (props: any) => {
  const { id, onEditButtonClicked, onDeleteButtonClicked, onCloneButtonClicked, type } = props

  const styledFloatingControlsProps = {
    type,
  }

  return (
    <StyledFloatingControls id={id} {...styledFloatingControlsProps}>
      <button type="button" onClick={() => onEditButtonClicked(id)}>
        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
      </button>
      <button type="button" onClick={() => onCloneButtonClicked(id)}>
        <FontAwesomeIcon icon={faClone}></FontAwesomeIcon>
      </button>
      <button type="button" onClick={() => onDeleteButtonClicked(id)}>
        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
      </button>
    </StyledFloatingControls>
  )
}

const SectionWrapper = (props: any) => {
  const { draggableId, sectionOverlayActions, id } = props
  const { onEditButtonClicked, onCloneButtonClicked, onDeleteButtonClicked } = sectionOverlayActions

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    userSelect: 'none',
    opacity: isDragging ? 0.5 : 1,
    ...draggableStyle,
  })

  const Section = (props: any) => {
    const [isRolledOver, setIsRolledOver] = useState(false)

    const onPointerEnter = (e: any) => {
      setIsRolledOver(true)
    }

    const onPointerLeave = (e: any) => {
      setIsRolledOver(false)
    }

    const shouldRenderOverlay = isRolledOver

    console.log({ shouldRenderOverlay, id })

    return (
      <div style={{ position: 'relative' }} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
        {shouldRenderOverlay && !props.isDragging && (
          <FloatingControls
            key={'section_controls_' + id}
            id={props.id}
            type={'section'}
            onEditButtonClicked={onEditButtonClicked}
            onCloneButtonClicked={onCloneButtonClicked}
            onDeleteButtonClicked={onDeleteButtonClicked}
          />
        )}
        {props.children}
      </div>
    )
  }

  return (
    // <div style={{ position: 'relative' }} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
    <Draggable draggableId={draggableId} index={props.index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <Section id={id} isDragging={snapshot.isDragging}>
            {props.children}
          </Section>
        </div>
      )}
    </Draggable>
  )
}

const ResultViewEditorWrapper = (props: any) => {
  return <DragDropContext onDragEnd={props.onDragEnd}>{props.children}</DragDropContext>
}

const BlockContentWrapper = (props: any, children: any) => {
  const { id, blockContentOverlayActions } = props
  const { onEditButtonClicked, onCloneButtonClicked, onDeleteButtonClicked } = blockContentOverlayActions

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    opacity: isDragging ? 0.5 : 1,
    outline: isDragging ? '3px ridge #ff660088' : 'none',

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // boxShadow: isDragging ? "5px 10px 8px #888888" : 0,

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const EditableContentBlock = (props: any) => {
    const [isRolledOver, setIsRolledOver] = useState(false)

    const onPointerEnter = (e: any) => {
      setIsRolledOver(true)
    }

    const onPointerLeave = (e: any) => {
      setIsRolledOver(false)
    }

    return (
      <div style={{ position: 'relative' }} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
        {isRolledOver && !props.isDragging && (
          <FloatingControls
            key={'block_controls_' + id}
            id={props.id}
            type={'block-content'}
            onEditButtonClicked={onEditButtonClicked}
            onCloneButtonClicked={onCloneButtonClicked}
            onDeleteButtonClicked={onDeleteButtonClicked}
          />
        )}
        {props.children}
      </div>
    )
  }

  return (
    <Draggable key={`draggable-${props.draggableId}`} draggableId={props.draggableId} index={props.index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <EditableContentBlock id={id} isDragging={snapshot.isDragging}>
            {props.children}
          </EditableContentBlock>
        </div>
      )}
    </Draggable>
  )
}

const SectionsWrapper = (props: any) => {
  return (
    <Droppable droppableId={`sections-droppable`} type="SECTIONS">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            outline: snapshot.isDraggingOver ? '2px dashed black' : 'none',
          }}
          {...provided.droppableProps}
        >
          {props.children}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

const SectionContentsWrapper = (props: any) => {
  return (
    <Droppable droppableId={`${props.id}`} type="CONTENT">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            outline: snapshot.isDraggingOver ? '2px dashed white' : 'none',
            minHeight: '10px',
          }}
          {...provided.droppableProps}
        >
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export { ResultViewEditorWrapper, SectionsWrapper, SectionContentsWrapper, SectionWrapper, BlockContentWrapper }
