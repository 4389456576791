import React, { useEffect, useRef, useState, useCallback } from 'react'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import styled from 'styled-components'

const Swatch = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: inherit;
`

const Popover = styled.div`
  top: 34px;
  z-index: 1;
  display: grid;
  position: relative;
  background-color: white;
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
`

interface ColorPickerProps {
  color: string
  setColor: any
}

// Improved version of https://usehooks.com/useOnClickOutside/
const useClickOutside = (ref: { current: any }, handler: (arg0: any) => void) => {
  useEffect(() => {
    let startedInside = false
    let startedWhenMounted = false

    const listener = (event: { target: any }) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return

      handler(event)
    }

    const validateEventStart = (event: { target: any }) => {
      startedWhenMounted = ref.current
      startedInside = ref.current && ref.current.contains(event.target)
    }

    document.addEventListener('mousedown', validateEventStart)
    document.addEventListener('touchstart', validateEventStart)
    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('mousedown', validateEventStart)
      document.removeEventListener('touchstart', validateEventStart)
      document.removeEventListener('click', listener)
    }
  }, [ref, handler])
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, setColor }) => {
  const popover = useRef<any>()
  const [isOpen, setIsOpen] = useState(false)
  const [colorPickerColor, setColorPickerColor] = useState(color)

  const close = useCallback(() => {
    setColor(colorPickerColor)
    setIsOpen(false)
  }, [colorPickerColor, setColor])

  useClickOutside(popover, close)
  return (
    <Swatch style={{ backgroundColor: colorPickerColor }} onClick={() => setIsOpen(true)} key={'swatch'}>
      {isOpen && (
        <div ref={popover} key={'popover'}>
          <Popover key={'popover-container'}>
            <HexColorInput color={colorPickerColor} onChange={setColorPickerColor} key={'input'} />
            <HexColorPicker color={colorPickerColor} onChange={setColorPickerColor} key={'color-picker'} />
          </Popover>
        </div>
      )}
    </Swatch>
  )
}

export default ColorPicker
