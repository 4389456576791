import { combineReducers } from 'redux'

import { account } from './account/reducers'
import { projects } from './projects/reducers'
import { collections } from './collections/reducers'
import { tags } from './tags/reducers'
import { tagOrders } from './tag-orders/reducers'
import { tagMetadata } from './tag-metadata/reducers'
import { scanEvents } from './scan-events/reducers'
import { content } from './content/reducers'
import { tagDownloads } from './tag-downloads/reducers'

const appReducer = combineReducers({
  account,
  projects,
  collections,
  tags,
  tagOrders,
  tagMetadata,
  scanEvents,
  content,
  tagDownloads,
})

const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset all redux state
  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
