import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DynamicTable from '@atlaskit/dynamic-table'
import EmojiFlagsIcon from '@atlaskit/icon/glyph/emoji/flags'

import { ScanEvent } from '../../../../types'

const Box = styled.div`
  min-width: 0;
  text-align: center;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  width: 100%;
`

interface Props {
  scanEvents: ScanEvent[]
}

const FlaggedTable: React.FC<Props> = ({ scanEvents }) => {
  const flaggedScans = scanEvents.filter((scanEvent) => scanEvent?.ruleStatus)

  const tableHead = {
    cells: [
      {
        content: 'Fingerprint ID',
        shouldTruncate: false,
        key: 'tagId',
        isSortable: true,
        width: 50,
      },
      {
        content: 'Status Flagged',
        shouldTruncate: true,
        key: 'ruleStatus',
        isSortable: true,
        width: 25,
      },
      {
        content: 'Date Flagged',
        key: 'createdAt',
        isSortable: true,
        width: 25,
      },
    ],
  }

  const tableCells = flaggedScans.map((scanEvent, index) => {
    return {
      cells: [
        {
          content: scanEvent.tagId,
          key: `${scanEvent.tagId}-${index}`,
          height: 36,
        },
        {
          content: scanEvent.ruleStatus,
          key: `${scanEvent.ruleStatus}-${index}`,
        },
        {
          content: moment(scanEvent.createdAt).calendar(null, {
            sameElse: 'MMM D, YYYY, h:mm a',
          }),
          key: `${scanEvent.createdAt}-${index}`,
        },
      ],
    }
  })

  return (
    <Box>
      <h3 style={{ paddingTop: '10px' }}>
        Flagged Scans <EmojiFlagsIcon secondaryColor="white" label="flag" />
      </h3>
      <div style={{ padding: '10px' }}>
        <DynamicTable
          head={tableHead}
          rows={tableCells}
          rowsPerPage={6}
          defaultPage={1}
          loadingSpinnerSize="large"
          defaultSortKey="createdAt"
          defaultSortOrder="DESC"
          emptyView={<h4 style={{ fontWeight: 100 }}>Scans not found</h4>}
        />
      </div>
    </Box>
  )
}

export default FlaggedTable
