import { TagDownloadStatus } from '../../types'

export const TAG_DOWNLOAD_ADD = 'TAG_DOWNLOAD_ADD'
export const TAG_DOWNLOAD_UPDATE = 'TAG_DOWNLOAD_UPDATE'
export const TAG_DOWNLOADS_UPDATE = 'TAG_DOWNLOADS_UPDATE'

export const addTagDownload = (newTagDownload: any) => {
  return {
    type: TAG_DOWNLOAD_ADD,
    newTagDownload,
  }
}

export const updateTagDownload = (tagDownload: any) => {
  return (dispatch: any, getState: any) => {
    const currentTagDownloads: any = getState().tagDownloads.items
    const updatedTagDownloads = currentTagDownloads.map((currentTagDownload: any) => {
      if (currentTagDownload.id === tagDownload.id) {
        const newTagDownload = Object.assign({}, currentTagDownload, {
          tagsComplete: [...currentTagDownload.tagsComplete, tagDownload.tagsComplete],
          status: tagDownload.status,
        })
        return newTagDownload
      } else {
        return currentTagDownload
      }
    })
    dispatch(updateTagDownloads(updatedTagDownloads))
  }
}

interface UpdateTagDownloadStatusParams {
  tagDownloadId: string
  status: TagDownloadStatus
}

export const updateTagDownloadStatus = ({ tagDownloadId, status }: UpdateTagDownloadStatusParams) => {
  return (dispatch: any, getState: any) => {
    const currentTagDownloads: any = getState().tagDownloads.items
    const updatedTagDownloads = currentTagDownloads.map((currentTagDownload: any) => {
      if (currentTagDownload.id === tagDownloadId) {
        const newTagDownload = Object.assign({}, currentTagDownload, {
          status: status,
        })
        return newTagDownload
      } else {
        return currentTagDownload
      }
    })
    dispatch(updateTagDownloads(updatedTagDownloads))
  }
}

interface UpdateTagDownloadTagsCompleteParams {
  tagDownloadId: string
  tagsComplete: number
}

export const updateTagDownloadTagsComplete = ({ tagDownloadId, tagsComplete }: UpdateTagDownloadTagsCompleteParams) => {
  return (dispatch: any, getState: any) => {
    const currentTagDownloads: any = getState().tagDownloads.items

    const updatedTagDownloads = currentTagDownloads.map((currentTagDownload: any) => {
      if (currentTagDownload.id === tagDownloadId) {
        const newTagDownload = Object.assign({}, currentTagDownload, {
          tagsComplete,
        })
        return newTagDownload
      } else {
        return currentTagDownload
      }
    })
    dispatch(updateTagDownloads(updatedTagDownloads))
  }
}

interface UpdateTagDownloadPercentageCompleteParams {
  tagDownloadId: string
  percentageComplete: number
}

export const updateTagDownloadPercentageComplete = ({
  tagDownloadId,
  percentageComplete,
}: UpdateTagDownloadPercentageCompleteParams) => {
  return (dispatch: any, getState: any) => {
    const currentTagDownloads: any = getState().tagDownloads.items

    let isChanged = false

    const updatedTagDownloads = currentTagDownloads.map((currentTagDownload: any) => {
      if (currentTagDownload.id === tagDownloadId && currentTagDownload.percentageComplete !== percentageComplete) {
        const newTagDownload = Object.assign({}, currentTagDownload, {
          percentageComplete,
        })
        isChanged = true
        return newTagDownload
      } else {
        return currentTagDownload
      }
    })
    if (isChanged) dispatch(updateTagDownloads(updatedTagDownloads))
  }
}

// interface UpdateTagDownloadTagCompleteParams {
//   tagDownloadId: string
//   tag: Tag
// }

// export const updateTagDownloadTagComplete = ({ tagDownloadId, tag }: UpdateTagDownloadTagCompleteParams) => {
//   return (dispatch: any, getState: any) => {
//     const currentTagDownloads: any = getState().tagDownloads.items

//     const updatedTagDownloads = currentTagDownloads.map((currentTagDownload: any) => {
//       if (currentTagDownload.id === tagDownloadId) {
//         const newTagDownload = Object.assign({}, currentTagDownload, {
//           tagsComplete: currentTagDownload.tagsComplete,
//         })
//         return newTagDownload
//       } else {
//         return currentTagDownload
//       }
//     })
//     dispatch(updateTagDownloads(updatedTagDownloads))
//   }
// }

export const updateTagDownloads = (tagDownloads: any) => {
  return {
    type: TAG_DOWNLOADS_UPDATE,
    tagDownloads,
  }
}
