import React from 'react';
import usePermission from "./usePermission";

type Props = {
    to?: string;
    type?: 'projects' | 'system'
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({to, type, children}) => {

    // We "connect" to the provider thanks to the PermissionContext
    const [allowed] = usePermission(to, type);

    // If the user has that permission, render the children
    if(allowed){
        return <>{children}</>;
    }


    // Otherwise, do not render anything
    return null;
};

export default Restricted;