import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { BarChart, XAxis, Legend, Tooltip, ResponsiveContainer, Bar, YAxis } from 'recharts'

import CalendarIcon from '@atlaskit/icon/glyph/calendar'

import { ScanEvent } from '../../../../types'

const Box = styled.div`
  min-width: 0;
  text-align: center;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  height: 500px;
  width: 100%;
`

interface Props {
  scanEvents: ScanEvent[]
}

const DailyScansBox: React.FC<Props> = ({ scanEvents }) => {
  let dataSummary: any = {}

  // Get a daily scan summary
  scanEvents.forEach((scanEvent) => {
    let day = String(moment(scanEvent.createdAt).startOf('day'))
    dataSummary[day] = dataSummary[day] || 0
    dataSummary[day]++
  })

  let dataFormatted: { name: string; Scans: number }[] = []
  let keys = Object.keys(dataSummary)
  // Next we sort the keys (dates with scans) in date order
  let keysSorted = keys.sort((a: any, b: any) => {
    return new Date(a).getTime() - new Date(b).getTime()
  })

  // Format the data for our bar chart
  for (let i = 0; i < keysSorted.length; i++) {
    // Here we fill in any missing days
    if (i > 0) {
      let diff = moment(keysSorted[i]).diff(moment(keysSorted[i - 1]), 'days')
      if (diff > 1) {
        for (let j = 1; j < diff; j++) {
          dataFormatted.push({
            name: moment(keysSorted[i - 1])
              .add(j, 'days')
              .format('ddd, MMM Do YYYY'),
            Scans: 0,
          })
        }
      }
    }
    dataFormatted.push({ name: moment(keysSorted[i]).format('ddd, MMM Do YYYY'), Scans: dataSummary[keysSorted[i]] })
  }

  return (
    <Box>
      <h3 style={{ paddingTop: '10px' }}>
        Daily Scans <CalendarIcon label="calendar" />
      </h3>
      <ResponsiveContainer>
        <BarChart data={dataFormatted}>
          <YAxis width={30} />
          <XAxis dataKey="name" />
          <Tooltip />
          <Legend />
          <Bar dataKey="Scans" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default DailyScansBox
