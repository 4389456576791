import React from 'react'
import { useLocation } from 'react-router-dom'
import { RiBearSmileLine } from 'react-icons/ri'
import Button from '@atlaskit/button'
import { AtlassianNavigation, PrimaryButton } from '@atlaskit/atlassian-navigation'
import { CustomProductHome } from '@atlaskit/atlassian-navigation'
import Logo from '../../../assets/logo_laava_manage_alpha.svg'
import { Roles } from '../../accounts'

const LogoButton = () => (
  <CustomProductHome iconUrl={Logo} iconAlt={'Laava Manage'} logoUrl={Logo} logoAlt={'Laava Manage'} href={`/`} />
)

const LogOut = () => <Button href={`/logout`}>Log out</Button>

interface Props {
  userRole: string
}

const Header: React.FC<Props> = ({ userRole }) => {
  const Admin = () => {
    return (
      <>
        <Button href={`/need-help`}>Need help?</Button>
        {userRole === Roles.SystemRoles.SUPER_USER && <Button href={`/admin`}>Account settings</Button>}
      </>
    )
  }

  const location = useLocation()
  const primaryItems = [
    <PrimaryButton isHighlighted={location.pathname.startsWith('/projects')} href={`/projects`}>
      Projects
    </PrimaryButton>,
    userRole === Roles.SystemRoles.SUPER_USER && (
      <PrimaryButton isHighlighted={location.pathname.startsWith('/ted')} href={`/ted`}>
        <h3>
          <RiBearSmileLine />
        </h3>
      </PrimaryButton>
    ),
  ]

  return (
    <AtlassianNavigation
      label="site"
      primaryItems={primaryItems}
      renderProductHome={LogoButton}
      renderSettings={Admin}
      renderSignIn={LogOut}
    />
  )
}

export default Header
