import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _, { chunk } from 'lodash'
import { Row, Col, Container } from 'react-grid-system'
import ReactJson from 'react-json-view'
import { Link } from 'react-router-dom'
import axios from 'axios'

import SectionMessage from '@atlaskit/section-message'
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import Pagination from '@atlaskit/pagination'
import { DatePicker } from '@atlaskit/datetime-picker'
import Lozenge from '@atlaskit/lozenge'
import Select from '@atlaskit/select'
import { Checkbox } from '@atlaskit/checkbox'
import TextField from '@atlaskit/textfield'

import { Collection, Project, ScanEventVerbose, State } from '../../types'
import { fetchScanEvents } from '../../state/scan-events/actions'
import { fetchCollectionsIfNeeded } from '../../state/collections/actions'

const Wrapper = styled.div`
  padding: 8px 40px;
`

const Col300 = styled(Col)`
  width: 300px !important;
`

const ScanEventBox = styled.div`
  font-size: 0.7rem;
  text-align: center;
  border-radius: 4px;
  padding: 10px;
  text-overflow: ellipsis;
  height: 230px;
  overflow: hidden;
  overflow-wrap: anywhere;
  border: 2px solid #e0e0e0;
  :hover {
    opacity: 50%;
  }
`

const ScanEventImage = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
  display: block;
  margin: auto;
`

const SpinnerContainer = styled.div`
  width: 10%;
  margin: auto;
  text-align: center;
`

interface Props {
  scanEvents: ScanEventVerbose[]
  scanEventsFetching: boolean
  project: Project
  collections: Collection[]
  dispatch: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]
  const scanEvents = state.scanEvents.items.filter(
    (scanEvent) => scanEvent.projectId === project.id,
  ) as ScanEventVerbose[]

  return {
    scanEventsFetching: state.scanEvents.isFetching,
    scanEvents,
    project,
    collections: state.collections.items,
  }
}

const ProjectTed: React.FC<Props> = ({ scanEventsFetching, scanEvents, project, collections, dispatch }) => {
  const [rows, setRows] = useState<any[]>([])
  const [pages, setPages] = useState<any[]>([])
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [numberOfPagesArray, setNumberOfPagesArray] = useState<number[]>([])
  const [scanEventsFiltered, setScanEventsFiltered] = useState<ScanEventVerbose[]>([])
  const [activeScanEvent, setActiveScanEvent] = useState<ScanEventVerbose>()
  const [activeScanEventPristine, setActiveScanEventPristine] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false) // Modal state
  const [dateAfterFilter, setDateAfterFilter] = useState<string>(
    new Date(Date.now() + -1 * 24 * 3600 * 1000).toISOString(),
  ) // 1 day ago in milliseconds UTC
  const [dateBeforeFilter, setDateBeforeFilter] = useState<string>()
  const [scannerTypeFilters, setScannerTypeFilters] = useState<any>({
    WECHAT: true,
    WEBSCANNER: true,
  })
  const [collectionFilters, setCollectionFilters] = useState<string[]>([])
  const [tagIdFilter, setTagIdFilter] = useState<string>()
  const [dotsBinaryFilter, setDotsBinaryFilter] = useState<string>()
  const [sortOption, setSortOption] = useState('newest')

  const collectionsById = collections.reduce((indexedCollections: { [key: string]: Collection }, collection) => {
    if (!(collection.id in indexedCollections)) {
      indexedCollections[collection.id] = collection
    }
    return indexedCollections
  }, {})

  useEffect(() => {
    dispatch(fetchCollectionsIfNeeded(project?.id))
  })

  useEffect(() => {
    if (!scanEventsFetching) {
      let scanEventsToSet = scanEvents
      scanEventsToSet = scanEvents.filter((scanEvent: ScanEventVerbose) => scannerTypeFilters[scanEvent.clientAgent])
      
      if (tagIdFilter) {
        scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) => tagIdFilter === scanEvent.tagId)
      }
  
      if (dotsBinaryFilter) {
        scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) => scanEvent.detectData?.some(detectData => detectData.binary === dotsBinaryFilter ))
      }
  
      scanEventsToSet = scanEventsToSet.sort((first: any, next: any) => {
        if (sortOption === 'newest') {
          return new Date(next.createdAt).getTime() - new Date(first.createdAt).getTime()
        } else {
          return new Date(first.createdAt).getTime() - new Date(next.createdAt).getTime()
        }
      })
  
      setScanEventsFiltered(scanEventsToSet)
    }
  }, [scanEvents, collectionFilters, tagIdFilter, sortOption, scannerTypeFilters, dotsBinaryFilter, scanEventsFetching])

  useEffect(() => {
    setRows(chunk(scanEventsFiltered, 4))
  }, [scanEventsFiltered])

  useEffect(() => {
    // Calculate pages from rows (number of columns)
    setPages(chunk(rows, 6))
  }, [rows])

  useEffect(() => {
    let numberArray = []
    for (let index = 0; index < pages.length; index++) {
      numberArray.push(index + 1)
    }
    setNumberOfPagesArray(numberArray)
  }, [pages])

  useEffect(() => {
    dispatch(
      fetchScanEvents({
        projectId: project.id,
        createdAfter: dateAfterFilter,
        createdBefore: dateBeforeFilter,
        include: 'detectData,matchData,scanData',
      }),
    )
  }, [dateAfterFilter, dateBeforeFilter, dispatch, project.id])

  function openModalWithScanEvent(newActiveScanEvent: ScanEventVerbose) {
    if (newActiveScanEvent?.tagId && newActiveScanEvent.projectId) {

      const headers = {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: newActiveScanEvent.projectId
      }
      
      axios
        .get(`${process.env.REACT_APP_TMAPI_BASE_URL}/tags/${newActiveScanEvent.tagId}/png?size=200`, {
          headers,
          responseType: 'blob'
        })
        .then((response: any) => {
          const imgUrl = URL.createObjectURL(response.data)
          setActiveScanEventPristine(imgUrl)
        })
    }
    setActiveScanEvent(newActiveScanEvent)
    setIsOpen(true)
  }

  function closeModal() {
    setActiveScanEvent(undefined)
    setIsOpen(false)
  }

  return (
    <>
      <Wrapper>
        <PageHeader key={'page-header'}>Tag Error Dashboard</PageHeader>
        <br />
        <Container fluid style={{ display: 'flex' }}>
          <Container>
            <Row nowrap debug>
              <Col300>
                <h3>Filters</h3>
                <br />
                <h4>Date</h4>
                <DatePicker
                  id="datepickerAfter"
                  placeholder="Scanned After"
                  value={dateAfterFilter}
                  onChange={setDateAfterFilter}
                />
                <DatePicker
                  id="datepickerBefore"
                  placeholder="Scanned Before"
                  onChange={setDateBeforeFilter}
                />
                <h4>Order</h4>
                <Select
                  options={[
                    { label: 'Newest', value: 'newest' },
                    { label: 'Oldest', value: 'oldest' },
                  ]}
                  defaultValue={{ label: 'Newest', value: 'newest' }}
                  onChange={(inputValue: any) => {
                    setSortOption(inputValue.value)
                  }}
                />
                <h4>Tag ID</h4>
                <TextField
                  name="tagId"
                  label="Tag ID"
                  value={tagIdFilter}
                  onChange={(event: any) => {
                    setTagIdFilter(event.currentTarget.value)
                  }}
                />
                <h4>Collection</h4>
                <Select
                  isMulti={true}
                  options={_.uniqBy(scanEvents, 'collectionId').map((scan) => {
                    return { label: collectionsById[scan.collectionId as string]?.name, value: scan.collectionId }
                  })}
                  onChange={(inputValue) => {
                    inputValue
                      ? setCollectionFilters(inputValue.map((input: any) => input.value))
                      : setCollectionFilters([])
                  }}
                  placeholder="Collection"
                />
                <h4>Scanner Type</h4>
                <Checkbox
                  isChecked={scannerTypeFilters.WECHAT}
                  onChange={() => {
                    setScannerTypeFilters({ ...scannerTypeFilters, WECHAT: !scannerTypeFilters.WECHAT })
                  }}
                  label="Wechat"
                />
                <Checkbox
                  isChecked={scannerTypeFilters.WEBSCANNER}
                  onChange={() => {
                    setScannerTypeFilters({ ...scannerTypeFilters, WEBSCANNER: !scannerTypeFilters.WEBSCANNER })
                  }}
                  label="Webscanner"
                />

                <h4>Dots Binary</h4>
                <TextField
                  name="dotsBinary"
                  label="Dots Binary"
                  value={dotsBinaryFilter}
                  onChange={(event: any) => {
                    setDotsBinaryFilter(event.currentTarget.value)
                  }}
                />
              </Col300>
            </Row>
          </Container>
          <Container style={{ height: '85vh', overflow: 'auto', minWidth: '300px', flex: '1 1 auto' }}>
            {(rows?.length === 0 && !scanEventsFetching) && (
              <h2 style={{ textAlign: 'center' }}>No scan events found.</h2>
            )}
            {scanEventsFetching ? (
              <SpinnerContainer>
                <Spinner size="xlarge" />
              </SpinnerContainer>
            ) : (
              <>
                <Row justify="start" key={'row'}>
                  <>
                    {pages[selectedPage]?.map((cols: any, rowIndex: number) =>
                      cols.map((scanEvent: ScanEventVerbose, colIndex: number) => {
                        return (
                          <Col300 key={`asset-col-${rowIndex}-${colIndex}`}>
                            <ScanEventBox
                              key={`asset-box-${rowIndex}-${colIndex}`}
                              onClick={() => openModalWithScanEvent(scanEvent)}
                            >
                              <ScanEventImage
                                key={`asset-image-${rowIndex}-${colIndex}`}
                                src={scanEvent?.detectData && scanEvent.detectData[0]?.image_in_url}
                              />
                              <br />
                              {scanEvent?.matchData && (
                                <>
                                  <Lozenge
                                    appearance={
                                      scanEvent?.matchData[0]?.results.filter((matchResult: any) =>
                                        matchResult.name?.startsWith('dots'),
                                      ).length > 0
                                        ? 'success'
                                        : 'removed'
                                    }
                                    isBold
                                  >
                                    DOTS
                                  </Lozenge>
                                  <Lozenge
                                    appearance={
                                      scanEvent?.matchData[0]?.results.filter((matchResult: any) =>
                                        matchResult.name?.startsWith('links'),
                                      ).length > 0
                                        ? 'success'
                                        : 'removed'
                                    }
                                    isBold
                                  >
                                    LINKS
                                  </Lozenge>
                                  <Lozenge
                                    appearance={
                                      scanEvent?.matchData[0]?.results.filter((matchResult: any) =>
                                        matchResult.name?.startsWith('goldberg'),
                                      ).length > 0
                                        ? 'success'
                                        : 'removed'
                                    }
                                    isBold
                                  >
                                    GOLDBERG
                                  </Lozenge>
                                </>
                              )}
                            </ScanEventBox>
                            <br />
                          </Col300>
                        )
                      }),
                    )}
                  </>
                </Row>
                <Pagination
                  defaultSelectedIndex={0}
                  selectedIndex={selectedPage}
                  onChange={(event, page) => {
                    setSelectedPage(page - 1)
                  }}
                  innerStyles={{ bottom: 0, justifyContent: 'center', position: 'sticky', backgroundColor: 'white' }}
                  pages={numberOfPagesArray}
                />
              </>
            )}

          </Container>
        </Container>
      </Wrapper>

      <ModalTransition>
        {isOpen && activeScanEvent && (
          <Modal onClose={closeModal} width={1200} autoFocus={false}>
            <ModalHeader>
              <ModalTitle>Scan Details</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <Row>
                <Col300 md={6}>
                  <Row align="center" justify="center" style={{ flexDirection: 'column' }}>
                    <br />
                    <ScanEventBox key={`activeScanEvent-input-box`} style={{ height: '575px' }}>
                      <h3>Scanned Image</h3>
                      <br />
                      <ScanEventImage
                        style={{ height: '500px', width: '500px' }}
                        key={`activeScanEvent-input-box-image`}
                        src={activeScanEvent?.detectData && activeScanEvent.detectData[0]?.image_in_url}
                        onClick={() => window.open(activeScanEvent?.detectData && activeScanEvent.detectData[0]?.image_in_url, '_blank')}
                      />
                      <br />
                      <Lozenge
                        appearance={
                          activeScanEvent?.matchData[0]?.results.filter((matchResult: any) =>
                            matchResult.name?.startsWith('dots'),
                          ).length > 0
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        DOTS
                      </Lozenge>{' '}
                      <Lozenge
                        appearance={
                          activeScanEvent?.matchData[0]?.results.filter((matchResult: any) =>
                            matchResult.name?.startsWith('links'),
                          ).length > 0
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        LINKS
                      </Lozenge>{' '}
                      <Lozenge
                        appearance={
                          activeScanEvent?.matchData[0]?.results.filter((matchResult: any) =>
                            matchResult.name?.startsWith('goldberg'),
                          ).length > 0
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        GOLDBERG
                      </Lozenge>
                    </ScanEventBox>
                    <br />
                    <Row>
                      <Col sm={6}>
                        <ScanEventBox
                          key={`activeScanEvent-detected-box`}
                          style={{ height: 'auto', width: '250px' }}
                        >
                          <h3>Detected Fingerprint</h3>
                          {activeScanEvent?.detectData?.map(detectData => 
                           detectData.image_out_url ? (
                            <ScanEventImage
                              key={`detected-fingerprint-${detectData.id}`}
                              src={detectData.image_out_url}
                              onClick={() => window.open(detectData.image_out_url, '_blank')}
                            />
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          ))}
                        </ScanEventBox>
                      </Col>

                      <Col sm={6}>
                        <ScanEventBox
                          key={`activeScanEvent-pristine-box`}
                          style={{ height: '250px', width: '250px' }}
                        >
                          <h3>Matched Fingerprint</h3>
                          <br />
                          <ScanEventImage
                            key={`matched-fingerprint-${activeScanEvent.id}`}
                            src={activeScanEventPristine}
                          />
                        </ScanEventBox>
                      </Col>
                    </Row>
                  </Row>
                </Col300>
                <Col md={6}>
                  <h3>All Details</h3>
                  <SectionMessage>
                    <p>
                      <b> Date: </b>
                      {new Date(activeScanEvent.createdAt).toLocaleString('en-AU')}
                      <br />
                      <b> Location: </b>
                      {activeScanEvent.city}, {activeScanEvent.country} ({activeScanEvent.geolocationEnabled ? 'GPS' : 'IP'})
                      <br />
                      <b> Scanner Type: </b>
                      {activeScanEvent.clientAgent}
                    </p>
                    
                    <p>
                      <b> Project: </b>
                      <Link to={`/projects/${project.id}/collections`} target="_blank">
                        {project.name}
                      </Link>
                      <br />
                      <b> Collection: </b>
                      <Link
                        to={`/projects/${project.id}/collections/${activeScanEvent?.collectionId}/fingerprints`}
                        target="_blank"
                      >
                        {collectionsById[activeScanEvent.collectionId as string]?.name}
                      </Link>
                      <br />
                      <b> Company: </b>
                      {activeScanEvent?.project ? activeScanEvent.project.company.name : <Lozenge>N/A</Lozenge>}
                    </p>
                    <p>
                      <b> Tag Metadata: </b>
                      {activeScanEvent?.tagMetadata ? (
                        <ReactJson
                          iconStyle="triangle"
                          displayObjectSize={false}
                          collapsed={0}
                          displayDataTypes={false}
                          src={activeScanEvent?.tagMetadata || {}}
                          quotesOnKeys={false}
                          name={null}
                          enableClipboard={(copy: any) => {navigator.clipboard.writeText(JSON.stringify(activeScanEvent.tagMetadata))}}
                          style={{ fontSize: "12px" }}
                          indentWidth={1}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                      <br />
                      <b> Hook Data: </b>
                      {activeScanEvent?.hookData ? (
                        <ReactJson
                          iconStyle="triangle"
                          displayObjectSize={false}
                          collapsed={1}
                          displayDataTypes={false}
                          src={activeScanEvent?.hookData || {}}
                          quotesOnKeys={false}
                          name={null}
                          enableClipboard={(copy: any) => {navigator.clipboard.writeText(JSON.stringify(activeScanEvent.hookData))}}
                          style={{ fontSize: "12px" }}
                          indentWidth={1}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                      <br />
                      <b> Third-party Scan Data: </b>
                      {activeScanEvent?.thirdPartyScanResponse ? (
                        <ReactJson
                          iconStyle="triangle"
                          displayObjectSize={false}
                          collapsed={1}
                          displayDataTypes={false}
                          src={activeScanEvent?.thirdPartyScanResponse || {}}
                          quotesOnKeys={false}
                          name={null}
                          enableClipboard={(copy: any) => {navigator.clipboard.writeText(JSON.stringify(activeScanEvent.thirdPartyScanResponse))}}
                          style={{ fontSize: "12px" }}
                          indentWidth={1}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                    </p>
                  </SectionMessage>
                  <br />

                  <ReactJson
                    iconStyle="triangle"
                    displayObjectSize={false}
                    collapsed={1}
                    displayDataTypes={false}
                    src={activeScanEvent || {}}
                    quotesOnKeys={false}
                    name={null}
                    enableClipboard={(copy: any) => {navigator.clipboard.writeText(JSON.stringify(copy.src))}}
                  />
                  <h3>Match Details</h3>
                  <ReactJson
                    iconStyle="triangle"
                    displayObjectSize={false}
                    collapsed={2}
                    displayDataTypes={false}
                    src={activeScanEvent?.matchData[0]}
                    quotesOnKeys={false}
                    name={null}
                    enableClipboard={(copy: any) => {navigator.clipboard.writeText(JSON.stringify(copy.src))}}
                  />
                  <h3>Detect Details</h3>
                  <ReactJson
                    iconStyle="triangle"
                    displayObjectSize={false}
                    collapsed={1}
                    displayDataTypes={false}
                    src={{ ...activeScanEvent?.detectData}}
                    quotesOnKeys={false}
                    name={null}
                    enableClipboard={(copy: any) => {navigator.clipboard.writeText(JSON.stringify(copy.src))}}
                  />
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <Button appearance="default" onClick={closeModal} autoFocus={false}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default connect(mapStateToProps)(ProjectTed)
