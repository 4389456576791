import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DynamicTable from '@atlaskit/dynamic-table'
import HipchatChevronDoubleUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-up'

import { ScanEvent } from '../../../../types'

const Box = styled.div`
  min-width: 0;
  text-align: center;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
  width: 100%;
`

interface Props {
  scanEvents: ScanEvent[]
}

const MostScannedTable: React.FC<Props> = ({ scanEvents }) => {
  const dataByTag = [
    ...scanEvents
      .reduce((accumulator: any, currentValue: any) => {
        if (!accumulator.has(currentValue.tagId)) accumulator.set(currentValue.tagId, { ...currentValue, scanCount: 0 })
        accumulator.get(currentValue.tagId).scanCount++
        return accumulator
      }, new Map())
      .values(),
  ]

  const tableHead = {
    cells: [
      {
        content: 'Fingerprint ID',
        key: 'tagId',
        isSortable: true,
        width: 55,
      },
      {
        content: 'Last Scanned',
        key: 'createdAt',
        isSortable: true,
        width: 25,
      },
      {
        content: 'Scan Count',
        key: 'scanCount',
        isSortable: true,
        width: 20,
      },
    ],
  }

  const tableCells = dataByTag.map((tag, index) => {
    return {
      cells: [
        {
          content: tag.tagId,
          key: `${tag.tagId}-${index}`,
          height: 36,
        },
        {
          content: moment(tag.createdAt).calendar(null, {
            sameElse: 'MMM D, YYYY, h:mm a',
          }),
          key: `${tag.createdAt}-${index}`,
        },
        {
          content: tag.scanCount,
          key: `${tag.scanCount}-${index}`,
        },
      ],
    }
  })

  return (
    <Box>
      <h3 style={{ paddingTop: '10px' }}>
        Most Scanned Fingerprints <HipchatChevronDoubleUpIcon label="chevronUp" />
      </h3>
      <div style={{ padding: '10px' }}>
        <DynamicTable
          head={tableHead}
          rows={tableCells}
          rowsPerPage={6}
          defaultPage={1}
          loadingSpinnerSize="large"
          defaultSortKey="scanCount"
          defaultSortOrder="DESC"
          emptyView={<h4 style={{ fontWeight: 100 }}>Scans not found</h4>}
        />
      </div>
    </Box>
  )
}

export default MostScannedTable
