import React, { useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Route } from 'react-router'
import styled from 'styled-components'
import { RiBearSmileLine } from 'react-icons/ri'

import {
  SideNavigation,
  NavigationHeader,
  Header,
  CustomItem,
  CustomItemComponentProps,
  NestableNavigationContent,
  Section,
  NestingItem,
} from '@atlaskit/side-navigation'
import { Main, LeftSidebar } from '@atlaskit/page-layout'
import GraphBarIcon from '@atlaskit/icon/glyph/graph-bar'
import CanvasIcon from '@atlaskit/icon/glyph/canvas'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import UnlockFilledIcon from '@atlaskit/icon/glyph/unlock-filled'
import PeopleIcon from '@atlaskit/icon/glyph/people'
import QueuesIcon from '@atlaskit/icon/glyph/queues'
import PageHeader from '@atlaskit/page-header'
import DocumentsIcon from '@atlaskit/icon/glyph/documents'
import MediaServicesScaleLargeIcon from '@atlaskit/icon/glyph/media-services/scale-large'
import DownloadIcon from '@atlaskit/icon/glyph/download'

import { PermissionProvider } from '../../providers/permission'
import { Roles } from '../../components/accounts'
import { Project as IProject, State } from '../../types'
import ProjectAnalytics from './analytics'
import ProjectDownload from './download'
import ProjectViews from './views'
import ProjectEditView from './edit-view'
import ProjectCollections from './collections'
import ProjectUsers from './users'
import ProjectApiKeys from './api-keys'
import ProjectAssets from './assets'
import ProjectTed from './ted'
import ErrorMessage from '../../components/react/error-message'
import { fetchProjectsIfNeeded } from '../../state/projects/actions'
import { assetsInvalidate, viewsInvalidate } from '../../state/content/actions'

interface Props {
  project: IProject
  isFetching: boolean
  userRole?: string
  dispatch: any
}

// Init all styles here
const Wrapper = styled.div`
  padding: 8px 40px;
  min-width: 720px;
`

const Breadcrumbs = styled.span`
  margin: 24px 0 16px 0;
  line-height: 24px;
  a {
    color: #6b778c !important;
  }
`

const BreadcrumbSeparator = styled.span`
  width: 8px;
  padding: 0 10px;
  line-height: 24px;
  color: #6b778c !important;
`

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: IProject) => project.id === ownProps.match.params.projectId,
  )[0]
  const isFetching = state.projects.isFetching
  const userRole = state.account.login.user.role?.name
  return {
    project,
    isFetching,
    userRole,
  }
}

const Project: React.FC<Props> = ({ project, isFetching, userRole, dispatch }) => {
  const location = useLocation()
  
  useEffect(() => {
    dispatch(viewsInvalidate())
    dispatch(assetsInvalidate())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
  })

  const breadcrumbs = (
    <Breadcrumbs>
      <Link to="/projects">Projects</Link>
      <BreadcrumbSeparator>/</BreadcrumbSeparator>
      <Link to={`/projects/${project?.id}/collections`}>{project?.name}</Link>
      {location.pathname.includes('/result-views') && (
        <>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <Link to={`/projects/${project?.id}/result-views`}>{'Result views'}</Link>
        </>
      )}
      {location.pathname.includes('/assets') && (
        <>
          <BreadcrumbSeparator>/</BreadcrumbSeparator>
          <Link to={`/projects/${project?.id}/assets`}>{'Assets'}</Link>
        </>
      )}
    </Breadcrumbs>
  )

  type CustomProps = CustomItemComponentProps & { href: string; project?: any }
  const CustomLink = forwardRef<HTMLAnchorElement, CustomProps>((props: CustomProps, ref) => {
    const { children, ...rest } = props

    return (
      <Link to={props.href} ref={ref} {...rest}>
        {children}
      </Link>
    )
  })

  const projectProp = { project }

  return (
    <>
      {!isFetching && !project ? (
        <Main>
          <Wrapper>
            <ErrorMessage title={'Error'} description={'Project not found.'} goBackLink={'/projects'} />
          </Wrapper>
        </Main>
      ) : (
        <PermissionProvider project={project}>
          <LeftSidebar isFixed={true} width={240}>
            <SideNavigation label="Project navigation" testId="side-navigation">
              <NavigationHeader>
                <Header iconBefore={<DocumentsIcon label="" />} description={`${project?.company.name}'s Project`}>
                  {project?.name}
                </Header>
              </NavigationHeader>

              <NestableNavigationContent initialStack={[]}>
                <Section>
                  <CustomItem
                    href={`/projects/${project?.id}/collections`}
                    component={CustomLink}
                    iconBefore={<QueuesIcon label="" />}
                    isSelected={location.pathname.endsWith('/collections')}
                  >
                    Collections
                  </CustomItem>

                  {userRole === Roles.SystemRoles.SUPER_USER ? (
                    <NestingItem
                      id="nested-project-nav-analytics"
                      title="Analytics"
                      iconBefore={<GraphBarIcon label="" />}
                      isSelected={location.pathname.includes('/analytics') || location.pathname.endsWith('/ted')}
                    >
                      <Section>
                        <CustomItem
                          href={`/projects/${project?.id}/analytics`}
                          component={CustomLink}
                          iconBefore={<GraphBarIcon label="" />}
                          isSelected={location.pathname.includes('/analytics')}
                        >
                          Scan Analytics
                        </CustomItem>
                        <CustomItem
                          href={`/projects/${project?.id}/ted`}
                          component={CustomLink}
                          iconBefore={
                            <h3>
                              <RiBearSmileLine />
                            </h3>
                          }
                          isSelected={location.pathname.endsWith('/ted')}
                        >
                          TED
                        </CustomItem>
                      </Section>
                    </NestingItem>
                  ) : (
                    <CustomItem
                      href={`/projects/${project?.id}/analytics`}
                      component={CustomLink}
                      iconBefore={<GraphBarIcon label="" />}
                      isSelected={location.pathname.endsWith('/analytics')}
                    >
                      Analytics
                    </CustomItem>
                  )}

                  <NestingItem
                    id="nested-project-nav-scan-results"
                    title="Create Result Views"
                    iconBefore={<CanvasIcon label="" />}
                    isSelected={location.pathname.includes('/result-views') || location.pathname.endsWith('/assets')}
                  >
                    <Section>
                      <CustomItem
                        href={`/projects/${project?.id}/result-views`}
                        component={CustomLink}
                        iconBefore={<CanvasIcon label="" />}
                        isSelected={location.pathname.includes('/result-views')}
                      >
                        Create Result Views
                      </CustomItem>
                      <CustomItem
                        href={`/projects/${project?.id}/assets`}
                        component={CustomLink}
                        iconBefore={<MediaServicesScaleLargeIcon label="" />}
                        isSelected={location.pathname.endsWith('/assets')}
                      >
                        Assets
                      </CustomItem>
                    </Section>
                  </NestingItem>


                  <CustomItem
                    href={`/projects/${project?.id}/download`}
                    component={CustomLink}
                    iconBefore={<DownloadIcon label="" />}
                    isSelected={location.pathname.endsWith('/download')}
                  >
                    Bulk Downloads
                  </CustomItem>


                  <NestingItem
                    id="nested-project-nav"
                    title="Project settings"
                    iconBefore={<SettingsIcon label="settings" />}
                    isSelected={location.pathname.endsWith('/api-keys') || location.pathname.endsWith('/users')}
                  >
                    <Section>
                      <CustomItem
                        href={`/projects/${project?.id}/users`}
                        component={CustomLink}
                        iconBefore={<PeopleIcon label="" />}
                        isSelected={location.pathname.endsWith('/users')}
                      >
                        Users
                      </CustomItem>
                      <CustomItem
                        href={`/projects/${project?.id}/api-keys`}
                        component={CustomLink}
                        iconBefore={<UnlockFilledIcon label="" />}
                        isSelected={location.pathname.endsWith('/api-keys')}
                      >
                        API keys
                      </CustomItem>
                    </Section>
                  </NestingItem>
                </Section>
              </NestableNavigationContent>
            </SideNavigation>
          </LeftSidebar>

          <Main>
            <Wrapper>
              <PageHeader breadcrumbs={breadcrumbs} />
              <Route exact path="/projects/:projectId/analytics" component={ProjectAnalytics} />
              <Route exact path="/projects/:projectId/collections" component={ProjectCollections} />
              <Route exact path="/projects/:projectId/users" component={ProjectUsers} {...projectProp} />
              <Route exact path="/projects/:projectId/download" component={ProjectDownload} />
              <Route exact path="/projects/:projectId/api-keys" component={ProjectApiKeys} {...projectProp} />
              <Route exact path="/projects/:projectId/result-views" component={ProjectViews} />
              <Route exact path="/projects/:projectId/result-views/:viewId" component={ProjectEditView} />
              <Route exact path="/projects/:projectId/assets" component={ProjectAssets} />
              <Route exact path="/projects/:projectId/ted" component={ProjectTed} />
            </Wrapper>
          </Main>
          </PermissionProvider>
      )}
    </>
  )
}

export default connect(mapStateToProps)(Project)
