import { TAGS_REQUEST, TAGS_RECEIVE, TAGS_INVALIDATE, TAGS_ERROR, TAGS_FETCH_PROGRESS } from './actions'

const initialTagsState = {
  isFetching: false,
  fetchProgress: 0,
  collectionId: null,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

export const tags = (state = initialTagsState, action: any) => {
  switch (action.type) {
    case TAGS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      })
    case TAGS_FETCH_PROGRESS:
      return Object.assign({}, state, {
        fetchProgress: action.percentageComplete,
      })
    case TAGS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        collectionId: action.collectionId,
        items: action.tags,
        lastFetched: Date.now(),
        didInvalidate: false,
      })
    case TAGS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      })
    case TAGS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      })

    default:
      return state
  }
}
