import axios from 'axios'
import moment from 'moment'
import { Company, State } from '../../types'

// Companies
export const COMPANIES_REQUEST = 'COMPANIES_REQUEST'
export const COMPANIES_RECEIVE = 'COMPANIES_RECEIVE'
export const COMPANIES_INVALIDATE = 'COMPANIES_INVALIDATE'
export const COMPANIES_ERROR = 'COMPANIES_ERROR'

const companiesRequest = () => {
  return {
    type: COMPANIES_REQUEST,
  }
}

const companiesReceive = (companies: any) => {
  return {
    type: COMPANIES_RECEIVE,
    companies,
  }
}

export const companiesInvalidate = () => {
  return {
    type: COMPANIES_INVALIDATE,
  }
}

const companiesError = () => {
  return {
    type: COMPANIES_ERROR
  }
}

const fetchCompanies = () => {
  return (dispatch: any) => {
    dispatch(companiesRequest())
    axios.get(`${process.env.REACT_APP_ACCMAN_URL}/companies`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    .then((result) => {
      return dispatch(companiesReceive(result.data.data))
    })
    .catch((error) => {
      console.log(error)
      return dispatch(companiesError())
    })
  }
}

const shouldFetchCompanies = (state: State): boolean => {
  const lastFetched = state.account.company?.lastFetched
  const isFetching = state.account.company?.isFetching

  if (!isFetching) {
    if (!lastFetched || moment(lastFetched).isBefore(new Date(new Date().getTime() - 60000))) {
      return true
    } else {
      return state.account.company.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchCompaniesIfNeeded = () => {
  return (dispatch: any, getState: any) => {
    if (shouldFetchCompanies(getState())) {
      return dispatch(fetchCompanies())
    }
  }
}

interface CreateCompanyParams {
  name: string
  domain: string
  description: string
  maxFingerprints: number
}

export const createCompany = (data: CreateCompanyParams) => {
  return (dispatch: any) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/companies`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        name: data.name,
        domain: data.domain,
        description: data.description,
        max_fingerprints: data.maxFingerprints,
      },
    })
      .then(() => {
        return dispatch(companiesInvalidate())
      })
      .catch((error) => {
        console.log(error)
        return dispatch(companiesError())
      })
  }
}

interface UpdateCompanyParams {
  companyId: number
  maxFingerprints: number
}

export const updateCompany = (data: UpdateCompanyParams) => {
  return (dispatch: any) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_ACCMAN_URL}/companies/${data.companyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        max_fingerprints: data.maxFingerprints,
      },
    })
      .then(() => {
        return dispatch(companiesInvalidate())
      })
      .catch((error) => {
        console.log(error)
        return dispatch(companiesError())
      })
  }
}

export const fetchFingerprintLimitForCompany = (company: Company) => {
  return async (dispatch: any) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/companies/${company.id}/tags`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      }
    })
      .then((result: any) => {
        return dispatch(companiesInvalidate())
      })
      .catch((error: any) => {
        console.log(error)
        return dispatch(companiesError())
      })
  }
}

// Invitations

export const INVITATIONS_REQUEST = 'INVITATIONS_REQUEST'
export const INVITATIONS_RECEIVE = 'INVITATIONS_RECEIVE'
export const INVITATIONS_INVALIDATE = 'INVITATIONS_INVALIDATE'
export const INVITATIONS_ERROR = 'INVITATIONS_ERROR'

const invitationsRequest = () => {
  return {
    type: INVITATIONS_REQUEST,
  }
}

const invitationsReceive = (invitations: any) => {
  return {
    type: INVITATIONS_RECEIVE,
    invitations,
  }
}

export const invitationsInvalidate = () => {
  return {
    type: INVITATIONS_INVALIDATE,
  }
}

export const invitationsError = () => {
  return {
    type: INVITATIONS_ERROR,
  }
}

export const fetchInvitations = () => {
  return async (dispatch: any) => {
    dispatch(invitationsRequest())
    const url = `${process.env.REACT_APP_ACCMAN_URL}/invitations`

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
      .then((result) => {
        return dispatch(invitationsReceive(result.data))
      })
      .catch((error) => {
        console.log(error)
        return dispatch(invitationsError())
      })
  }
}

const shouldFetchInvitations = (state: any): boolean => {
  const lastFetched = state.account.invitations?.lastFetched
  const isFetching = state.account.invitations?.isFetching

  if (!isFetching) {
    if (!lastFetched) {
      return true
    } else {
      return state.account.invitations.didInvalidate
    }
  } else {
    return false
  }
}

export const fetchInvitationsIfNeeded = () => {
  return (dispatch: any, getState: any) => {
    if (shouldFetchInvitations(getState())) {
      return dispatch(fetchInvitations())
    }
  }
}

export interface CreateInvitationParams {
  email: string
  type: string
  companyId: number
}

export const createInvitation = (data: CreateInvitationParams) => {
  return (dispatch: any, getState: any) => {
    const state = getState()
    const user = {
      id: state.account.login.user.id,
    }
    axios({
      // Create new invitation
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        email: data.email,
        type: data.type,
        company_id: data.companyId,
        user_id_from: user.id,
      },
    })
      .then((result) => {
        dispatch(invitationsInvalidate())
        dispatch(fetchInvitations())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

interface DeleteInvitationParams {
  invitation: any
  projectId: string
}

export const deleteInvitation = ({ invitation, projectId }: DeleteInvitationParams) => {
  return (dispatch: any) => {
    axios({
      // Delete invitation
      method: 'delete',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/${invitation.token}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: {
        project_id: projectId,
      },
    })
      .then((result) => {
        dispatch(invitationsInvalidate())
        dispatch(fetchInvitations())
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

// Register

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR'

const registerSuccess = () => {
  return {
    type: REGISTER_SUCCESS,
  }
}

const registerError = () => {
  return {
    type: REGISTER_ERROR,
  }
}

const verifyEmailSuccess = () => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
  }
}

const verifyEmailError = () => {
  return {
    type: VERIFY_EMAIL_ERROR,
  }
}

export const register = (values: any) => {
  return (dispatch: any) => {
    axios({
      // Our AccMan service create account endpoint
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/users`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { ...values },
    })
      // from: https://jasonwatmore.com/post/2017/09/16/react-redux-user-registration-and-login-tutorial-example
      .then((result) => {
        return dispatch(registerSuccess())
      })
      .catch((error) => {
        return dispatch(registerError())
      })
  }
}

export const handleCompanyInviteRegister = (values: any, token?: string) => {
  return (dispatch: any) => {
    axios({
      // Our AccMan service create account endpoint
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/${token}/activate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { ...values },
    })
      // from: https://jasonwatmore.com/post/2017/09/16/react-redux-user-registration-and-login-tutorial-example
      // .then(handleResponse)
      .then((result) => {
        return dispatch(registerSuccess())
      })
      .catch((error) => {
        return dispatch(registerError())
      })
  }
}

export const verifyEmail = (token: string) => {
  return (dispatch: any) => {
    axios({
      // Activate the invitation
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/activate/${token}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        dispatch(getProfile()) // Update our state with new user data
        return dispatch(verifyEmailSuccess())
      })
      .catch((error) => {
        console.log(error)
        return dispatch(verifyEmailError())
      })
  }
}

// Login

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'

const loginSuccess = (data: any) => {
  return {
    type: LOGIN_SUCCESS,
    data,
  }
}

const getProfileSuccess = (data: any) => {
  return {
    type: GET_PROFILE_SUCCESS,
    data,
  }
}

export const refreshSuccess = (data: any) => {
  return {
    type: REFRESH_SUCCESS,
    data,
  }
}

const loginError = () => {
  return {
    type: LOGIN_ERROR,
  }
}

export const logoutRequest = () => {
  return {
    type: LOGOUT,
  }
}

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  }
}

export const login = (values: any) => {
  return (dispatch: any) => {
    dispatch(loginRequest())
    axios({
      // Our AccMan service login endpoint
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/auth/login`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        password: values.password,
        email: values.email,
      },
    })
      .then((result) => {
        let tokens = {
          access: {
            token: result.data.access_token,
            expiry: result.data.access_token_expiry,
          },
          refresh: {
            token: result.data.refresh_token,
            expiry: result.data.refresh_token_expiry,
          },
        }
        // Get profile data
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_ACCMAN_URL}/auth/profile`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens.access.token}`,
          },
        }).then((result) => {
          const data = {
            user: result.data,
            tokens,
          }
          return dispatch(loginSuccess(data))
        })
      })
      .catch((error) => {
        return dispatch(loginError())
      })
  }
}

export const getProfile = () => {
  return (dispatch: any) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_ACCMAN_URL}/auth/profile`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }).then((response) => {
      const user = response.data
      return dispatch(getProfileSuccess(user))
    })
  }
}

export const logout = () => {
  return (dispatch: any) => {
    return dispatch(logoutRequest())
  }
}
