import './wdyr'
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import rootReducer from './state/rootReducer'
import { logoutRequest, refreshSuccess } from './state/account/actions'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account', 'projects'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// Interceptors
//
// The following Axios interceptor is initiated on all 401 responses from Accman
axios.interceptors.response.use(
  (response: any) => {
    return response
  },
  (err: { config: { __isRetryRequest: any; url: string }; response: { status: number; data: any } }) => {
    return new Promise((resolve, reject) => {
      // Get a refresh token if it exists
      const refresh_token = localStorage.getItem('refresh_token')

      if (
        // There are instances of 500 status but jwt malformed/expired because of the proxy
        (err.response?.status === 401 || err.response?.data?.message?.includes('jwt')) &&
        !err.config.__isRetryRequest &&
        err.config.url.includes(`${process.env.REACT_APP_ACCMAN_URL}`) &&
        refresh_token
      ) {
        // Retrieve a new access token using the refresh token
        // And retries the original request
        const state = store.getState()
        const email = state.account.login.user.email
        const originalReq: any = err.config

        originalReq.__isRetryRequest = true

        let res = fetch(`${process.env.REACT_APP_ACCMAN_URL}/auth/refresh`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            refresh_token: refresh_token || '',
          },
          body: JSON.stringify({
            email,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            let tokens = {
              access: {
                token: res.access_token,
                expiry: res.access_token_expiry,
              },
              refresh: {
                token: res.refresh_token,
                expiry: res.refresh_token_expiry,
              },
            }
            originalReq.headers['Authorization'] = `Bearer ${tokens.access.token}`
            localStorage.setItem('access_token', tokens.access.token)
            localStorage.setItem('refresh_token', tokens.refresh.token)
            store.dispatch(refreshSuccess({ tokens }))
            return axios(originalReq)
          })
          .catch((error) => {
            store.dispatch(logoutRequest())
          })
        

        resolve(res)
      }

      reject(err.response)
    })
  },
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
