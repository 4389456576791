import React, { useEffect, useState } from 'react'
import _, { chunk } from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import { SiGooglemaps } from 'react-icons/si'
import ReactJson from 'react-json-view'
import ReactMapboxGl, { Popup, Cluster, Marker, ZoomControl } from 'react-mapbox-gl'
import { Link } from 'react-router-dom'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { lineString, bbox, point, pointsWithinPolygon, bboxPolygon } from '@turf/turf'
import { Col } from 'react-grid-system'

import Button from '@atlaskit/button/dist/cjs/components/Button'
import InlineMessage from '@atlaskit/inline-message'
import Lozenge from '@atlaskit/lozenge'
import Pagination from '@atlaskit/pagination'
import DiscoverIcon from '@atlaskit/icon/glyph/discover'
import DiscoverFilledIcon from '@atlaskit/icon/glyph/discover-filled'
import EmojiFlagsIcon from '@atlaskit/icon/glyph/emoji/flags'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut'

import { Project, ScanEvent, ScanEventVerbose } from '../../../../types'

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const MapBox = styled.div`
  min-width: 0;
  height: 700px;
  width: 100%;
`

const PopupList = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    background-color: #ebecf0;
  }
`

const StyledPopup = styled(Popup)`
  width: 400px;
  z-index: 1 !important;
`

const ScanEventImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: block;
  margin: auto;
`
const ScanList = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const clusterMarkerStyle = {
  width: 60,
  height: 60,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: '20px',
  backgroundColor: 'rgba(136, 132, 216, 0.8)',
  zIndex: 1,
}

const markerStyle = {
  width: 50,
  height: 50,
  borderRadius: '50%',
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: '20px',
  zIndex: 1,
  backgroundColor: 'rgba(136, 132, 216, 0.8)',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

interface Props {
  mapCenter: [number, number]
  setMapCenter: React.Dispatch<React.SetStateAction<[number, number]>>
  scans: ScanEventGeocoded[]
  popup: any
  setPopup: any
  isMaxZoom: boolean
  setIsMaxZoom: any
  collectionsById: any
  project: Project
}

type ScanEventGeocoded = (ScanEvent | ScanEventVerbose) & {
  type?: string
  properties?: any
  geometry?: any
}

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN || '',
  attributionControl: false,
  pitchWithRotate: false,
  keyboard: false,
  dragRotate: false,
})

const ScanListItemSelected = ({setPopup, key, scan}: {setPopup: any, key: number, scan: ScanEventGeocoded}) => {
  const Item = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #ebecf0;
  `
  const onItemClick = () => {setPopup(undefined)}
  const scanDate = moment(scan.createdAt).calendar(null, { sameElse: 'MMM D, YYYY' })
  
  return (
    <Item onClick={onItemClick} key={key}>
    <div>
      <DiscoverFilledIcon label="selected" />
      {scan?.ruleStatus && <EmojiFlagsIcon secondaryColor="white" label="flag" />}
    </div>

    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      {scanDate}
      <p style={{ fontWeight: 'lighter', fontSize: '12px', margin: 'unset' }}>
        {scan.city}, {scan.country}
      </p>
    </div>
  </Item>
  )
}

const ScanListItem = ({setPopup, setMapCenter, scan, key}: {setPopup: any, setMapCenter: any, scan: ScanEventGeocoded, key: number}) => {
  const Item = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    :hover {
      background-color: #ebecf0;
    }
  `
  const onItemClick = () => {
    setPopup({
      coordinates: scan.geometry.coordinates,
      scan,
    })
    setMapCenter(scan.geometry.coordinates)

  }
  const scanDate = moment(scan.createdAt).calendar(null, { sameElse: 'MMM D, YYYY' })
  
  return (
    <Item onClick={onItemClick} key={key}>
    <div>
      <DiscoverIcon label="notSelected" />{' '}
      {scan?.ruleStatus && <EmojiFlagsIcon secondaryColor="white" label="flag" />}
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      {scanDate}
      <p style={{ fontWeight: 'lighter', fontSize: '12px', margin: 'unset' }}>
        {scan.city}, {scan.country}
      </p>
    </div>
  </Item>
  )
}

const MapPopup = ({
  popup,
  setPopup,
  project,
  scans,
  collectionsById
}: {popup: any, setPopup: any, project: Project, scans: ScanEventGeocoded[], collectionsById: any}) => {
  return (
    <StyledPopup offset={[0, -50]} coordinates={popup.coordinates}>
    <div
      style={{
        margin: '-10px -10px -15px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          padding: '15px',
        }}
      >
        {popup.leaves?.length ? (
          <h1>{popup.leaves?.length} Scans</h1>
        ) : (
          <h1>{moment(popup.scan.createdAt).format('MMM D YY, h:mm a')}</h1>
        )}
        {popup.scan?.ruleStatus && <EmojiFlagsIcon secondaryColor="white" label="flag" />}

        <Button
          onClick={() => {
            setPopup(undefined)
          }}
          appearance="subtle"
          iconBefore={<CrossIcon secondaryColor="white" label="closePopup" />}
        />
      </div>
      <div
        style={{
          maxHeight: '350px',
          overflow: 'auto',
        }}
      >
        {popup.leaves?.length ? (
          popup.leaves.map((leaf: React.ReactElement<any>, index: number) => (
            <div
              key={index}
              onClick={() =>
                setPopup({
                  coordinates: leaf.props['data-feature'].geometry.coordinates,
                  scan: leaf.props['data-feature'],
                })
              }
            >
              <PopupList>
                <DiscoverIcon label="notSelected" />
                {moment(leaf.props['data-feature'].createdAt).calendar(null, {
                  sameElse: 'MMM D, YYYY, h:mm a',
                })}
              </PopupList>
            </div>
          ))
        ) : (
          <div style={{ padding: '0 15px 15px 15px' }} key={popup.scan.id}>
            {!popup.scan?.geolocationEnabled && (
              <InlineMessage type="warning" secondaryText="IP address location">
                IP address location data is inaccurate and reflects the public network location
                of the scan, for example ISP (Internet Service Provider), cellphone tower or VPN
                (virtual private network) locations.
              </InlineMessage>
            )}
            {popup.scan?.ruleStatus && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <p style={{ fontWeight: 'bold' }}>Status Flagged</p>
                <p style={{ textAlign: 'right' }}>{popup.scan.ruleStatus}</p>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Tag ID</p>
              <p style={{ textAlign: 'right' }}>{popup.scan.tagId}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Latitude/Longitude</p>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${popup.scan.clientLocationLat},${popup.scan.clientLocationLong}`}
                target={'blank'}
                style={{ margin: 'unset', textAlign: 'right' }}
              >
                {popup.scan.clientLocationLat}, {popup.scan.clientLocationLong}{' '}
                <SiGooglemaps />
              </a>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Scanner Type</p>
              <p style={{ margin: 'unset', textAlign: 'right' }}>
                {popup.scan.clientAgent}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
                <p style={{ fontWeight: 'bold' }}>Collection</p>
                <Link
                  to={`/projects/${project.id}/collections/${popup.scan.collectionId}/fingerprints`}
                  target="_blank"
                >
                  {collectionsById[popup.scan.collectionId]?.name}{' '}
                  <ShortcutIcon label="Link to collection" size="small"/>
              </Link>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Total Tag Scan Count</p>
              <p style={{ margin: 'unset', textAlign: 'right' }}>
                {
                  scans.filter((scan) => scan.tagId === popup.scan.tagId)
                    .length
                }
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Scan Number</p>
              <p style={{ margin: 'unset', textAlign: 'right' }}>
                {scans
                  .filter((scan) => scan.tagId === popup.scan.tagId).reverse()
                  .map((scan, index) => {
                    if (scan.id === popup.scan.id) {
                      return index + 1
                    } else return null
                  })}
              </p>
              </div>
              <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Tag Metadata</p>
                {!_.isEmpty(popup.scan.tagMetadata) ? <ReactJson
                  iconStyle="triangle"
                  displayObjectSize={false}
                  collapsed={0}
                  collapseStringsAfterLength={25}
                  displayDataTypes={false}
                  src={popup.scan.tagMetadata}
                  quotesOnKeys={false}
                  name={null}
                  style={{margin: 'unset'}}
                /> : <Lozenge>N/A</Lozenge>}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Hook Data</p>
                {!_.isEmpty(popup.scan.hookData) ? <ReactJson
                  iconStyle="triangle"
                  displayObjectSize={false}
                  collapsed={0}
                  collapseStringsAfterLength={25}
                  displayDataTypes={false}
                  src={popup.scan.hookData}
                  quotesOnKeys={false}
                  name={null}
                  style={{margin: 'unset'}}
                /> : <Lozenge>N/A</Lozenge>}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Auth Data</p>
                {!_.isEmpty(popup.scan.authData) ? <ReactJson
                  iconStyle="triangle"
                  displayObjectSize={false}
                  collapsed={0}
                  collapseStringsAfterLength={25}
                  displayDataTypes={false}
                  src={popup.scan.authData}
                  quotesOnKeys={false}
                  name={null}
                  style={{margin: 'unset'}}
                /> : <Lozenge>N/A</Lozenge>}
            </div>
            <div>
                {popup.scan?.detectData && popup.scan.detectData[0]?.image_in_url && (
                  <ScanEventImage
                    key={`scanned-image-${popup.scan.id}`}
                    src={popup.scan?.detectData[0]?.image_in_url}
                  />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  </StyledPopup>
  )
}

const ScanMap: React.FC<Props> = ({ mapCenter, setMapCenter, scans, popup, setPopup, isMaxZoom, setIsMaxZoom, collectionsById, project  }) => {
  const [mapZoom, setMapZoom] = useState<[number]>([1])
  const [currentMapBounds, setCurrentMapBounds] = useState<any>()
  const [scansInMapBounds, setScansInMapBounds] = useState<ScanEventGeocoded[]>([])
  const [pages, setPages] = useState<any[]>([])
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [numberOfPagesArray, setNumberOfPagesArray] = useState<number[]>([])

  useEffect(() => {
    setPages(chunk(scansInMapBounds, 10))
    setSelectedPage(0)
  }, [scansInMapBounds])

  useEffect(() => {
    pages.map((scans: ScanEventGeocoded[], key: number) => {
      if (scans.indexOf(popup?.scan) > -1) {
        setSelectedPage(key)
      }
      return null
    })
  }, [pages, popup, setPopup])

  useEffect(() => {
    let numberArray = []
    for (let index = 0; index < pages.length; index++) {
      numberArray.push(index + 1)
    }
    setNumberOfPagesArray(numberArray)
  }, [pages])

  useEffect(() => {
    const mappableScans = scans.filter((scan) => {
      if (scan.clientLocationLat && scan.clientLocationLong) {
        if (currentMapBounds) {
          const pointOnMap = point([scan.clientLocationLat, scan.clientLocationLong])
          const pointWithin = pointsWithinPolygon(pointOnMap, bboxPolygon(currentMapBounds))
          if (!(pointWithin.features.length > 0)) {
            return null
          }
        }
        return scan
      } else return false
    })

    setScansInMapBounds(mappableScans)
  }, [currentMapBounds, scans])

  const clusterMarker = (
    coordinates: [number, number],
    pointCount: number,
    getLeaves: (limit?: number, offset?: number) => Array<React.ReactElement<any>>
  ) => (
    <Marker
      key={coordinates.toString()}
      coordinates={coordinates}
      style={clusterMarkerStyle}
      onClick={() => {
        // If a cluster is clicked and zoomed in all the way, we setup the popup for a multiple scan list
        isMaxZoom &&
          setPopup({
            coordinates,
            total: pointCount,
            leaves: getLeaves(),
          })
      }}
    >
      <div>{pointCount}</div>
    </Marker>
  )

  const onMapMoveEnd = (map: any) => {
    const ne = map.getBounds().getNorthEast()
    const sw = map.getBounds().getSouthWest()
    const line = lineString([
      [ne.lat, ne.lng],
      [sw.lat, sw.lng],
    ])
    const mapBounds = bbox(line)
    setCurrentMapBounds(mapBounds)
  }

  const onMapZoomEnd = (map: any) => {
    Math.round(map.style.z) === 20 ? setIsMaxZoom(true) : setIsMaxZoom(false)
  }

  return (
    <>
      <Col sm={9} style={{ paddingLeft: 0, paddingRight: 0 }}>
        <MapBox>
          <Map
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{ height: '100%', width: '100%' }}
            center={mapCenter}
            zoom={mapZoom}
            onMoveEnd={onMapMoveEnd}
            onZoomEnd={onMapZoomEnd}
          >
            <ZoomControl zoomDiff={2} position={'bottom-right'}/>
            <Cluster ClusterMarkerFactory={clusterMarker} zoomOnClick={true} zoomOnClickPadding={40} maxZoom={20}>
              {scans?.filter(scan => scan.clientLocationLat && scan.clientLocationLong).map((scan, key) => (
                <Marker
                  key={key}
                  style={markerStyle}
                  coordinates={scan.geometry?.coordinates}
                  data-feature={scan}
                  onClick={() => {
                    setPopup({
                      coordinates: scan.geometry?.coordinates,
                      scan,
                    })
                    setMapCenter(scan.geometry?.coordinates)
                    setMapZoom([15])
                  }}
                >
                  <div>1</div>
                </Marker>
              ))}
            </Cluster>
            {popup && (
              <MapPopup popup={popup} setPopup={setPopup} project={project} collectionsById={collectionsById} scans={scans} />
            )}
          </Map>
        </MapBox>
      </Col>
      <Col sm={3} style={{ padding: 'unset' }}>
        <div style={{ padding: '15px' }}>
          <h3>Scans</h3>
          <p style={{ fontWeight: 'lighter' }}>
            Showing {scansInMapBounds?.length} {scansInMapBounds?.length === 1 ? 'scan' : 'scans'}{' '}
            with location data available
          </p>
        </div>

        <ScanList>
          <div>
            {pages[selectedPage]?.map((scan: ScanEventGeocoded, key: number) => {
              return popup?.scan?.id === scan?.id ? (
                <ScanListItemSelected scan={scan} setPopup={setPopup} key={key} />
              ) : (
                  <ScanListItem scan={scan} setPopup={setPopup} key={key} setMapCenter={setMapCenter} />
              )
            })}
          </div>
          <Pagination
            defaultSelectedIndex={0}
            selectedIndex={selectedPage}
            onChange={(event, page) => {
              setSelectedPage(page - 1)
            }}
            innerStyles={{ bottom: 0, justifyContent: 'center', position: 'sticky', backgroundColor: 'white' }}
            pages={numberOfPagesArray}
          />
        </ScanList>
      </Col>
    </>
  )
}

export default ScanMap
