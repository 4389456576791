import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormSection, ErrorMessage, HelperMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import { RadioGroup } from '@atlaskit/radio'
import { OptionsPropType } from '@atlaskit/radio/types'

// import SearchIcon from '@atlaskit/icon/glyph/search'

import { dateFormatter } from '../../../../helpers'
import AssetSelector from '../asset-selector'
import { OrangeButton } from '../../../styled/Button'
import FingerprintPreview from '../../fingerprint-preview'
import {
  Project,
  Asset,
  TagDownloadHubImageOption,
  TagDownloadImageFormatOption,
  Collection,
  TagOrder,
} from '../../../../types'
import { isNumber, isNumberGreaterThanZero } from '../../../../helpers'
import { Checkbox } from '@atlaskit/checkbox'
import DynamicTable from '@atlaskit/dynamic-table'
import PageHeader from '@atlaskit/page-header'
// import Textfield from '@atlaskit/textfield'

const HubImageOptionHolder = styled.div`
  display: flex;
  justify-content: space-between;
  // height: 150px;
`

const PDFOptionHolder = styled.div`
  display: flex;
  justify-content: space-between;

  div:first-child {
    padding-right: 15px;
  }

  div {
    flex: 1;
  }
`

interface NewTagBatchDownloadModalProps {
  isFetching?: boolean
  project: Project
  onSubmit: any
  isOpen: boolean
  setIsOpen: any
  collections: any[]
  tagOrders: any[]
  assets: any[]
}

const NewTagBatchDownloadModal: React.FC<NewTagBatchDownloadModalProps> = ({
  project,
  collections,
  tagOrders,
  onSubmit,
  isFetching,
  isOpen,
  setIsOpen,
  assets,
}) => {
  const [hubImageAsset, setHubImageAsset] = useState<Asset | null>(null)
  const [hubImageAssetSelectorIsOpen, setHubImageAssetSelectorIsOpen] = useState(false)

  let selectedCollections: string[] = []

  // const [searchText, setSearchText] = useState<string>('')
  const [hubImageAssetId, setHubImageAssetId] = useState(null)
  const [hubImageOption, setHubImageOption] = useState<TagDownloadHubImageOption>('no-hub-image')
  const [tagImageFormatOption, setTagImageFormatOption] = useState<TagDownloadImageFormatOption>('EPS')

  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }
  interface onFormSubmitParams {
    filenamePrefix?: string
    hubImageOption: TagDownloadHubImageOption
    imageFormatOption: TagDownloadImageFormatOption
    startTagOffset: number
    tagCount: number
    tagsPerArchive: number
  }

  const onFormSubmit = (data: onFormSubmitParams) => {
    const { filenamePrefix, tagsPerArchive, imageFormatOption } = data

    let submitData = {
      hubImageOption,
      imageFormatOption,
      filenamePrefix,
      hubImageAssetId,
      tagsPerArchive: Number(tagsPerArchive),
      selectedCollections,
    }

    onSubmit(submitData)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Order
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  const handleHubImageSelection = (assetId: any) => {
    setHubImageAssetId(assetId)
    const hubImageAsset = assets.filter((asset: any) => asset.id === assetId)[0]
    setHubImageAsset(hubImageAsset)
  }

  const handleCheckboxClick = (event: any) => {
    const collectionId = event.target.value

    if (event.target.checked) {
      const newSelectedCollections = [...selectedCollections, event.target.value]
      // setSelectedCollections(newSelectedCollections)

      selectedCollections = newSelectedCollections
    } else {
      selectedCollections = selectedCollections.filter((c) => c !== collectionId)
      // setSelectedCollections(selectedCollections.filter((c) => c !== collectionId))
    }
  }

  const hubImageRadioOptions: OptionsPropType = [
    { name: 'hubImage', value: 'no-hub-image', label: 'No Logo' },
    { name: 'hubImage', value: 'laava-hub-image', label: 'Laava Logo', isDisabled: true },
    { name: 'hubImage', value: 'custom-hub-image', label: 'Custom Logo' },
  ]

  const tagImageFormatRadioOptions: OptionsPropType = [
    { name: 'tagImageFormat', value: 'EPS', label: 'EPS' },
    { name: 'tagImageFormat', value: 'PNG', label: 'PNG' },
    { name: 'tagImageFormat', value: 'PDF', label: 'PDF' },
  ]

  const onChangeHubImageLogoRadio = useCallback((event: any) => {
    const hubImageOption: TagDownloadHubImageOption = event.currentTarget.value
    setHubImageOption(hubImageOption)
  }, [])

  const onChangeTagImageFormatRadio = useCallback((event: any) => {
    const tagImageFormatOption: TagDownloadImageFormatOption = event.currentTarget.value
    setTagImageFormatOption(tagImageFormatOption)
  }, [])

  // if (searchText) {
  //   collections = collections?.filter((collection: Collection) =>
  //     collection.name.toUpperCase().includes(searchText.toUpperCase()),
  //   )
  // }

  const collectionsHead = {
    cells: [
      {
        content: 'Selected',
        shouldTruncate: true,
        key: 'isSelected',
        isSortable: true,
      },
      {
        content: 'Name',
        shouldTruncate: true,
        key: 'name',
        isSortable: true,
      },
      {
        content: 'Total Fingerprints',
        key: 'tagCount',
        isSortable: true,
      },
      {
        content: 'Date Created',
        key: 'createdAt',
        isSortable: true,
      },
    ],
  }

  const collectionsCells = collections?.map((collection: Collection) => {
    const tagOrdersForCollection = tagOrders?.filter((tagOrder: TagOrder) => tagOrder.collectionId === collection.id)
    const totalNumberOfTags = tagOrdersForCollection?.reduce((count: any, tagOrder: TagOrder) => {
      return count + tagOrder.numberOfTags
    }, 0)
    const totalNumberOfTagsProcessing = totalNumberOfTags - collection?.tagCount || 0

    return {
      cells: [
        {
          content: (
            <Checkbox onChange={handleCheckboxClick} value={collection.id} name={collection.id} testId="cb-default" />
          ),
          // key: `${selectedCollections.indexOf(collection.id) > -1 ? 1 : 2}`,
        },
        {
          content: (
            <Link to={`/projects/${project.id}/collections/${collection.id}/fingerprints`}>{collection.name}</Link>
          ),
          key: collection.name,
          height: 36,
        },
        {
          // Show the total tagCount and any that may be processing
          content: (
            <>
              <p style={{ float: 'left', margin: 0 }}>{collection.tagCount}</p>
              {totalNumberOfTagsProcessing > 0 && (
                <p style={{ float: 'right', fontWeight: 'lighter', margin: 0 }}>
                  +{totalNumberOfTagsProcessing} processing
                </p>
              )}
            </>
          ),
          key: collection.tagCount,
        },
        {
          content: dateFormatter(collection.createdAt),
        },
      ],
    }
  })

  return (
    <div key={'new-tag-order-container'}>
      <OrangeButton onClick={open} appearance="primary">
        Set Up New Tag Download
      </OrangeButton>
      {isOpen && (
        <ModalTransition>
          <ModalDialog
            heading="Start New Tag Download"
            width="x-large"
            components={{
              Container: ({ children, className }) => (
                <>
                  <Form onSubmit={onFormSubmit}>
                    {({ formProps }) => (
                      <form {...formProps} className={className}>
                        {children}
                      </form>
                    )}
                  </Form>
                </>
              ),
              Footer: footer,
            }}
          >
            {
              <>
                <HubImageOptionHolder>
                  <Field name="hubImageOption" label="Hub Image" isRequired defaultValue={hubImageOption}>
                    {({ fieldProps, error }) => (
                      <>
                        <RadioGroup
                          value={hubImageOption}
                          isDisabled={false}
                          options={hubImageRadioOptions}
                          onChange={onChangeHubImageLogoRadio}
                          aria-labelledby="radiogroup-label"
                        />
                      </>
                    )}
                  </Field>

                  {hubImageOption === 'custom-hub-image' && (
                    <>
                      <AssetSelector
                        key={'product_image_asset_id'}
                        onSubmitAsset={(assetId: string) => {
                          handleHubImageSelection(assetId)
                        }}
                        currentAssetId={hubImageAssetId}
                        project={project}
                        isOpen={hubImageAssetSelectorIsOpen}
                        setIsOpen={setHubImageAssetSelectorIsOpen}
                        setIsClosed={() => {
                          setHubImageAssetSelectorIsOpen(false)
                        }}
                      />
                    </>
                  )}
                </HubImageOptionHolder>

                <HubImageOptionHolder>
                  <Field
                    name="imageFormatOption"
                    label="Tag Image Format"
                    isRequired
                    defaultValue={tagImageFormatOption}
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <RadioGroup
                          value={tagImageFormatOption}
                          isDisabled={false}
                          options={tagImageFormatRadioOptions}
                          onChange={onChangeTagImageFormatRadio}
                          aria-labelledby="radiogroup-label"
                        />
                      </>
                    )}
                  </Field>
                </HubImageOptionHolder>

                {tagImageFormatOption === 'PDF' && (
                  <FormSection>
                    <PDFOptionHolder>
                      <Field
                        name="pdfTagSize"
                        label="Diameter of Tag in mm"
                        validate={isNumberGreaterThanZero}
                        isRequired
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <TextField {...fieldProps} />
                            {tagImageFormatOption === 'PDF' && error === 'NOT_A_NUMBER_GREATER_THAN_ZERO' && (
                              <ErrorMessage>Number greater than zero required</ErrorMessage>
                            )}
                            <HelperMessage>The width of the tag in mm when exported to a PDF.</HelperMessage>
                          </>
                        )}
                      </Field>

                      <Field name="pdfTagMargin" label="Tag Margin in mm" validate={isNumberGreaterThanZero} isRequired>
                        {({ fieldProps, error }) => (
                          <>
                            <TextField {...fieldProps} />
                            {tagImageFormatOption === 'PDF' && error === 'NOT_A_NUMBER_GREATER_THAN_ZERO' && (
                              <ErrorMessage>Number greater than zero required</ErrorMessage>
                            )}
                            <HelperMessage>
                              The space between the tag and the edge of the PDF document in mm.
                            </HelperMessage>
                          </>
                        )}
                      </Field>
                    </PDFOptionHolder>
                  </FormSection>
                )}

                <FormSection>
                  <Field
                    name="tagsPerArchive"
                    label="Number of tags per archive file"
                    defaultValue={1000}
                    validate={isNumber}
                    isRequired
                  >
                    {({ fieldProps, error }) => (
                      <>
                        <TextField {...fieldProps} />
                        {error === 'NOT_A_NUMBER' && <ErrorMessage>Not a valid quantity</ErrorMessage>}
                        <HelperMessage>The number of tags to put in each archive file.</HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormSection>
                  <FingerprintPreview
                    url={hubImageAsset?.formats.thumbnail?.url || hubImageAsset?.url}
                    hubImageOption={hubImageOption}
                    key={'fp-preview'}
                  />
                </FormSection>

                <FormSection>
                  <PageHeader key={'page-header'}>Collections</PageHeader>
                  {/* <Textfield
                    placeholder="Search"
                    width="250"
                    onChange={(event) => {
                      const element = event.currentTarget as HTMLInputElement
                      setSearchText(element.value)
                    }}
                    elemAfterInput={<SearchIcon label="search" />}
                  /> */}
                  <br />
                  <DynamicTable
                    head={collectionsHead}
                    rows={collectionsCells}
                    rowsPerPage={100}
                    defaultPage={1}
                    loadingSpinnerSize="large"
                    isLoading={isFetching}
                    isFixedSize
                    defaultSortKey="createdAt"
                    defaultSortOrder="DESC"
                    emptyView={<h2>This project is empty.</h2>}
                  />
                </FormSection>
              </>
            }
          </ModalDialog>
        </ModalTransition>
      )}
    </div>
  )
}

export default NewTagBatchDownloadModal
