import {useContext, useState} from 'react';
import PermissionContext from "./context";

const usePermission = (permission?: string, type?: 'projects' | 'system') => {
    const [allowed, setAllowed] = useState<boolean>();

    const {isAllowedTo} = useContext(PermissionContext);

    isAllowedTo(permission, type).then((allowed) => {
        setAllowed(allowed);
    })
    return [allowed]
}

export default usePermission;