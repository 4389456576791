import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'

import DynamicTable from '@atlaskit/dynamic-table'
import Button, { ButtonGroup } from '@atlaskit/button'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import { Checkbox } from '@atlaskit/checkbox'
import Form, { Field, ErrorMessage, HelperMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { Company, Invitation, User } from '../../../../types'
import { OrangeButton } from '../../../styled/Button'
import { dateFormatter, emailValidator } from '../../../../helpers'
import { fetchFingerprintLimitForCompany } from '../../../../state/account/actions'

const SubmitButton = styled(OrangeButton)`
  margin: 0;
`

const validateNumberOfTags = (value: any) => {
  if (value && isNaN(value)) {
    return 'NOT_A_NUMBER'
  }
}

const usersHead = {
  cells: [
    {
      content: 'Name',
      shouldTruncate: true,
    },
    {
      content: 'Email',
      shouldTruncate: true,
    },
    {
      content: 'Email verified?',
      shouldTruncate: true,
    },
    {
      content: 'Date registered',
      key: 'created_at',
      isSortable: true,
    },
  ],
}

const invitationsHead = {
  cells: [
    {
      content: 'E-mail',
      shouldTruncate: true,
    },
    {
      content: 'Invitation is active',
      shouldTruncate: true,
    },
    {
      content: 'Date created',
      key: 'created_at',
      shouldTruncate: true,
    },
  ],
}

interface Props {
  company: Company
  companyInvitations?: Invitation[],
  onSubmitCompanyInvite: (data: { email: string, companyId: number }) => void;
  onSubmitCompanyUpdate: (data: { companyId: number, maxFingerprints: number }) => void;
}

const ManageCompanyModal: React.FC<Props> = ({ company, companyInvitations, onSubmitCompanyInvite, onSubmitCompanyUpdate }) => {
  const companyFingerprintCount = Number(company.total_fingerprint_count)
  const companyFingerprintLimit = Number(company.max_fingerprints)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = () => {
    fetchFingerprintLimitForCompany(company)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const usersCells = company.users.map((user: User) => {
    return {
      cells: [
        {
          content: user.first_name,
        },
        {
          content: user.email,
        },
        {
          content: <Checkbox isChecked={user.is_verified} />,
        },
        {
          content: dateFormatter(user.created_at),
        },
      ],
    }
  })

  const invitationsCells = companyInvitations?.map((invitation: Invitation) => {
    return {
      cells: [
        {
          content: invitation.email,
        },
        {
          content: <Checkbox isChecked={invitation.is_active} />,
        },
        {
          content: dateFormatter(invitation.created_at),
        },
      ],
    }
  })

  const handleCompanyInviteSubmit = (values: { email: string }) => {
    onSubmitCompanyInvite({
      ...values,
      companyId: company.id
    })
    close()
  }

  const handleCompanyUpdateSubmit = (values: { addToCompanyFingerprintLimit: number }) => {
    onSubmitCompanyUpdate({
      maxFingerprints: Number(values.addToCompanyFingerprintLimit) + companyFingerprintLimit,
      companyId: company.id
    })
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <>
      <Button onClick={open} iconBefore={<SettingsIcon label="manage" />}>
        Manage company
      </Button>

      <ModalTransition>
        {isOpen && (
          <ModalDialog
            heading={`Manage company: ${company?.name}`}
            components={{
              Container: ({ children, className }) => (
                <div className={className}>
                  {children}
                </div>
              ),
              Footer: footer,
            }}
          >
            <h2>Fingerprints</h2>
            <br/>
          <Row>
            <Col sm={3}><h4>Total Count</h4></Col>
              <Col sm={3}><h4>Limit</h4></Col>
              <Col sm={6}><h4>Increase Limit</h4></Col>
          </Row>
          <Row style={{height: '60px'}}>
            <Col sm={3}>
              {companyFingerprintCount.toLocaleString()}
            </Col>

            <Col sm={3}>
              {companyFingerprintLimit.toLocaleString()}
            </Col>

            <Col sm={6}>
              <Form onSubmit={handleCompanyUpdateSubmit}>
                {({ formProps }) => (
                  <form {...formProps} style={{display: 'flex', marginTop: '-8px'}}>
                      <Field
                        name="addToCompanyFingerprintLimit"
                        isRequired
                        validate={validateNumberOfTags}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <TextField
                              {...fieldProps}
                            />
                            {Number(fieldProps.value) > 0 && (
                              <HelperMessage>Limit will be increased to {((Number(fieldProps.value) + companyFingerprintLimit).toLocaleString())}</HelperMessage>
                            )}
                            {Number(fieldProps.value) < 0 && (
                              <HelperMessage>Limit will be decreased to {((Number(fieldProps.value) + companyFingerprintLimit).toLocaleString())}</HelperMessage>
                            )}
                            
                            {error === 'NOT_A_NUMBER' && <ErrorMessage>Not a valid number</ErrorMessage>}
                          </>
                        )}
                      </Field>
                      <SubmitButton
                        appearance="primary"
                        type="submit"
                        style={{ height: '40px', marginTop: '8px'}}
                      >
                        +
                      </SubmitButton>
                  </form>
                )}
              </Form>
              </Col>
            </Row>
            <h2>Users</h2>
            <Form onSubmit={handleCompanyInviteSubmit}>
              {({ formProps }) => (
                <form {...formProps}>
                  <Row style={{ placeContent: 'center' }}>
                    <Field name="email" label="Email" isRequired validate={emailValidator}>
                      {({ fieldProps, error }) => (
                        <Fragment>
                          <TextField {...fieldProps} style={{ width: '372px' }} />
                          {error === 'INVALID' && <ErrorMessage>Invalid email</ErrorMessage>}
                        </Fragment>
                      )}
                    </Field>

                    <SubmitButton
                      appearance="primary"
                      type="submit"
                      style={{
                        marginTop: '31px',
                        marginLeft: '10px',
                        height: '39px',
                        width: '150px',
                        placeContent: 'center',
                      }}
                    >
                      Invite to Company
                    </SubmitButton>
                  </Row>
                </form>
              )}
            </Form>
            <br />
            <DynamicTable
              head={usersHead}
              rows={usersCells}
              rowsPerPage={15}
              defaultPage={1}
              emptyView={<h2>No users found.</h2>}
            />
            <h2>Invitations</h2>
            <DynamicTable
              head={invitationsHead}
              rows={invitationsCells}
              rowsPerPage={5}
              defaultPage={1}
              emptyView={<h2>No invitations found.</h2>}
            />
            <br />
          </ModalDialog>
        )}
      </ModalTransition>
    </>
  )
}

export default ManageCompanyModal
