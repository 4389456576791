import {
  COLLECTIONS_REQUEST,
  COLLECTIONS_RECEIVE,
  COLLECTIONS_INVALIDATE,
  COLLECTIONS_ERROR,
} from "./actions";

const initialCollectionsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
};

export const collections = (state = initialCollectionsState, action: any) => {
  switch (action.type) {
    case COLLECTIONS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case COLLECTIONS_RECEIVE:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.collections,
        lastFetched: Date.now(),
        didInvalidate: false,
      });
    case COLLECTIONS_INVALIDATE:
      return Object.assign({}, state, {
        didInvalidate: true,
      });
    case COLLECTIONS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
      });

    default:
      return state;
  }
};
