import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import Welcome from './pages/welcome'
import Admin from './pages/admin'
import Login from './pages/login'
import Logout from './pages/logout'
import ForgotYourPassword from './pages/forgot-your-password'
import ResetPassword from './pages/reset-password'
import Register from './pages/register'
import NotFound from './pages/not-found'
import NeedHelp from './pages/need-help'
import JoinCompany from './pages/join-company'
import VerifyEmail from './pages/verify-email'
import VerificationSent from './pages/verification-sent'
import Onboarding from './pages/onboarding'
import Collection from './pages/collection'
import Projects from './pages/projects'
import Project from './pages/project'
import Ted from './pages/ted'

import PrivateRoute from './components/react/private-route'
import { PublicRoute } from './components/react/public-route'
import { Roles } from './components/accounts'
import { PageLayout } from '@atlaskit/page-layout'

const Page = (props: any) => <div style={{ height: '100vh', overflowY: 'scroll' }} {...props} />

function App() {
  return (
    <Router>
      <Page>
        <PageLayout>
          <Switch>
            <PrivateRoute exact path="/" component={Welcome} />
            <PrivateRoute exact path="/admin" component={Admin} />
            <PrivateRoute exact path="/projects" component={Projects} />
            <PrivateRoute
              exact
              path={[
                '/projects/:projectId',
                '/projects/:projectId/collections',
                '/projects/:projectId/analytics',
                '/projects/:projectId/download',
                '/projects/:projectId/users',
                '/projects/:projectId/api-keys',
                '/projects/:projectId/result-views',
                '/projects/:projectId/result-views/:viewId',
                '/projects/:projectId/assets',
              ]}
              component={Project}
              roleRequired={Roles.SystemRoles.ADMIN}
            />
            <PrivateRoute
              exact
              path={['/projects/:projectId/ted']}
              component={Project}
              roleRequired={Roles.SystemRoles.SUPER_USER}
            />
            <PrivateRoute
              exact
              path={[
                '/projects/:projectId/collections/:collectionId',
                '/projects/:projectId/collections/:collectionId/fingerprints',
                '/projects/:projectId/collections/:collectionId/metadata',
                '/projects/:projectId/collections/:collectionId/result-views',
                '/projects/:projectId/collections/:collectionId/download',
              ]}
              component={Collection}
              roleRequired={Roles.SystemRoles.ADMIN}
            />
            <PrivateRoute exact path="/need-help" component={NeedHelp} />
            <PublicRoute exact path="/onboarding" component={Onboarding} />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/logout" component={Logout} />
            <PublicRoute exact path="/forgot-your-password" component={ForgotYourPassword} />
            <PublicRoute exact path="/reset-password/:token" component={ResetPassword} />
            <PublicRoute exact path="/register" component={Register} />
            <PublicRoute path="/register/verify/:token" component={VerifyEmail} />
            <PublicRoute exact path="/verification-sent" component={VerificationSent} />
            <PublicRoute path="/join-company/:token" component={JoinCompany} />
            <PrivateRoute exact path="/ted" component={Ted} roleRequired={Roles.SystemRoles.SUPER_USER} />
            <Route component={NotFound} />
          </Switch>
        </PageLayout>
        </Page>
    </Router>
  )
}

export default App
