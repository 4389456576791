import React, { useState } from 'react'
import { connect } from 'react-redux'
import HubspotForm from 'react-hubspot-form'
import styled from 'styled-components'

import Page, { Grid, GridColumn } from '@atlaskit/page'
import PageHeader from '@atlaskit/page-header'
import Button, { ButtonGroup } from '@atlaskit/button'
import { Main } from '@atlaskit/page-layout'

import { User, State } from '../../types'

const Wrapper = styled.div`
  padding: 8px 40px;
`

const OrangeButton = styled(Button)`
  background-color: #333333 !important;
  color: #ffffff !important;
  &:hover {
    opacity: 0.5;
  }
`

interface Props {
  user: User
}

const mapStateToProps = (state: State) => {
  return {
    user: state.account.login.user,
  }
}

const NeedHelp: React.FC<Props> = ({ user }) => {
  const [buttonsHidden, setButtonsHidden] = useState(true)
  return (
    <Main>
      <PageHeader></PageHeader>
      <Wrapper>
        <Page>
          <Grid>
            <GridColumn medium={12}>
              <HubspotForm
                portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
                formId={process.env.REACT_APP_HUBSPOT_NEED_HELP_FORM_ID}
                // This callback requires jquery to be executed
                onSubmit={() => {
                  // This timeout is required since there is no 'onSubmitted' callback for some reason.
                  // Otherwise theres a split second where the embedded submit button is shown along with...
                  // ...the next screens buttons
                  setTimeout(() => {
                    setButtonsHidden(false)
                  }, 1000)
                }}
                loading={<div>Loading...</div>}
                onFormReady={(form: any) => {
                  // We pre-fill the (hidden) e-mail field here
                  // Used from: https://github.com/facebook/react/issues/11488

                  let input = form[0].querySelector("input[name='email']")
                  let lastValue = input.value
                  input.value = user.email
                  let event = new Event('input', { bubbles: true })

                  let tracker = input._valueTracker
                  if (tracker) {
                    tracker.setValue(lastValue)
                  }
                  input.dispatchEvent(event)
                }}
              />
              <div hidden={buttonsHidden}>
                <ButtonGroup>
                  <OrangeButton href={'/need-help'}>
                    <div style={{ color: 'white' }}>Submit another</div>
                  </OrangeButton>
                  <OrangeButton href={'/'}>
                    <div style={{ color: 'white' }}>Done</div>
                  </OrangeButton>
                </ButtonGroup>
              </div>
            </GridColumn>
          </Grid>
        </Page>
      </Wrapper>
    </Main>
  )
}

export default connect(mapStateToProps)(NeedHelp)
