import React, { useState, useCallback, useRef } from 'react'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormSection, HelperMessage, Label } from '@atlaskit/form'
import Select from '@atlaskit/select'
import TextField from '@atlaskit/textfield'

import { resultScreenLanguageLabels, scanPlatformLabels } from '../../../../helpers'
import { OrangeButton } from '../../../styled/Button'
import { Project, CollectionView, View } from '../../../../types'

interface NewTagDownloadModalProps {
  collectionViews: CollectionView[]
  views: View[]
  project: Project
  onSubmit: any
  isOpen: boolean
  setIsOpen: any
}

const NewCollectionViewModal: React.FC<NewTagDownloadModalProps> = ({ onSubmit, isOpen, setIsOpen, views }) => {
  const [viewId, setViewId] = useState<string | null>(null)
  const [platform, setPlatform] = useState<string | null>(null)
  const [language, setLanguage] = useState<string | null>(null)
  const statusRef = useRef<HTMLInputElement>(null)

  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  interface onFormSubmitParams {
    status: string
  }

  const onFormSubmit = (data: onFormSubmitParams) => {
    const status = statusRef.current?.value

    let submitData = {
      viewId,
      status,
      platform,
      language,
    }

    onSubmit(submitData)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Submit
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  const platformSelectOptions = Object.entries(scanPlatformLabels).map(([key, value]) => {
    return { value: key, label: value }
  })

  const languageSelectOptions = Object.entries(resultScreenLanguageLabels).map(([key, value]) => {
    return { value: key, label: value }
  })

  const onChangeViewSelect = useCallback((event: any) => {
    setViewId(event.value)
  }, [])

  const onChangePlatformSelect = useCallback((event: any) => {
    setPlatform(event.value)
  }, [])

  const onChangeLanguageSelect = useCallback((event: any) => {
    setLanguage(event.value)
  }, [])

  return (
    <div key={'new-collection-view-container'}>
      <OrangeButton onClick={open} appearance="primary">
        Assign a Result View
      </OrangeButton>
      {isOpen && (
        <ModalTransition>
          <ModalDialog
            heading="Assign a Result View"
            components={{
              Container: ({ children, className }) => (
                <>
                  <Form onSubmit={onFormSubmit}>
                    {({ formProps }) => (
                      <form {...formProps} className={className}>
                        {children}
                      </form>
                    )}
                  </Form>
                </>
              ),
              Footer: footer,
            }}
          >
            {
              <>
                <FormSection>
                  <Label htmlFor="view-select">Result View</Label>
                  <Select
                    inputId="view-select"
                    name="viewId"
                    label="Result View to Associate"
                    options={views.map((view) => ({ label: view.name, value: view.id }))}
                    placeholder="Select a view"
                    onChange={onChangeViewSelect}
                    value={
                      views
                        .map((view) => ({ label: view.name, value: view.id }))
                        .find((option) => option.value === viewId) || null
                    }
                    isRequired
                  ></Select>
                </FormSection>

                <FormSection>
                  <Field name="status" label="Scan Status" isRequired>
                    {({ fieldProps, error }) => (
                      <>
                        <TextField
                          key={"statusTextField"}
                          {...fieldProps}
                          ref={statusRef}
                          defaultValue={"positive"}
                          isRequired
                        />
                        <HelperMessage>This Result View will appear for this scan status.</HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>

                <FormSection>
                  <Label htmlFor="platform-select">Platform</Label>
                  <Select
                    inputId="platform-select"
                    name="platform"
                    label="Platform"
                    options={platformSelectOptions}
                    placeholder="Select a Platform"
                    onChange={onChangePlatformSelect}
                    value={platformSelectOptions.find((option) => option.value === platform) || null}
                    isRequired
                  ></Select>
                </FormSection>

                <FormSection>
                  <Label htmlFor="platform-select">Language</Label>
                  <Select
                    inputId="language-select"
                    name="language"
                    label="Language"
                    options={languageSelectOptions}
                    placeholder="Select a Language"
                    onChange={onChangeLanguageSelect}
                    value={languageSelectOptions.find((option) => option.value === language) || null}
                    isRequired
                  ></Select>
                  <div style={{ height: '100px' }}></div>
                </FormSection>
              </>
            }
          </ModalDialog>
        </ModalTransition>
      )}
    </div>
  )
}

export default NewCollectionViewModal
