import React from "react";
import { Container, Row } from 'react-grid-system';

const showOrderStatus = (status, progress) => {
  switch (status) {
    case "COMPLETED":
      return (
        <>
          <p>Complete</p>
        </>
      );
    case "EMPTY":
      return (
        <>
          <p>Empty</p>
        </>
      )
    case "PROCESSING":
      return <p>Processing</p>
    default:
      return (
        <>
          <p>Empty</p>
        </>
      )
  }
}

export default ({totalNumberOfTagsProcessing, totalNumberOfTags}) => {
  const progress = totalNumberOfTagsProcessing/totalNumberOfTags
  const getOrderStatus = () => {
    if (totalNumberOfTagsProcessing > 0) {
        return "PROCESSING"
    }
    if (totalNumberOfTagsProcessing === 0) {
        if (totalNumberOfTags > 0) {
            return "COMPLETED"
        }
        return "EMPTY"
    }
  }
  const status = getOrderStatus()
  const orderStatus = showOrderStatus(status, progress)
  return (
    <Container>
      <Row>
        {orderStatus}
      </Row>
    </Container>
  )
};
