import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { TagStyle, TagData, TagRender, HubImageData } from '@laava/tag-generator'

import Lozenge from '@atlaskit/lozenge'
import PageHeader from '@atlaskit/page-header'
import DynamicTable from '@atlaskit/dynamic-table'
import Button from '@atlaskit/button'
import { ButtonGroup } from '@atlaskit/button'
import ProgressBar from '@atlaskit/progress-bar'

import jsPDF from 'jspdf'
import 'svg2pdf.js'

import {
  Project,
  State,
  TagOrder,
  Collection,
  Tag,
  TagDownloadHubImageOption,
  TagDownloadStatus,
  TagDownloadImageFormatOption,
} from '../../types'
import {
  addTagDownload,
  updateTagDownloadTagsComplete,
  updateTagDownloadStatus,
} from '../../state/tag-downloads/actions'
import { collectionsInvalidate } from '../../state/collections/actions'
import NewTagDownloadModal from '../../components/react/modals/new-tag-download'
import { getCsvMetadataString } from '../../helpers'

interface Props {
  isFetching?: boolean
  tagFetchProgress: number
  collection: Collection
  tags: Tag[]
  project: Project
  tagOrders: TagOrder[]
  dispatch?: any
  assets?: any
  tagDownloads?: TagDownload[]
}

const mapStateToProps = (state: State, ownProps: any) => {
  const collection = state.collections.items.filter(
    (collection: Collection) => collection.id === ownProps.match.params.collectionId,
  )[0]
  const tagFetchProgress = state.tags.fetchProgress
  const project = state.projects.items.filter((project: Project) => project.id === ownProps.match.params.projectId)[0]
  const assets = state.content.assets.items
  const tagDownloads: TagDownload[] = state.tagDownloads.items.filter(
    (tagDownload: TagDownload) => tagDownload.collectionId === ownProps.match.params.collectionId,
  )

  return {
    isFetching: state.collections.isFetching || state.tags.isFetching,
    tagFetchProgress,
    collection,
    project,
    assets,
    tagDownloads,
  }
}

interface TagDownload {
  id: string
  fileName: string
  collectionId: string
  startTagOffset: number
  tagCount: number
  imageFormatOption: TagDownloadImageFormatOption
  hubImageOption: TagDownloadHubImageOption
  pdfTagSize?: number
  pdfTagMargin?: number
  hubImageBase64Data: string
  tagsQueued: number
  tagsComplete: number
  status: string
}

const CollectionDownloads: React.FC<Props> = ({ isFetching, collection, project, dispatch, assets, tagDownloads }) => {
  const [isTagDownloadModalOpen, setIsTagDownloadModalOpen] = useState(false)

  useEffect(() => {
    dispatch(collectionsInvalidate())
  }, [collection.id, dispatch, project.id])

  useEffect(() => {
    const cleanCache = async () => {
      const cache = await caches.open('laava-manage-tag-download')
      const keys = await cache.keys()

      if (tagDownloads) {
        const allowedCacheKeys: any[] = []

        tagDownloads.forEach((tagDownload) => {
          const fileName = tagDownload.fileName
          allowedCacheKeys.push(fileName)
        })

        keys.forEach((key) => {
          const components = key.url.split('/')
          const fileName = components[components.length - 1]

          if (allowedCacheKeys.indexOf(fileName) < 0 && fileName.indexOf('.zip') > 0) {
            cache.delete(fileName)
          }
        })
      }
    }
    cleanCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  interface onPrepareTagDownloadsParams {
    hubImageAssetId?: string
    filenamePrefix: string
    hubImageOption: TagDownloadHubImageOption
    imageFormatOption: TagDownloadImageFormatOption
    tagCount: number
    startTagOffset: number
    tagsPerArchive: number
    hubImageBase64?: string
    pdfTagSize?: number
    pdfTagMargin?: number
  }

  const prepareTagDownloads = ({
    filenamePrefix,
    startTagOffset,
    tagCount,
    tagsPerArchive,
    hubImageOption,
    imageFormatOption,
    hubImageBase64,
    pdfTagSize,
    pdfTagMargin,
  }: onPrepareTagDownloadsParams) => {
    const maxTagsPerArchive = tagsPerArchive || 5000
    const downloadArchives: any = []

    if (!hubImageBase64) hubImageBase64 = ''

    const tagsToGenerate = Math.min(tagCount, collection.tagCount - startTagOffset)
    const numberOfArchives = Math.ceil(tagsToGenerate / maxTagsPerArchive)

    for (var i = 0; i < numberOfArchives; i += 1) {
      const fileName = `${filenamePrefix}_${i + 1}.zip`
      const status: TagDownloadStatus = 'queued'
      const tagsLeftToGenerate = tagsToGenerate - maxTagsPerArchive * i
      const tagCount = tagsLeftToGenerate > maxTagsPerArchive ? maxTagsPerArchive : tagsLeftToGenerate

      if (!downloadArchives[i])
        downloadArchives[i] = {
          fileName,
          collectionId: collection.id,
          startTagOffset: startTagOffset + maxTagsPerArchive * i,
          tagCount,
          hubImageOption,
          imageFormatOption,
          hubImageBase64Data: hubImageBase64,
          pdfTagSize,
          pdfTagMargin,
          tagsComplete: 0,
          status,
        }
    }

    downloadArchives.forEach((downloadArchive: any) => {
      dispatch(addTagDownload(downloadArchive))
    })
  }

  interface NewTagDownloadData {
    hubImageAssetId?: string
    filenamePrefix: string
    hubImageOption: TagDownloadHubImageOption
    imageFormatOption: TagDownloadImageFormatOption
    tagCount: number
    pdfTagSize?: number
    pdfTagMargin?: number
    startTagOffset: number
    tagsPerArchive: number
  }

  const getTagData = async (startTagOffset: number, tagCount: number): Promise<Tag[]> => {
    return new Promise(async (resolve, reject) => {
      const DATA_PAGE_SIZE = 5000

      let tagPromises = []
      const tagData: any[] = []

      for (var i = 0; i < tagCount; i += DATA_PAGE_SIZE) {
        let remainingTags = tagCount - i
        let limit = remainingTags < DATA_PAGE_SIZE ? remainingTags : DATA_PAGE_SIZE
        let offset = startTagOffset + i

        const promise = axios.get(
          `${process.env.REACT_APP_TMAPI_BASE_URL}/tags?collectionIds[]=${collection.id}&fields=id,collectionId,createdAt,updatedAt&offset=${offset}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              project_id: project.id,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
        tagPromises.push(promise)
      }

      await Promise.all(tagPromises).then((responses) => {
        responses.forEach((res) => {
          tagData.push(...res.data)
        })
      })

      resolve(tagData)
    })
  }

  const onPrepareTagDownloads = async (newTagDownloadData: NewTagDownloadData) => {
    const {
      hubImageAssetId,
      filenamePrefix,
      hubImageOption,
      imageFormatOption,
      startTagOffset,
      tagCount,
      tagsPerArchive,
      pdfTagSize,
      pdfTagMargin,
    } = newTagDownloadData

    let hubImageBase64 = ''

    if (hubImageOption === 'custom-hub-image' && hubImageAssetId) {
      hubImageBase64 = await getAssetAsBase64(hubImageAssetId)
    }

    prepareTagDownloads({
      filenamePrefix,
      startTagOffset,
      tagCount,
      tagsPerArchive,
      hubImageOption,
      imageFormatOption,
      hubImageBase64,
      pdfTagSize,
      pdfTagMargin,
    })
  }
  const getHubImageData = async (hubImageOption: TagDownloadHubImageOption, hubImageBase64Data: string) => {
    const BLANK_HUB_IMAGE_DATA: HubImageData = {
      hubImageDataString: '',
      hubImageDisplayHeight: '348.16',
      hubImageDisplayWidth: '348.16',
      hubImageDisplayX: 337.92,
      hubImageDisplayY: 337.92,
      hubImageHeight: 350,
      hubImageWidth: 350,
    }

    let tagStyle

    switch (hubImageOption) {
      case 'custom-hub-image':
        if (hubImageBase64Data !== '') {
          tagStyle = TagStyle.version('1', hubImageBase64Data)
          const tagData = TagData.generate(tagStyle)
          const imageData = await TagRender.epsHubImageData(tagData)
          return imageData
        }
        break
      case 'laava-hub-image':
        tagStyle = TagStyle.version('1', TagStyle.laavaLogoBase64)
        const tagData = TagData.generate(tagStyle)
        const imageData = await TagRender.epsHubImageData(tagData)
        return imageData
      case 'no-hub-image':
        return BLANK_HUB_IMAGE_DATA
      default:
        return BLANK_HUB_IMAGE_DATA
    }
  }

  const processTagDownloads = async () => {
    const cache = await caches.open('laava-manage-tag-download')

    if (tagDownloads) {
      for (var i = 0; i < tagDownloads.length; i += 1) {
        const {
          id,
          fileName,
          startTagOffset,
          tagCount,
          tagsComplete,
          hubImageOption,
          imageFormatOption,
          hubImageBase64Data,
          pdfTagSize = 20,
          pdfTagMargin = 5,
        } = tagDownloads[i]

        if (tagsComplete === 0) {
          var zip = new JSZip()

          const hubImageData = await getHubImageData(hubImageOption, hubImageBase64Data)

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'downloading data' }))
          const tags = await getTagData(startTagOffset, tagCount)

          for (var j = 0; j < tags.length; j += 1) {
            const tag = tags[j]

            let tagStyle

            switch (hubImageOption) {
              case 'custom-hub-image':
                tagStyle = TagStyle.version(tag.version, hubImageBase64Data)
                break
              case 'laava-hub-image':
                tagStyle = TagStyle.version(tag.version, TagStyle.laavaLogoBase64)
                break
              case 'no-hub-image':
                tagStyle = TagStyle.version(tag.version, TagStyle.blankLogoBase64)
                break
              default:
                tagStyle = TagStyle.version(tag.version, TagStyle.laavaLogoBase64)
            }

            const tagData = TagData.generate(tagStyle, tag.seed)

            let blob = null
            let tagFileName = ''

            if (imageFormatOption === 'EPS') {
              // render tag as EPS

              const epsTag = await TagRender.eps(tagData, hubImageData)

              blob = new Blob([epsTag], { type: 'application/eps' })
              tagFileName = `${tag.id}.eps`

              // save the files to the cache here
              const response = new Response(blob)
              await cache.put(tagFileName, response)
            } else if (imageFormatOption === 'PNG') {
              // render tag as PNG
              const pngTag = await TagRender.png(tagData)

              // turn the buffer into a file and save it to the cache
              const finalBlob = new Blob([pngTag as BlobPart], { type: 'image/png' })
              const response = new Response(finalBlob)
              tagFileName = `${tag.id}.png`
              await cache.put(tagFileName, response)
            } else if (imageFormatOption === 'PDF') {
              const pdfDocumentSize: number = pdfTagSize + pdfTagMargin * 2

              // Initialize jsPDF instance
              const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [pdfDocumentSize, pdfDocumentSize],
              })

              // Generate SVG content for the tag
              const svgTag = await TagRender.svg(tagData)

              // Create a virtual SVG element (you can also directly query an SVG element in the DOM)
              const svgElement = new DOMParser().parseFromString(svgTag, 'image/svg+xml').documentElement

              // Convert SVG to PDF using the jsPDF instance (svg2pdf.js automatically hooks into jsPDF)

              try {
                await pdf.svg(svgElement, {
                  x: pdfTagMargin,
                  y: pdfTagMargin,
                  width: pdfTagSize,
                  height: pdfTagSize,
                })
              } catch (e) {
                console.error(e)
              }

              // Save the PDF to a blob
              const pdfBlob = pdf.output('blob')

              // Cache the blob
              const response = new Response(pdfBlob)
              tagFileName = `${tag.id}.pdf`
              await cache.put(tagFileName, response)
            }

            dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'generating' }))
            dispatch(updateTagDownloadTagsComplete({ tagDownloadId: id, tagsComplete: j + 1 }))
          }

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'archiving' }))

          for (let k = 0; k < tags.length; k++) {
            const tag = tags[k]
            const fileName = `${tag.id}.${imageFormatOption.toLowerCase()}`
            const epsResponse = await cache.match(fileName)
            const epsBlob = await epsResponse?.blob()
            if (epsBlob) zip.file(fileName, epsBlob)
          }

          const csvString = getCsvMetadataString(tags)
          zip.file('manifest.csv', csvString)
          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'finalising' }))

          const content = await zip.generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              level: 9,
            },
          })

          const response = new Response(content)
          await cache.put(fileName, response)

          // now delete all the tags in the cache

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'cleaning up' }))

          for (let k = 0; k < tags.length; k++) {
            const tag = tags[k]
            const fileName = `${tag.id}.${imageFormatOption.toLowerCase()}`

            const deleteResponse = await cache.delete(fileName)
            if (!deleteResponse) {
              console.error('There was a problem deleting a cache entry.')
            }
          }

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'complete' }))
        }
      }
    }
  }

  const getMimeType = (url: string) => {
    const extension = url.split('.').pop()?.toLowerCase()
    const mimeTypes: { [key: string]: string } = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      // add more types as needed
    }
    return mimeTypes[extension || ''] || 'application/octet-stream'
  }

  const getAssetAsBase64 = async (assetId: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const hubImageAsset = assets.filter((item: any) => item.id === assetId);
      const hubImageAssetUrl = hubImageAsset[0].formats.small?.url || hubImageAsset[0].url;
  
      if (hubImageAssetUrl) {
        const response = await axios.get(hubImageAssetUrl, { responseType: 'blob' });
  
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          if (typeof reader.result === 'string') {
            const img = new Image();
            img.src = reader.result;
  
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const size = Math.max(img.width, img.height);
              canvas.width = size;
              canvas.height = size;
  
              const offsetX = (size - img.width) / 2;
              const offsetY = (size - img.height) / 2;
  
              if (ctx) {
                ctx.drawImage(img, offsetX, offsetY, img.width, img.height);
                const squaredBase64 = canvas.toDataURL();
                const mimeType = getMimeType(hubImageAssetUrl);
                const correctBase64String = squaredBase64.replace('application/octet-stream', mimeType);
                resolve(correctBase64String);
              } else {
                reject(new Error('Could not get 2D context from canvas'));
              }
            };
          } else {
            // Handle the ArrayBuffer case
          }
        };
      } else {
        alert('There was a problem selecting this asset.');
      }
    });
  };
  

  const downloadFile = async (tagDownloadId: string) => {
    try {
      if (tagDownloads) {
        const fileName: string = tagDownloads.filter((tagDownload) => tagDownload.id === tagDownloadId)[0].fileName
        const cache = await caches.open('laava-manage-tag-download')
        const archiveResponse = await cache.match(fileName)
        const archiveBlob = await archiveResponse?.blob()
        archiveBlob && saveAs(archiveBlob, fileName)
      }
    } catch (e) {
      throw new Error(e as any)
    }
  }

  const tagDownloadsHead = {
    cells: [
      {
        content: 'File Name',
        key: 'fileName',
        isSortable: true,
        height: 36,
        width: 35,
      },
      {
        content: 'Format',
        width: 10,
      },
      {
        content: 'Number of Fingerprints',
        width: 10,
      },
      {
        content: 'Progress',
        width: 30,
      },
      {
        content: 'Status',
        width: 15,
      },
      {
        width: 10,
      },
    ],
  }

  const tagDownloadsCells =
    tagDownloads &&
    tagDownloads.map((tagDownload: TagDownload) => {
      let statusLozengeAppearance: 'success' | 'inprogress' | 'default' | 'moved' | 'new' | 'removed'
      let statusLozengeText = tagDownload.status

      statusLozengeAppearance = 'default'

      const progressBar = (tagDownload: TagDownload) => {
        const { tagCount, tagsComplete, status } = tagDownload

        const progress = tagsComplete / tagCount

        switch (status) {
          case 'archiving':
            return <ProgressBar isIndeterminate />
          default:
            return <ProgressBar value={progress} />
        }
      }

      return {
        cells: [
          {
            content: tagDownload.fileName,
            key: tagDownload.fileName,
            height: 36,
          },
          {
            content: tagDownload.imageFormatOption,
            key: `${tagDownload.id}_imageFormat`,
          },
          {
            content: tagDownload.tagCount,
            key: `${tagDownload.id}_numberOfTags`,
          },
          {
            content: progressBar(tagDownload),
          },
          {
            content: <Lozenge appearance={statusLozengeAppearance}>{statusLozengeText}</Lozenge>,
          },
          {
            content: (
              <>
                {/* {tagDownload.status === 'queued' && <Button onClick={() => generateFile(tagDownload.id)}>Generate</Button>} */}
                {tagDownload.status === 'complete' && (
                  <Button onClick={() => downloadFile(tagDownload.id)}> Download</Button>
                )}
              </>
            ),
          },
        ],
      }
    })

  const actions = (
    <ButtonGroup>
      {
        <NewTagDownloadModal
          key={'new-tag-setup-button'}
          assets={assets}
          project={project}
          onSubmit={onPrepareTagDownloads}
          isOpen={isTagDownloadModalOpen}
          setIsOpen={setIsTagDownloadModalOpen}
        />
      }
      {tagDownloads?.length && (
        <Button key={'new-tag-download-button'} onClick={processTagDownloads}>
          Prepare All
        </Button>
      )}
    </ButtonGroup>
  )

  return (
    <>
      <PageHeader key={'page-header'} actions={actions}>
        Download Fingerprints
      </PageHeader>

      <DynamicTable
        head={tagDownloadsHead}
        rows={tagDownloadsCells}
        loadingSpinnerSize="large"
        isLoading={isFetching}
        isFixedSize
        defaultSortKey="fileName"
        defaultSortOrder="ASC"
        emptyView={<h3>Set up a new tag download to get started.</h3>}
        key={'tag-downloads-table'}
      />
    </>
  )
}

export default connect(mapStateToProps)(CollectionDownloads)
