import React, { useState, Fragment } from 'react'

import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import Button, { ButtonGroup } from '@atlaskit/button'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Form, { Field, ErrorMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { OrangeButton } from '../../../styled/Button'
import { emailValidator } from '../../../../helpers/input-validators'

interface InviteCompanyUserModalProps {
  onSubmit: ({ email }: { email: string }) => void;
}

const InviteCompanyUserModal: React.FC<InviteCompanyUserModalProps> = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  const onFormSubmit = (data: { email: string }) => {
    onSubmit(data)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Invite
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div>
      <OrangeButton
        onClick={open}
        appearance="primary"
        iconBefore={<AddCircleIcon primaryColor="white" secondaryColor="#FF5500" label="inviteUser" />}
      >
        Invite User
      </OrangeButton>
      <ModalTransition>
        {isOpen && (
          <ModalDialog
            heading="Invite a User to Your Company"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onFormSubmit}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: footer,
            }}
          >
            <Field name="email" defaultValue="" label="Email" isRequired validate={emailValidator}>
              {({ fieldProps, error }) => (
                <Fragment>
                  <TextField {...fieldProps} />
                  {error === 'INVALID' && <ErrorMessage>Invalid email</ErrorMessage>}
                </Fragment>
              )}
            </Field>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  )
}

export default InviteCompanyUserModal
