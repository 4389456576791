/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'

import DynamicTable from '@atlaskit/dynamic-table'
import PageHeader from '@atlaskit/page-header'
import InlineMessage from '@atlaskit/inline-message'
import Select from '@atlaskit/select'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import { Code } from '@atlaskit/code'

import { Restricted } from '../../providers/permission'
import { OrangeButton } from '../../components/styled/Button'
import { Project, User, State } from '../../types'
import { dateFormatter } from '../../helpers'
import { fetchProjectsIfNeeded } from '../../state/projects/actions'
import { Roles } from '../../components/accounts'

const SubmitButton = styled(OrangeButton)`
  margin: 0;
`

interface Props {
  isFetching: boolean
  project: Project
  loginState: any
  dispatch: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  // We use the company the project is part of
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]

  return {
    isFetching: state.projects.isFetching || state.account.company.isFetching,
    project,
    loginState: state.account.login,
  }
}

const ProjectApiKeys: React.FC<Props> = ({ isFetching, project, loginState, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false) // Displays created api key
  const open = () => {
    setIsOpen(true)
  } // Displays created api key
  const close = () => {
    setIsOpen(false)
  } // Displays created api key
  const [createdApiKey, setCreatedApiKey] = useState('') // Displays created api key

  const [apiKeys, setApiKeys] = useState([]) // Displays current keys
  const [userPermissions, setUserPermissions] = useState([]) // Creates new key using these values
  const [selectValue, setSelectValue] = useState<any>([{ label: '', value: null }]) // Select field values
  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setSelectValue(newValue)
  }

  // API key creation logic
  const onSubmitNewKey = async (event: any) => {
    const url = `${process.env.REACT_APP_ACCMAN_URL}/api-keys`
    event.preventDefault() // Needed to properly update state
    const data = {
      scopes: selectValue.map((value: any) => {
        return value.value
      }),
      projectId: project.id,
    }
    await axios({
      // Create new api key
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data,
    })
      .then((result) => {
        setCreatedApiKey(result.data.apiKey)
        open()
        return fetchKeys()
      })
      .catch((error) => {
        setErrorMessage('Something went wrong.')
      })
  }

  async function fetchKeys() {
    const url = `${process.env.REACT_APP_ACCMAN_URL}/api-keys?join=project&join=owner&join=owner.company||name&filter=project.id||$eq||${project.id}`
    await axios({
      // Get all available api keys for this project
      method: 'get',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((result) => {
        return setApiKeys(result.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async function fetchUserPermissions() {
    // Check if the user is a superuser, if so we allow all permissions to be used
    if (loginState.user.role.name === Roles.SystemRoles.SUPER_USER) {
      await axios({
        // Get all role permissions associated for the current user
        method: 'get',
        url: `${process.env.REACT_APP_ACCMAN_URL}/permissions`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((result) => {
        return setUserPermissions(
          result.data.filter((permission: any) => {
            return permission.type !== 'system'
          }),
        )
      })
    } else {
      await axios({
        // Get all role permissions associated for the current user
        method: 'get',
        url: `${process.env.REACT_APP_ACCMAN_URL}/my-projects/${project.id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((result) => {
        return setUserPermissions(
          result.data.role.rolePermissions.map((rolePermission: any) => {
            return rolePermission.permission
          }),
        )
      })
    }
  }

  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
  })

  useEffect(() => {
    fetchKeys()
    fetchUserPermissions()
  }, [])

  // Populate our drop down menu...
  const initialSelectOptions =
    userPermissions?.map((userPermission: any) => {
      return { label: userPermission.description, value: userPermission.name }
    }) || []

  const apiKeysHead = {
    cells: [
      {
        content: 'Owner name',
        shouldTruncate: true,
        isSortable: true,
        key: 'name',
      },
      {
        content: 'Owner email',
        shouldTruncate: true,
        key: 'email',
      },
      {
        content: 'Owner company',
        shouldTruncate: true,
      },
      {
        content: 'Key scopes',
        shouldTruncate: true,
      },
      {
        content: 'Issued at',
        isSortable: true,
        key: 'created_at',
      },
    ],
  }

  const apiKeysCells = apiKeys?.map((apiKey: { owner: User; created_at: string; scopes: string[] }) => {
    return {
      cells: [
        {
          content: `${apiKey.owner.first_name} ${apiKey.owner.last_name}`,
        },
        {
          content: apiKey.owner.email,
        },
        {
          content: apiKey.owner.company.name,
        },
        {
          content: apiKey.scopes.map((scope: string) => {
            return `${scope} `
          }),
        },
        {
          content: dateFormatter(apiKey.created_at),
          key: apiKey.created_at,
        },
      ],
    }
  })

  return (
    <div>
      <PageHeader>Project API keys</PageHeader>

      <DynamicTable
        head={apiKeysHead}
        rows={apiKeysCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching}
        isFixedSize
        defaultSortKey="created_at"
        defaultSortOrder="DESC"
        emptyView={<h2>No API keys found for this project.</h2>}
      />
      <Restricted to="api-key:create" type="projects">
        <h3>Create new API key</h3>
        <form onSubmit={onSubmitNewKey}>
          <Select
            isClearable
            isMulti={true}
            placeholder="Add scopes"
            onChange={handleSelectChange}
            options={initialSelectOptions}
          />
          <br />
          {errorMessage && (
            <>
              <InlineMessage type="error" secondaryText={errorMessage} />
              <br />
              <br />
            </>
          )}
          <SubmitButton appearance="primary" type="submit">
            Create key
          </SubmitButton>
          <br />
          <br />
        </form>
      </Restricted>
      {/* Modal to show user their API key */}
      <ModalTransition>
        {isOpen && (
          <Modal actions={[{ text: 'Got it', onClick: close }]} onClose={close} heading="Your new API key">
            <p>The following key is unrecoverable, so keep it safe.</p>
            <br />
            <Code language="text" text={createdApiKey} />
            <br />
            <p>
              We've published and released the first version of our API. The Laava Connect API allows you to integrate
              and connect your Smart Fingerprints with your other business systems using API keys.
            </p>
            <a href="https://connect.laava.id" target="_blank" rel="noopener noreferrer">
              Learn More
            </a>
          </Modal>
        )}
      </ModalTransition>
    </div>
  )
}

export default connect(mapStateToProps)(ProjectApiKeys)
