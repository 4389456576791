import React from 'react'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'

interface ErrorMessageProps {
  title: string
  description: string
  goBackLink?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ title, description, goBackLink }) => {
  return (
    <SectionMessage
      title={title}
      appearance="error"
      actions={goBackLink ? <SectionMessageAction href={goBackLink}>Go back</SectionMessageAction> : undefined}
    >
      <p>{description}</p>
    </SectionMessage>
  )
}

export default ErrorMessage
