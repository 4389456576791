import uuid from 'react-uuid'
import {
  REQUEST_TAG_METADATA_UPLOAD,
  TAG_METADATA_UPLOAD_ERROR,
  TAG_METADATA_UPLOAD_SUCCESS,
  TAG_METADATA_DOWNLOAD_ADD,
  TAG_METADATA_DOWNLOADS_UPDATE,
} from './actions'

const initialTagMetadataState = {
  uploads: {
    isUploading: false,
    uploadSuccess: false,
    uploadError: false,
  },
  downloads: { items: [] },
}

export const tagMetadata = (state = initialTagMetadataState, action: any) => {
  switch (action.type) {
    case REQUEST_TAG_METADATA_UPLOAD:
      return Object.assign({}, state, {
        ...state,
        uploads: {
          isUploading: true,
          uploadSuccess: false,
          uploadError: false,
        },
      })
    case TAG_METADATA_UPLOAD_ERROR:
      return Object.assign({}, state, {
        ...state,
        uploads: {
          isUploading: false,
          uploadSuccess: false,
          uploadError: true,
        },
      })
    case TAG_METADATA_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        uploads: {
          isUploading: false,
          uploadSuccess: true,
          uploadError: false,
        },
      })
    case TAG_METADATA_DOWNLOAD_ADD:
      const metadataDownloadWithId = Object.assign(
        {
          id: uuid(),
        },
        action.newMetadataDownload,
      )

      return {
        ...state,
        downloads: {
          items: [...state.downloads.items, metadataDownloadWithId],
        },
      }
    case TAG_METADATA_DOWNLOADS_UPDATE:
      return {
        ...state,
        downloads: {
          items: action.metadataDownloads,
        },
      }
    default:
      return state
  }
}
