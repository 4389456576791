import React from 'react'
import { Container, Row } from 'react-grid-system'

import { LandingRightCol, LandingLeftCol } from '../../components/styled/Column'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'

const NotFound: React.FC = () => {
  return (
    <>
      <Container fluid style={{ width: '100vw', padding: 'unset' }}>
        <Row nowrap={true}>
          <LandingRightCol>
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto' }} alt="Laava Manage" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ textAlign: 'center' }}>Page not found</h1>
            <br />
            <br />
            <br />
            <Row style={{ textAlign: 'center', justifyContent: 'center' }}>
              <p>
                It looks like you're trying to access a page that doesn't exist.
                <br />
                <br />
                <a href="/">Go back</a>
              </p>
            </Row>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ height: '100vh', minHeight: '720px' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </>
  )
}

export default NotFound
