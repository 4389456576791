import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useInterval from 'use-interval'

import Lozenge from '@atlaskit/lozenge'
import PageHeader from '@atlaskit/page-header'
import DynamicTable from '@atlaskit/dynamic-table'

import { Project, State, TagOrder, Collection } from '../../types'
import { dateFormatter } from '../../helpers'
import NewTagOrderModal from '../../components/react/modals/new-tag-order'

import { fetchCollectionsIfNeeded, collectionsInvalidate } from '../../state/collections/actions'
import { fetchTagOrdersIfNeeded, tagOrdersInvalidate } from '../../state/tag-orders/actions'
import { createTagOrder } from '../../state/tag-orders/actions'
import { Restricted } from '../../providers/permission'
import { fetchFingerprintLimitForProject } from '../../state/projects/actions'

interface Props {
  user: any,
  isFetching: boolean
  collection: Collection
  project: Project
  tagOrders: TagOrder[]
  dispatch?: any
}

const tagOrdersHead = {
  cells: [
    {
      content: 'Order date',
      key: 'createdAt',
      isSortable: true,
      height: 36,
    },
    {
      content: 'Number of Fingerprints',
    },
    {
      content: 'Status',
    }
  ],
}

const mapStateToProps = (state: State, ownProps: any) => {
  const collection = state.collections.items.filter(
    (collection: Collection) => collection.id === ownProps.match.params.collectionId,
  )[0]
  const project = state.projects.items.filter((project: Project) => project.id === ownProps.match.params.projectId)[0]
  const tagOrders = state.tagOrders.items.filter(
    (tagOrder: TagOrder) => tagOrder.collectionId === ownProps.match.params.collectionId,
  )

  return {
    isFetching: state.collections.isFetching || state.tagOrders.isFetching,
    collection,
    project,
    tagOrders,
    user: state.account.login.user,
  }
}

const CollectionFingerprints: React.FC<Props> = ({
  user,
  isFetching,
  collection,
  project,
  tagOrders,
  dispatch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  useInterval(
    () => {
      dispatch(tagOrdersInvalidate())
      dispatch(collectionsInvalidate())
    },
    // our useInterval will only execute if any of our tagOrders have started or are processing
    tagOrders.some((tagOrder: TagOrder) => tagOrder?.status === 'STARTED' || tagOrder?.status === 'PROCESSING') &&
      !isModalOpen
      ? 10000
      : null,
  )
  useEffect(() => {
    dispatch(fetchFingerprintLimitForProject(project))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(fetchCollectionsIfNeeded(project.id))
    dispatch(fetchTagOrdersIfNeeded(project.id))
  })

  const tagOrdersCells = tagOrders.map((tagOrder: TagOrder) => {
    let statusLozengeAppearance
    let formattedDate = dateFormatter(tagOrder.createdAt)
    switch (tagOrder.status) {
      case 'COMPLETED':
        statusLozengeAppearance = 'success'
        break
      case 'PROCESSING':
        statusLozengeAppearance = 'inprogress'
        break
      default:
        statusLozengeAppearance = 'default'
    }

    return {
      cells: [
        {
          content: formattedDate,
          key: tagOrder.createdAt,
          height: 36,
        },
        {
          content: tagOrder.numberOfTags,
          key: tagOrder.numberOfTags,
        },
        {
          content: <Lozenge appearance={statusLozengeAppearance as any}>{tagOrder.status}</Lozenge>,
        }
      ],
    }
  })

  interface OnSubmitNewTagOrderProps {
    numberOfTags: number
  }

  const onSubmitNewTagOrder = ({ numberOfTags }: OnSubmitNewTagOrderProps) => {
    const collectionId = collection.id
    const projectId = project.id

    dispatch(
      createTagOrder({
        numberOfTags,
        projectId,
        collectionId,
      }),
    )
  }

  const actions = (
    <Restricted to="tag-order:create" type="projects">
      {user.system_role > 1 && <NewTagOrderModal
        key={'new-tag-order-button'}
        project={project}
        onSubmit={onSubmitNewTagOrder}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isDisabled={isFetching}
      />}
    </Restricted>
  )

  return (
    <>
      <PageHeader key={'page-header'} actions={actions}>
        Smart Fingerprint Orders
      </PageHeader>

      <DynamicTable
        head={tagOrdersHead}
        rows={tagOrdersCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching}
        isFixedSize
        defaultSortKey="createdAt"
        defaultSortOrder="DESC"
        emptyView={<h2>This collection is empty.</h2>}
        key={'tag-orders-table'}
      />
    </>
  )
}

export default connect(mapStateToProps)(CollectionFingerprints)
