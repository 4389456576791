import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { chunk } from 'lodash'
import { Row, Col, Container } from 'react-grid-system'
import _ from 'lodash'

import { Main } from '@atlaskit/page-layout'
import PageHeader from '@atlaskit/page-header'
import Spinner from '@atlaskit/spinner'
import Button, { ButtonGroup } from '@atlaskit/button'
import Pagination from '@atlaskit/pagination'
import { DatePicker } from '@atlaskit/datetime-picker'
import Select from '@atlaskit/select'
import Tooltip from '@atlaskit/tooltip'
import { Checkbox } from '@atlaskit/checkbox'
import Textfield from '@atlaskit/textfield'
import { MdOutlinePhotoSizeSelectActual, MdOutlinePhotoLibrary } from 'react-icons/md'

import { ScanEventVerbose, State } from '../../types'
import { fetchScanEvents } from '../../state/scan-events/actions'
import { fetchProjects } from '../../state/projects/actions'
import SingleView from './single-view'
import SessionView from './session-view'
import ClientIconComponent from '../../components/styled/ClientIcon'

const Wrapper = styled.div`
  padding: 8px 40px;
`

const Col300 = styled(Col)`
  width: 300px !important;
`

const SpinnerContainer = styled.div`
  width: 10%;
  margin: auto;
  text-align: center;
`
const ButtonGroupStyled = styled(ButtonGroup)`
  width: 100%;
`

interface Props {
  scanEvents: ScanEventVerbose[]
  scanEventsFetching: boolean
  dispatch: any
}

const mapStateToProps = (state: State) => {
  const scanEvents = state.scanEvents.items.map((scanEvent) => {
    scanEvent.project = state.projects.items?.filter((project) => project.id === scanEvent.projectId)[0]
    return scanEvent as ScanEventVerbose
  })
  return {
    scanEventsFetching: state.scanEvents.isFetching,
    scanEvents,
  }
}

const Ted: React.FC<Props> = ({ scanEventsFetching, scanEvents, dispatch }) => {
  const [rows, setRows] = useState<any[]>([])
  const [pages, setPages] = useState<any[]>([])
  const [selectedPage, setSelectedPage] = useState<number>(0)
  const [numberOfPagesArray, setNumberOfPagesArray] = useState<number[]>([])
  const [scanEventsFiltered, setScanEventsFiltered] = useState<ScanEventVerbose[]>([])
  const [dateAfterFilter, setDateAfterFilter] = useState<string>(
    new Date(Date.now() + -1 * 24 * 3600 * 1000).toISOString(),
  ) // 1 day ago in milliseconds UTC
  const [dateBeforeFilter, setDateBeforeFilter] = useState<string>()
  const [scanStatusFilters, setScanStatusFilters] = useState<any>({
    DETECT_FAILED: true,
    MATCH_FAILED: true,
    SUCCESS: false,
  })
  const [scannerTypeFilters, setScannerTypeFilters] = useState<any>({
    WECHAT: true,
    WEBSCANNER: true,
    WHATSAPP: true,
    NATIVESCANNER_IOS: true,
    NATIVESCANNER_ANDROID: true,
    OTHER: true,
  })
  const [companyFilters, setCompanyFilters] = useState<string[]>([])
  const [tagIdFilter, setTagIdFilter] = useState<string>()
  const [dotsBinaryFilter, setDotsBinaryFilter] = useState<string>()
  const [sortOption, setSortOption] = useState('newest')
  const [singleViewSelected, setSingleViewSelected] = useState<boolean>(true)
  const [sessionViewSelected, setSessionViewSelected] = useState<boolean>(false)

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  useEffect(() => {
    if (!scanEventsFetching) {
      let scanEventsToSet = scanEvents
      scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) => scanStatusFilters[scanEvent.scanStatus])

      scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) => {
        const scannerType = scanEvent.clientAgent
        if (scannerType === null || scannerType === undefined) {
          return scannerTypeFilters.OTHER
        }
        return scannerTypeFilters[scannerType]
      })

      if (companyFilters.length > 0) {
        scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) =>
          companyFilters.includes(scanEvent.project?.company.name),
        )
      }

      if (tagIdFilter) {
        scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) => tagIdFilter === scanEvent.tagId)
      }

      if (dotsBinaryFilter) {
        scanEventsToSet = scanEventsToSet.filter((scanEvent: ScanEventVerbose) =>
          scanEvent.detectData?.some((detectData) => detectData.binary === dotsBinaryFilter),
        )
      }
      scanEventsToSet = scanEventsToSet.sort((first: any, next: any) => {
        if (sortOption === 'newest') {
          return new Date(next.createdAt).getTime() - new Date(first.createdAt).getTime()
        } else {
          return new Date(first.createdAt).getTime() - new Date(next.createdAt).getTime()
        }
      })
      singleViewSelected && setScanEventsFiltered(scanEventsToSet)
      sessionViewSelected &&
        setScanEventsFiltered(
          Object.values(
            scanEventsToSet.reduce((previous: any, current) => {
              ;(previous[current.clientSessionId] = previous[current.clientSessionId] || []).push(current)
              return previous
            }, {}),
          ),
        )
    }
  }, [
    scanEvents,
    scanStatusFilters,
    companyFilters,
    sortOption,
    tagIdFilter,
    dotsBinaryFilter,
    scannerTypeFilters,
    singleViewSelected,
    sessionViewSelected,
    scanEventsFetching,
  ])

  useEffect(() => {
    setRows(chunk(scanEventsFiltered, 4))
  }, [scanEventsFiltered])

  useEffect(() => {
    // Calculate pages from rows (number of columns)
    setPages(chunk(rows, 6))
  }, [rows])

  useEffect(() => {
    let numberArray = []
    for (let index = 0; index < pages.length; index++) {
      numberArray.push(index + 1)
    }
    setNumberOfPagesArray(numberArray)
  }, [pages])

  useEffect(() => {
    dispatch(
      fetchScanEvents({
        createdAfter: dateAfterFilter,
        createdBefore: dateBeforeFilter,
        include: 'detectData,matchData,scanData',
        // include: 'scanData',
      }),
    )
  }, [dateAfterFilter, dateBeforeFilter, dispatch])

  return (
    <Main>
      <Wrapper>
        <PageHeader key={'page-header'}>Tag Error Dashboard</PageHeader>
        <br />

        <Container fluid style={{ display: 'flex' }}>
          <Container>
            <Row>
              <Col300>
                <ButtonGroupStyled>
                  <Button
                    onClick={() => {
                      setSingleViewSelected(true)
                      setSessionViewSelected(false)
                    }}
                    isSelected={singleViewSelected}
                    iconAfter={<MdOutlinePhotoSizeSelectActual size={18} />}
                  >
                    <p style={{ fontSize: '12px' }}>SINGLE VIEW</p>
                  </Button>

                  <Button
                    onClick={() => {
                      setSingleViewSelected(false)
                      setSessionViewSelected(true)
                    }}
                    isSelected={sessionViewSelected}
                    iconAfter={<MdOutlinePhotoLibrary size={18} />}
                  >
                    <p style={{ fontSize: '12px' }}>SESSION VIEW</p>
                  </Button>
                </ButtonGroupStyled>
                <h3>Filters</h3>
                <h4>Status</h4>
                <Checkbox
                  isChecked={scanStatusFilters.SUCCESS}
                  onChange={() => {
                    setScanStatusFilters({ ...scanStatusFilters, SUCCESS: !scanStatusFilters.SUCCESS })
                  }}
                  label="SUCCESS"
                />
                <Checkbox
                  isChecked={scanStatusFilters.DETECT_FAILED}
                  onChange={() => {
                    setScanStatusFilters({ ...scanStatusFilters, DETECT_FAILED: !scanStatusFilters.DETECT_FAILED })
                  }}
                  label="FAILED DETECT"
                />
                <Checkbox
                  isChecked={scanStatusFilters.MATCH_FAILED}
                  onChange={() => {
                    setScanStatusFilters({ ...scanStatusFilters, MATCH_FAILED: !scanStatusFilters.MATCH_FAILED })
                  }}
                  label="FAILED MATCH"
                />
                <h4>Date</h4>
                <Tooltip
                  hideTooltipOnClick={true}
                  position={'right'}
                  content={
                    'Scan events 60 days or older will not appear here. Contact Laava engineers if you need access to older scan events.'
                  }
                >
                  <DatePicker
                    id="datepickerAfter"
                    placeholder="Scanned After"
                    value={dateAfterFilter}
                    onChange={(value: any) => {
                      !value
                        ? setDateAfterFilter(new Date(Date.now() + -60 * 24 * 3600 * 1000).toISOString())
                        : setDateAfterFilter(value)
                    }}
                    minDate={new Date(Date.now() + -60 * 24 * 3600 * 1000).toISOString()}
                  />
                </Tooltip>
                <DatePicker id="datepickerBefore" placeholder="Scanned Before" onChange={setDateBeforeFilter} />
                <h4>Order</h4>
                <Select
                  options={[
                    { label: 'Newest', value: 'newest' },
                    { label: 'Oldest', value: 'oldest' },
                  ]}
                  defaultValue={{ label: 'Newest', value: 'newest' }}
                  onChange={(inputValue: any) => {
                    setSortOption(inputValue.value)
                  }}
                />
                <h4>Tag ID</h4>
                <Textfield
                  name="tagId"
                  label="Tag ID"
                  value={tagIdFilter}
                  onChange={(event) => {
                    setTagIdFilter(event.currentTarget.value)
                  }}
                />

                <h4>Company</h4>
                <Select
                  isMulti={true}
                  options={_.uniqBy(scanEvents, 'project.company.name').map((scanEvent) => {
                    if (scanEvent.project) {
                      return { label: scanEvent.project.company.name, value: scanEvent.project.company.name }
                    } else {
                      return { label: 'Unknown' }
                    }
                  })}
                  onChange={(inputValue) => {
                    inputValue ? setCompanyFilters(inputValue.map((input: any) => input.value)) : setCompanyFilters([])
                  }}
                  placeholder="Company"
                />

                <h4>Scanner Type</h4>
                <Checkbox
                  isChecked={scannerTypeFilters.WECHAT}
                  onChange={() => {
                    setScannerTypeFilters({ ...scannerTypeFilters, WECHAT: !scannerTypeFilters.WECHAT })
                  }}
                  label={
                    <>
                      <ClientIconComponent clientType="WECHAT" /> Wechat
                    </>
                  }
                />
                <Checkbox
                  isChecked={scannerTypeFilters.WEBSCANNER}
                  onChange={() => {
                    setScannerTypeFilters({ ...scannerTypeFilters, WEBSCANNER: !scannerTypeFilters.WEBSCANNER })
                  }}
                  label={
                    <>
                      <ClientIconComponent clientType="WEBSCANNER" /> Web
                    </>
                  }
                />
                <Checkbox
                  isChecked={scannerTypeFilters.WHATSAPP}
                  onChange={() => {
                    setScannerTypeFilters({ ...scannerTypeFilters, WHATSAPP: !scannerTypeFilters.WHATSAPP })
                  }}
                  label={
                    <>
                      <ClientIconComponent clientType="WHATSAPP" /> WhatsApp
                    </>
                  }
                />
                <Checkbox
                  isChecked={scannerTypeFilters.NATIVESCANNER_ANDROID}
                  onChange={() => {
                    setScannerTypeFilters({
                      ...scannerTypeFilters,
                      NATIVESCANNER_ANDROID: !scannerTypeFilters.NATIVESCANNER_ANDROID,
                    })
                  }}
                  label={
                    <>
                      <ClientIconComponent clientType="NATIVESCANNER_ANDROID" /> Android
                    </>
                  }
                />
                <Checkbox
                  isChecked={scannerTypeFilters.NATIVESCANNER_IOS}
                  onChange={() => {
                    setScannerTypeFilters({
                      ...scannerTypeFilters,
                      NATIVESCANNER_IOS: !scannerTypeFilters.NATIVESCANNER_IOS,
                    })
                  }}
                  label={
                    <>
                      <ClientIconComponent clientType="NATIVESCANNER_IOS" /> iOS
                    </>
                  }
                />
                <Checkbox
                  isChecked={scannerTypeFilters.OTHER}
                  onChange={() => {
                    setScannerTypeFilters({ ...scannerTypeFilters, OTHER: !scannerTypeFilters.OTHER })
                  }}
                  label={
                    <>
                      <ClientIconComponent clientType="OTHER" /> Other
                    </>
                  }
                />

                <h4>Dots Binary</h4>
                <Textfield
                  name="dotsBinary"
                  label="Dots Binary"
                  value={dotsBinaryFilter}
                  onChange={(event) => {
                    setDotsBinaryFilter(event.currentTarget.value)
                  }}
                />
              </Col300>
            </Row>
          </Container>
          <Container style={{ height: '85vh', overflow: 'auto', minWidth: '300px', flex: '1 1 auto' }}>
            {scanEventsFetching && (
              <SpinnerContainer>
                <Spinner size="xlarge" />
              </SpinnerContainer>
            )}
            {!(rows.length > 0) && !scanEventsFetching && (
              <h2 style={{ textAlign: 'center' }}>No scan events found.</h2>
            )}
            {singleViewSelected && <SingleView pages={pages} selectedPage={selectedPage} key={'single-view'} />}
            {sessionViewSelected && <SessionView pages={pages} selectedPage={selectedPage} key={'session-view'} />}
            <Pagination
              defaultSelectedIndex={0}
              selectedIndex={selectedPage}
              onChange={(event, page) => {
                setSelectedPage(page - 1)
              }}
              innerStyles={{ bottom: 0, justifyContent: 'center', position: 'sticky', backgroundColor: 'white' }}
              pages={numberOfPagesArray}
            />
          </Container>
        </Container>
      </Wrapper>
    </Main>
  )
}

export default connect(mapStateToProps)(Ted)
