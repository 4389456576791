import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'

import DynamicTable from '@atlaskit/dynamic-table'
import { Checkbox } from '@atlaskit/checkbox'
import PageHeader from '@atlaskit/page-header'
import { Main } from '@atlaskit/page-layout'
import ProgressBar from '@atlaskit/progress-bar'

import { User, Company, Invitation, State } from '../../types'
import { dateFormatter } from '../../helpers'
import {
  fetchCompaniesIfNeeded,
  fetchInvitationsIfNeeded,
  createCompany,
  createInvitation,
  updateCompany,
} from '../../state/account/actions'
import InviteCompanyUserModal from '../../components/react/modals/invite-company-user-modal'
import NewCompanyModal from '../../components/react/modals/new-company-modal'
import ManageCompanyModal from '../../components/react/modals/manage-company-modal'
import { OrangeButton } from '../../components/styled/Button'
import { PermissionProvider, Restricted } from '../../providers/permission'

const Wrapper = styled.div`
  margin: auto;
  margin-bottom: 50px;
  max-width: 1080px;
  min-width: 720px;
`

const EmailButton = styled(OrangeButton)`
  margin: 0;
`

const usersHead = {
  cells: [
    {
      content: 'Name',
      shouldTruncate: true,
    },
    {
      content: 'Email',
      shouldTruncate: true,
    },
    {
      content: 'Email verified?',
      shouldTruncate: true,
    },
    {
      content: 'Date registered',
      key: 'created_at',
      isSortable: true,
    },
  ],
}

const companiesHead = {
  cells: [
    {
      content: 'Name',
      shouldTruncate: true,
      isSortable: true,
      key: 'name',
    },
    {
      content: 'Website',
      shouldTruncate: true,
    },
    {
      content: 'Description',
      shouldTruncate: true,
    },
    {
      content: 'Max Fingerprints',
    },
    {
      content: 'Date registered',
      key: 'created_at',
      isSortable: true,
    },
    {
      content: 'Actions',
      shouldTruncate: true,
    },
  ],
}

const invitationsHead = {
  cells: [
    {
      content: 'E-mail',
      shouldTruncate: true,
    },
    {
      content: 'Invitation Type',
      shouldTruncate: true,
    },
    {
      content: 'Company',
      shouldTruncate: true,
    },
    {
      content: 'Date created',
      shouldTruncate: true,
      key: 'created_at',
      isSortable: true,
    },
    {
      content: 'Is active?',
      shouldTruncate: true,
    },
  ],
}

const mapInvitationTypeText = (invitationType: string) => {
  switch (invitationType) {
    case 'VERIFY_ACCOUNT':
      return 'Verify account'
    case 'INVITE_COMPANY_USER':
      return 'Invite to company'
    case 'RESET_PASSWORD':
      return 'Password reset'
    case 'PROJECT:NEW_EXTERNAL_USER:USER':
      return 'Invited to project'
    case 'MIGRATE':
      return 'Password reset'
    default:
      return invitationType
  }
}

interface Props {
  isFetching: boolean
  company: Company
  companies?: Company[]
  user: User
  invitations?: Invitation[]
  isFetchingCompanies: boolean
  isFetchingInvitations: boolean
  dispatch: any
}

const mapStateToProps = (state: State) => {
  const company = state.account.company.items?.filter(
    (company: Company) => company.id === state.account.login.user.company_id,
  )[0]
  return {
    user: state.account.login.user,
    company,
    companies: state.account.company?.items,
    invitations: state.account.invitations?.items,
    isFetchingCompanies: state.account.company.isFetching,
    isFetchingInvitations:  state.account.invitations.isFetching
  }
}

const Admin: React.FC<Props> = ({ user, company, companies, invitations, isFetchingCompanies, isFetchingInvitations, dispatch }) => {
  const userIsInCompany = user.company_id ? true : false
  
  useEffect(() => {
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchInvitationsIfNeeded())
  }, [dispatch, companies, invitations])

  const onSubmitNewCompany = (data: {name: string, domain: string, description: string, maxFingerprints: number}) => {
    dispatch(createCompany(data))
  }

  const onSubmitCompanyUpdate = (data: {companyId: number, maxFingerprints: number}) => {
    dispatch(updateCompany(data))
  }

  const onSubmitCompanyUserInvite = (data: {email: string, companyId?: number}) => {
    const companyId = data?.companyId || user.company_id
    dispatch(
      createInvitation({
        email: data.email,
        companyId,
        type: 'INVITE_COMPANY_USER'
      }),
    )
  }

  const usersCells = company?.users.map((user: User) => {
    return {
      cells: [
        {
          content: user.first_name,
        },
        {
          content: user.email,
        },
        {
          content: <Checkbox isChecked={user.is_verified} />,
        },
        {
          content: dateFormatter(user.created_at),
          key: user.created_at,
        },
      ],
    }
  })

  const invitationsCells = invitations?.map((invitation: Invitation) => {
    return {
      cells: [
        {
          content: invitation.email,
        },
        {
          content: mapInvitationTypeText(invitation.type),
        },
        {
          content: invitation.company?.name,
        },
        {
          content: dateFormatter(invitation.created_at),
          key: invitation.created_at,
        },
        {
          content: <Checkbox isChecked={invitation.is_active} />,
        },
      ],
    }
  })

  const companiesCells = companies?.map((company: Company) => {
    const companyInvitations = invitations?.filter(
      (invitation: Invitation) => invitation?.company_id === company.id,
    )
    return {
      cells: [
        {
          content: company.name,
          key: company.name,
        },
        {
          content: company.domain,
        },
        {
          content: company.description,
        },
        {
          content: company.max_fingerprints,
          key: company.max_fingerprints,
        },
        {
          content: dateFormatter(company.created_at),
          key: company.created_at,
        },
        {
          content: <ManageCompanyModal company={company} companyInvitations={companyInvitations} onSubmitCompanyInvite={onSubmitCompanyUserInvite} onSubmitCompanyUpdate={onSubmitCompanyUpdate} />,
        },
      ],
    }
  })

  return (
    <Main>
      <Wrapper>
        <PageHeader>Administration | {company?.name}</PageHeader>
        {userIsInCompany ? (
          <PermissionProvider>
            <Row align='center' style={{marginBottom: '20px'}}>
              <Col md={3}>
                <h3>Fingerprints Available</h3>
              </Col>
              <Col md={5}>
                <p>You have {Number(company?.total_fingerprint_count).toLocaleString()} active Fingerprints out of a maximum of {Number(company?.max_fingerprints).toLocaleString()}</p>
              </Col>
              <Col md={4}>
                <ProgressBar value={company?.total_fingerprint_count/company?.max_fingerprints} />
              </Col>
            </Row>

            <Row style={{marginBottom: '10px', alignItems: 'center'}}>
              <Col>
                <h3>Users</h3>
              </Col>
              <Col style={{ width: 'max-content', alignSelf: 'center', flex: 'unset' }}>
                <InviteCompanyUserModal onSubmit={onSubmitCompanyUserInvite} />
              </Col>
            </Row>

            <DynamicTable
              head={usersHead}
              rows={usersCells}
              rowsPerPage={15}
              defaultPage={1}
              loadingSpinnerSize="large"
              isLoading={isFetchingCompanies}
              defaultSortKey="created_at"
              defaultSortOrder="DESC"
            />
            <Row style={{marginBottom: '10px', alignItems: 'center'}}>
              <Col>
                <h3>Invitations</h3>
              </Col>
            </Row>
            <DynamicTable
              head={invitationsHead}
              rows={invitationsCells}
              rowsPerPage={15}
              defaultPage={1}
              loadingSpinnerSize="large"
              defaultSortKey="created_at"
              defaultSortOrder="DESC"
              isLoading={isFetchingInvitations}
              emptyView={<h4>No invitations found.</h4>}
            />

            <Restricted to="company:create" type="system">
              <Row style={{marginBottom: '10px', alignItems: 'center'}}>
                <Col>
                  <h3>Companies</h3>
                </Col>
                <Col style={{ width: 'max-content', alignSelf: 'center', flex: 'unset' }}>
                  <NewCompanyModal onSubmit={onSubmitNewCompany} />
                </Col>
              </Row>

              <DynamicTable
                head={companiesHead}
                rows={companiesCells}
                rowsPerPage={15}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={isFetchingCompanies}
                defaultSortKey="created_at"
                defaultSortOrder="DESC"
              />
            </Restricted>
          </PermissionProvider>
        ) : (
          <>
            <p>It looks like you aren't part of a team yet. Get in touch with us to help you get started.</p>
            <br />
            <EmailButton appearance="primary" href={'/need-help'}>
              Get in touch
            </EmailButton>
          </>
        )}
      </Wrapper>
    </Main>
  )
}

export default connect(mapStateToProps)(Admin)
