import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import axios from 'axios'
import ReactJson from 'react-json-view'
import { Link } from 'react-router-dom'

import Modal, { ModalTransition, ModalFooter, ModalBody, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import SectionMessage from '@atlaskit/section-message'
import ClientIconComponent from '../../components/styled/ClientIcon'

import { ScanEventVerbose } from '../../types'

interface ScanEventBoxProps {
  isSuccess: boolean
}

const ScanEventBox = styled.div<ScanEventBoxProps & { width?: number }>`
  // Add width to the type here
  position: relative;
  padding: 5px;
  text-align: center;
  border: 2px solid ${(props) => (props.isSuccess ? '#a4c639' : 'grey')};
  border-radius: 4px;
  background-color: ${(props) => (props.isSuccess ? '#efffef' : 'white')};
  :hover {
    opacity: 50%;
  }
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')}; // Use the width prop or default to auto
`

const ClientIconWrapper = styled.div<{ size?: number }>`
  position: absolute;
  top: 8%;
  left: 10%;
  // padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  // Add a pseudo-element for the circular background
  &::before {
    content: '';
    position: absolute;
    width: ${(props) => (props.size ? `${props.size + 16}px` : '50px')}; // Adjust size as needed
    height: ${(props) => (props.size ? `${props.size + 16}px` : '50px')}; // Adjust size as needed
    background: rgba(0, 0, 0, 0.1); // Semi-transparent white background
    border-radius: 50%; // Make it circular
    backdrop-filter: blur(10px); // Apply blur effect
    z-index: 10; // Ensure it's behind the icon
  }

  // Ensure the icon is positioned above the pseudo-element
  & > img {
    position: relative;
    z-index: 11;
  }
`

const ScanEventImage = styled.img`
  height: 200px;
  width: 200px;
  object-fit: cover;
  // display: block;
  margin: auto;
  border-radius: 4px;
`

interface Props {
  pages: any
  selectedPage: any
}

const SingleView: React.FC<Props> = ({ pages, selectedPage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeScanEvent, setActiveScanEvent] = useState<ScanEventVerbose>()
  const [activeScanEventPristine, setActiveScanEventPristine] = useState<string>('')
  const [activeCollectionName, setActiveCollectionName] = useState<string>()

  function closeModal() {
    setActiveScanEventPristine('')
    setActiveScanEvent(undefined)
    setIsOpen(false)
  }

  function openModalWithScanEvent(newActiveScanEvent: ScanEventVerbose) {
    if (newActiveScanEvent?.tagId && newActiveScanEvent.projectId && newActiveScanEvent.collectionId) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: newActiveScanEvent.projectId,
      }

      axios
        .get(`${process.env.REACT_APP_TMAPI_BASE_URL}/tags/${newActiveScanEvent.tagId}/png?size=200`, {
          headers,
          responseType: 'blob',
        })
        .then((response: any) => {
          const imgUrl = URL.createObjectURL(response.data)
          setActiveScanEventPristine(imgUrl)
        })

      axios
        .get(`${process.env.REACT_APP_TMAPI_BASE_URL}/collections/${newActiveScanEvent.collectionId}`, {
          headers,
        })
        .then((response: any) => {
          setActiveCollectionName(response.data.name)
        })
    }
    setActiveScanEvent(newActiveScanEvent)
    setIsOpen(true)
  }

  return (
    <>
      <Row justify="start" key={'row'}>
        <>
          {pages[selectedPage]?.map((cols: any, rowIndex: number) =>
            cols.map((scanEvent: ScanEventVerbose, colIndex: number) => (
              <Col key={`asset-col-${rowIndex}-${colIndex}`}>
                <ScanEventBox
                  isSuccess={scanEvent.scanStatus === 'SUCCESS'}
                  onClick={() => openModalWithScanEvent(scanEvent)}
                  width={204} // Pass the desired width here
                >
                  {/* Add this wrapper and icon component */}
                  <ClientIconWrapper size={22}>
                    <ClientIconComponent clientType={scanEvent.clientAgent} size={20} />
                  </ClientIconWrapper>
                  <ScanEventImage
                    key={`asset-image-${rowIndex}-${colIndex}`}
                    src={
                      scanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]?.image_in_url ||
                      `${process.env.PUBLIC_URL}/qr-code.svg` // Fallback to placeholder image
                    }
                    alt="Scan Event"
                  />
                  <br />
                  {scanEvent.scanStatus === 'SUCCESS' && (
                    <Lozenge appearance="success" isBold>
                      Matched
                    </Lozenge>
                  )}
                  {scanEvent.scanStatus === 'SUCCESS' ? (
                    <>
                      {/* {' '}
                      <Lozenge
                        appearance={
                          scanEvent.matchData[0]?.results.filter((matchResult: any) =>
                            matchResult.name?.startsWith('dots'),
                          ).length > 0
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        DOTS
                      </Lozenge>{' '}
                      <Lozenge
                        appearance={
                          scanEvent.matchData[0]?.results.filter((matchResult: any) =>
                            matchResult.name?.startsWith('links'),
                          ).length > 0
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        LINKS
                      </Lozenge>{' '}
                      <Lozenge
                        appearance={
                          scanEvent.matchData[0]?.results.filter((matchResult: any) =>
                            matchResult.name?.startsWith('goldberg'),
                          ).length > 0
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        GOLDBERG
                      </Lozenge>{' '}
                      <Lozenge
                        appearance={
                          scanEvent?.thirdPartyScanResponse?.nanoprint?.response?.status === 'success'
                            ? 'success'
                            : 'removed'
                        }
                        isBold
                      >
                        NANOPRINT
                      </Lozenge> */}
                    </>
                  ) : scanEvent.scanStatus === 'DETECT_FAILED' ? (
                    <>
                      <Lozenge appearance="moved">DETECT FAILED</Lozenge>
                    </>
                  ) : (
                    <>
                      <Lozenge appearance="removed">MATCH FAILED</Lozenge>
                    </>
                  )}
                </ScanEventBox>
                <br />
              </Col>
            )),
          )}
        </>
      </Row>

      <ModalTransition>
        {isOpen && activeScanEvent && (
          <Modal onClose={closeModal} width={1200} autoFocus={false}>
            <ModalHeader>
              <ModalTitle>Scan Details</ModalTitle>
            </ModalHeader>

            <ModalBody>
              <Row>
                <Col md={6}>
                  <br />
                  <ScanEventBox key={`activeScanEvent-input-box`} isSuccess={activeScanEvent?.scanStatus === 'SUCCESS'}>
                    <h3>Scanned Image</h3>
                    <br />
                    {activeScanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0] ? (
                      <ScanEventImage
                        style={{ height: '500px', width: '500px' }}
                        key={`activeScanEvent-input-box-image`}
                        src={
                          activeScanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]
                            .image_in_url
                        }
                        onClick={() =>
                          window.open(
                            activeScanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]
                              .image_in_url,
                            '_blank',
                          )
                        }
                      />
                    ) : (
                      <Lozenge>N/A</Lozenge>
                    )}
                    <br />
                    {activeScanEvent.scanStatus === 'SUCCESS' && <Lozenge appearance="success">MATCHED</Lozenge>}
                    {activeScanEvent.scanStatus === 'MATCH_FAILED' && (
                      <Lozenge appearance="removed">MATCH FAILED</Lozenge>
                    )}
                    {activeScanEvent.scanStatus === 'DETECT_FAILED' && (
                      <Lozenge appearance="moved">DETECT FAILED</Lozenge>
                    )}
                  </ScanEventBox>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <h3>Detected Fingerprint</h3>
                      {activeScanEvent.detectData?.map((detectData) =>
                        detectData.image_out_url ? (
                          <ScanEventBox
                            key={`activeScanEvent-detected-box`}
                            isSuccess={activeScanEvent?.scanStatus === 'SUCCESS'}
                          >
                            <ScanEventImage
                              key={`detected-fingerprint-${detectData.id}`}
                              src={detectData.image_out_url}
                              onClick={() => window.open(detectData.image_out_url, '_blank')}
                            />
                          </ScanEventBox>
                        ) : (
                          <Lozenge>N/A</Lozenge>
                        ),
                      )}
                    </Col>
                    <Col sm={6}>
                      <h3>Matched Fingerprint</h3>
                      {activeScanEvent.tagId && activeScanEventPristine ? (
                        <ScanEventImage
                          key={`matched-fingerprint-${activeScanEvent.id}`}
                          src={activeScanEventPristine}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <h3>All Details</h3>
                  <SectionMessage>
                    <p>
                      <b> Date: </b>
                      {new Date(activeScanEvent.createdAt).toLocaleString('en-AU')}
                      <br />
                      <b> Location: </b>
                      {activeScanEvent.city}, {activeScanEvent.country} (
                      {activeScanEvent.geolocationEnabled ? 'GPS' : 'IP'})
                      <br />
                      <b> Scanner Type: </b>
                      {activeScanEvent.clientAgent}
                    </p>

                    <p>
                      <b> Project: </b>
                      {activeScanEvent?.project ? (
                        <Link to={`/projects/${activeScanEvent.project.id}/collections`} target="_blank">
                          {activeScanEvent.project.name}
                        </Link>
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                      <br />
                      <b> Collection: </b>
                      {activeScanEvent?.projectId && activeScanEvent.collectionId ? (
                        <Link
                          to={`/projects/${activeScanEvent.projectId}/collections/${activeScanEvent.collectionId}/fingerprints`}
                          target="_blank"
                        >
                          {activeCollectionName || activeScanEvent.collectionId}
                        </Link>
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                      <br />
                      <b> Company: </b>
                      {activeScanEvent?.project ? activeScanEvent.project.company.name : <Lozenge>N/A</Lozenge>}
                    </p>
                    <p>
                      <b> Tag Metadata: </b>
                      {activeScanEvent?.tagMetadata ? (
                        <ReactJson
                          iconStyle="triangle"
                          displayObjectSize={false}
                          collapsed={0}
                          displayDataTypes={false}
                          src={activeScanEvent?.tagMetadata || {}}
                          quotesOnKeys={false}
                          name={null}
                          enableClipboard={(copy: any) => {
                            navigator.clipboard.writeText(JSON.stringify(activeScanEvent.tagMetadata))
                          }}
                          style={{ fontSize: '12px' }}
                          indentWidth={1}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                      <br />
                      <b> Hook Data: </b>
                      {activeScanEvent?.hookData ? (
                        <ReactJson
                          iconStyle="triangle"
                          displayObjectSize={false}
                          collapsed={1}
                          displayDataTypes={false}
                          src={activeScanEvent?.hookData || {}}
                          quotesOnKeys={false}
                          name={null}
                          enableClipboard={(copy: any) => {
                            navigator.clipboard.writeText(JSON.stringify(activeScanEvent.hookData))
                          }}
                          style={{ fontSize: '12px' }}
                          indentWidth={1}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                      <br />
                      <b> Third-party Scan Data: </b>
                      {activeScanEvent?.thirdPartyScanResponse ? (
                        <ReactJson
                          iconStyle="triangle"
                          displayObjectSize={false}
                          collapsed={1}
                          displayDataTypes={false}
                          src={activeScanEvent?.thirdPartyScanResponse || {}}
                          quotesOnKeys={false}
                          name={null}
                          enableClipboard={(copy: any) => {
                            navigator.clipboard.writeText(JSON.stringify(activeScanEvent.thirdPartyScanResponse))
                          }}
                          style={{ fontSize: '12px' }}
                          indentWidth={1}
                        />
                      ) : (
                        <Lozenge>N/A</Lozenge>
                      )}
                    </p>
                  </SectionMessage>
                  <br />
                  <ReactJson
                    iconStyle="triangle"
                    displayObjectSize={false}
                    collapsed={1}
                    displayDataTypes={false}
                    src={activeScanEvent || {}}
                    quotesOnKeys={false}
                    name={null}
                    enableClipboard={(copy: any) => {
                      navigator.clipboard.writeText(JSON.stringify(copy.src))
                    }}
                    style={{ fontSize: '12px' }}
                    indentWidth={1}
                  />
                  <h3>Match Details</h3>
                  <ReactJson
                    iconStyle="triangle"
                    displayObjectSize={false}
                    collapsed={2}
                    displayDataTypes={false}
                    src={activeScanEvent.matchData[0]}
                    quotesOnKeys={false}
                    name={null}
                    enableClipboard={(copy: any) => {
                      navigator.clipboard.writeText(JSON.stringify(copy.src))
                    }}
                    style={{ fontSize: '12px' }}
                    indentWidth={1}
                  />
                  <h3>Detect Details</h3>
                  <ReactJson
                    iconStyle="triangle"
                    displayObjectSize={false}
                    collapsed={1}
                    displayDataTypes={false}
                    src={{ ...activeScanEvent.detectData }}
                    quotesOnKeys={false}
                    name={null}
                    enableClipboard={(copy: any) => {
                      navigator.clipboard.writeText(JSON.stringify(copy.src))
                    }}
                    style={{ fontSize: '12px' }}
                    indentWidth={1}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button appearance="default" onClick={closeModal} autoFocus={false}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default SingleView
