import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'

import { Content, TopNavigation } from '@atlaskit/page-layout'

import { State } from '../../../types'
import NavBar from '../nav-bar'
import { systemRoleCanAccess, SystemRoles } from '../../accounts/roles'

interface Props {
  component?: any
  roleRequired?: SystemRoles
  loggedIn?: boolean
  isVerified?: boolean
  userRole?: any
  accessTokenExpiry?: string
  refreshTokenExpiry?: string
  onboarded?: any
  exact?: boolean
  path: any
  dispatch: any
}

const mapStateToProps = (state: State) => {
  const loggedIn = state.account.login.loggedIn
  const isVerified = state.account.login.user?.is_verified || state.account.register.verifyEmailSuccess || false
  const userRole = state.account.login.user?.role?.name // Based on user's system role
  const accessTokenExpiry = state.account.login.tokens?.access?.expiry
  const refreshTokenExpiry = state.account.login.tokens?.refresh?.expiry
  const onboarded = state.account.login.user?.first_name

  return {
    loggedIn,
    isVerified,
    userRole,
    accessTokenExpiry,
    refreshTokenExpiry,
    onboarded,
  }
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  roleRequired,
  loggedIn,
  isVerified,
  userRole,
  accessTokenExpiry,
  refreshTokenExpiry,
  onboarded,
  dispatch,
  ...rest
}) => {
  const canAccess = roleRequired ? systemRoleCanAccess(userRole, roleRequired) : true // Some routes will have required roles to access them
  const loggedInWithToken =
    (localStorage.getItem('access_token') != null || localStorage.getItem('refresh_token') != null) && loggedIn

  const accessTokenExpired = moment(accessTokenExpiry).isBefore(moment()) || false
  const refreshTokenExpired = moment(refreshTokenExpiry).isBefore(moment()) || false
  const tokensExpired = accessTokenExpired === true && refreshTokenExpired === true

  const accessTokenExpiredButNoRefresh = accessTokenExpired === true && localStorage.getItem('refresh_token') == null

  return (
    <Route
      {...rest}
      render={(props) =>
        // TODO: Make this nicer
        tokensExpired || accessTokenExpiredButNoRefresh || !loggedInWithToken ? (
          <Redirect to={{ pathname: '/logout' }} />
        ) : loggedInWithToken && isVerified && canAccess && onboarded && !tokensExpired ? (
          <>
            <TopNavigation height={56}>
              <NavBar userRole={userRole} />
            </TopNavigation>
            <Content>
              <Component {...props} />
            </Content>
          </>
        ) : loggedInWithToken && isVerified && !onboarded ? (
          <Redirect to={{ pathname: '/onboarding', state: { from: props.location } }} />
        ) : loggedInWithToken && isVerified && !canAccess ? (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ) : loggedInWithToken && !isVerified ? (
          <Redirect to={{ pathname: '/verification-sent', state: { from: props.location } }} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default connect(mapStateToProps)(PrivateRoute)
