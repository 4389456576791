import _ from 'lodash'
import { Tag } from '../types'
import short from 'short-uuid'

const findMetadataKeys = (data: { tagMetadata: { data: {} }[] }[]) => {
  let keys: string[] = []

  data.forEach((tag: { tagMetadata: { data: {} }[] }) => {
    if (tag.tagMetadata && tag.tagMetadata[0] && tag.tagMetadata[0].data) {
      const objectKeys = Object.keys(tag.tagMetadata[0].data)
      objectKeys.forEach((key) => {
        if (keys.indexOf(key) === -1) {
          keys.push(key)
        }
      })
    }
  })
  return keys
}

export const getCsvMetadataStringWithoutSequence = (tags: Tag[]): string => {
  let csvString = 'tag_id'

  const keys = findMetadataKeys(tags)
  keys.forEach((key) => {
    csvString += `,"${key}"`
  })
  csvString += `\n`

  tags.forEach((tag: Tag) => {
    csvString += `${tag.id}`

    keys.forEach((key) => {
      if (tag.tagMetadata && tag.tagMetadata[0] && tag.tagMetadata[0].data && tag.tagMetadata[0].data[key]) {
        csvString += `,"${tag.tagMetadata[0].data[key]}"`
      } else {
        csvString += `,`
      }
    })
    csvString += `\n`
  })

  return csvString
}

export const getCsvMetadataString = (tags: Tag[]): string => {
  let csvString = 'tag_id,sequence_number,short_url'

  tags.sort((a: { sequenceNumber: number }, b: { sequenceNumber: number }) => {
    if (a.sequenceNumber < b.sequenceNumber) {
      return -1
    } else if (a.sequenceNumber > b.sequenceNumber) {
      return 1
    } else {
      return 0
    }
  })

  const keys = findMetadataKeys(tags)
  keys.forEach((key) => {
    csvString += `,"${key}"`
  })
  csvString += `\n`

  tags.forEach((tag: Tag) => {
    const translator = short('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    const shortId = translator.fromUUID(tag.id)
    const shortUrl = `https://s.laava.id/t/${shortId}/`

    csvString += `${tag.id},${tag.sequenceNumber},${shortUrl}`

    keys.forEach((key) => {
      if (tag.tagMetadata && tag.tagMetadata[0] && tag.tagMetadata[0].data && tag.tagMetadata[0].data[key]) {
        csvString += `,"${tag.tagMetadata[0].data[key]}"`
      } else {
        csvString += `,`
      }
    })
    csvString += `\n`
  })

  return csvString
}

export const getCsvMetadataRangeString = (tags: Tag[]) => {
  let csvString = 'tag_index_start,tag_index_end,tag_count'

  tags.sort((a: { sequenceNumber: number }, b: { sequenceNumber: number }) => {
    if (a.sequenceNumber < b.sequenceNumber) {
      return -1
    } else if (a.sequenceNumber > b.sequenceNumber) {
      return 1
    } else {
      return 0
    }
  })

  const keys = findMetadataKeys(tags)
  keys.forEach((key) => {
    csvString += `,"${key}"`
  })
  csvString += `\n`

  let tagCount = 1
  let tagStartIndex = tags[0].sequenceNumber - 1

  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i]
    const nextTag = tags[i + 1]

    if (!nextTag || !_.isEqual(tag?.tagMetadata[0]?.data, nextTag?.tagMetadata[0]?.data)) {
      csvString += `${tagStartIndex},${tag.sequenceNumber - 1},${tagCount}`

      // eslint-disable-next-line no-loop-func
      keys.forEach((key) => {
        if (tag.tagMetadata && tag.tagMetadata[0] && tag.tagMetadata[0].data && tag.tagMetadata[0].data[key]) {
          csvString += `,"${tag.tagMetadata[0].data[key]}"`
        } else {
          csvString += `,`
        }
      })

      csvString += `\n`
      tagCount = 1
      tagStartIndex = nextTag?.sequenceNumber - 1
    } else {
      tagCount += 1
      continue
    }
  }

  return csvString
}
