// Load the CSV file as a string
export const loadCsv = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.onload = async function (e) {
        const csvString = e.target?.result
        csvString ? resolve(String(csvString)) : reject()
      }
      reader.readAsText(file)
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

// Converts CSV to JSON
export const parseCsv = (str: any, delim = ','): { [key: string]: any } => {
  const headers = str.slice(0, str.indexOf('\n')).split(delim)
  const rows = str.slice(str.indexOf('\n') + 1).split('\n')

  const json = rows.map((row: string) => {
    const values = row.split(delim)
    const eachObject = headers.reduce((obj: any, header: string | number, i: number) => {
      obj[header] = values[i]
      return obj
    }, {})
    return eachObject
  })

  return json
}
