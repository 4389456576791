import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { ButtonGroup } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import DynamicTable from '@atlaskit/dynamic-table'
import InlineMessage from '@atlaskit/inline-message'

import { State, Project } from '../../types'
import { dateFormatter } from '../../helpers'
import { fetchViewsIfNeeded, createView } from '../../state/content/actions'
import NewViewModal from '../../components/react/modals/new-view-modal'
import { Restricted } from '../../providers/permission'

interface Props {
  user: any,
  isFetching?: boolean
  views?: any
  project: Project
  dispatch: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]

  return {
    isFetching: state.projects.isFetching || state.content.views?.isFetching,
    views: state.content.views.items,
    user: state.account.login.user,
    project,
  }
}

const ProjectViews: React.FC<Props> = ({ user, isFetching, project, views, dispatch }) => {
  useEffect(() => {
    dispatch(fetchViewsIfNeeded(project?.id))
  })

  interface OnSubmitNewViewProps {
    name: string
    description: string
  }

  const onSubmitNewView = ({ name, description }: OnSubmitNewViewProps) => {
    const newViewDefaults: any = {
      product_image_url: 'https://via.placeholder.com/500?text=PRODUCT+IMAGE',
      product_image_asset: null,
      logo_image_url: 'https://via.placeholder.com/180x50?text=LOGO',
      logo_image_asset: null,
      display_state: 'State',
      display_details: 'Details',
      display_description: 'Description',
      style: { icon_background_colour: '#00b021', background_colour: '#ffffff', product_image_background_colour: '#ffffff', arrow_colour: '#000000', details_colour: '#000000', description_colour: '#000000', state_colour: '#000000', product_image_scale_mode: 'cover' },
      content: {
        fonts: [
          {
            name: 'Inter',
            italic: false,
            weight: 400,
          },
          {
            name: 'Inter',
            italic: false,
            weight: 600,
          },
        ],
        blocks: [
          {
            data: {
              content: [
                {
                  data: {
                    text: 'Welcome to Laava!',
                    color: '#000000',
                    style: 'heading',
                  },
                  id: '9c57d75d-e3ee-41bb-9ad1-53bf03326753',
                  type: 'text',
                },
                {
                  data: {
                    text: 'This is your placeholder result view',
                    color: '#000000',
                    style: 'leadParagraph',
                  },
                  id: '24dc008d-07f4-4014-8c83-aa0009ae8ee0',
                  type: 'text',
                },
                {
                  data: {
                    text: 'Use the tabs on the right to add new content items.',
                    color: '#000000',
                    style: 'paragraph',
                  },
                  id: '54c3601e-9ca9-4308-b863-b0309562160c',
                  type: 'text',
                },
                {
                  data: {
                    text: 'Roll over a content items to access buttons to edit, duplicate and delete.',
                    color: '#000000',
                    style: 'paragraph',
                  },
                  id: '51f67031-9e4d-4638-9778-6029f90a15be',
                  type: 'text',
                },
                
              ],
              backgroundColor: '#e0e0e0',
            },
            id: 'ceb1431d-6d1d-4eec-87a0-1eb2e61ea13f',
            type: 'section',
          },
          {
            "id": "f7eb5638-18eb-4e38-955e-66e91befcb44",
            "data": {
                "content": [
                    {
                        "id": "398e7454-4056-4779-bc6e-48ac7f9ec0fe",
                        "data": {
                            "text": "Powered by",
                            "color": "#000000",
                            "style": "heading",
                            "centered": true
                        },
                        "type": "text"
                    },
                    {
                        "id": "38a48811-137e-4bb5-bf89-c09fcab11f35",
                        "data": {
                            "url": "",
                            "asset": {
                                "id": "be5964e8-1c77-463e-aace-541945ef4866",
                                "url": "https://s3-ap-southeast-2.amazonaws.com/production-laava-content-assets-public/small_Laava_aot_logo_Cropped_10fe24e512.png"
                            },
                            "width": "50%",
                            "horizontalPosition": "center"
                        },
                        "type": "image"
                    },
                    {
                        "id": "1284e1d4-3894-4f1d-9373-959e63e733d2",
                        "data": {
                            "text": "Laava Smart Technology",
                            "color": "#000000",
                            "style": "leadParagraph",
                            "centered": true
                        },
                        "type": "text"
                    },
                    {
                        "id": "d07fdf33-ce4f-4bf1-a47c-e3b7f36b466f",
                        "data": {
                            "text": "Advanced digital product ID technology\n*Unclonable Digital Fingerprints\n*AI-Powered Computer Vision\n*Visible, invisible or both",
                            "color": "#000000",
                            "style": "paragraph",
                            "centered": true
                        },
                        "type": "text"
                    },
                    {
                        "id": "77f2e830-8c4d-479b-b1da-8930cd49c9e5",
                        "data": {
                            "text": "LEARN MORE",
                            "color": "#FFFFFF",
                            "style": "button",
                            "rounded": true,
                            "destinationUrl": "https://laava.id",
                            "backgroundColor": "#FF6600",
                            "shouldOpenInNewWindow": false
                        },
                        "type": "button"
                    }
                ],
                "centered": false,
                "removePadding": false,
                "backgroundSize": "cover",
                "backgroundColor": "#F2F2F2",
                "backgroundPosition": "center",
                "requiresAuthentication": false
            },
            "type": "section"
        }
        ],
        styles: {
          heading: {
            font: 'Inter',
            size: '23px',
            weight: 'bold',
            lineHeight: 1.2,
          },
          leadParagraph: {
            font: 'Inter',
            size: '18px',
            weight: 'bold',
            fontStyle: 'italic',
            lineHeight: 1.2,
          },
          paragraph: {
            font: 'Inter',
            size: '14px',
            weight: 'normal',
            lineHeight: 1.2,
          },
          button: {
            font: 'Inter',
            size: '18px',
            weight: 'bold',
            lineHeight: 1.2,
          }
        },
      },
    }

    dispatch(createView({ name, description, project_id: project.id, ...newViewDefaults }))
  }

  const viewsHead = {
    cells: [
      {
        content: 'Name',
        shouldTruncate: true,
        key: 'name',
        isSortable: true,
      },
      {
        content: 'Description',
        key: 'description',
        isSortable: true,
      },
      {
        content: 'Date Created',
        key: 'created_at',
        isSortable: true,
      },
    ],
  }

  const viewsCells = views?.map((view: any, index: any) => {
    return {
      cells: [
        {
          content: <Link to={`/projects/${project.id}/result-views/${view.id}`}>{view.name}</Link>,
          key: view.name,
          height: 36,
        },
        {
          content: `${view.description}`,
          key: view.description,
        },
        {
          content: dateFormatter(view.created_at),
          key: view.created_at,
        },
      ],
    }
  })

  const actions = (
    <ButtonGroup>
      <Restricted to="view:create" type="projects">
        {user.system_role > 1 && <NewViewModal onSubmit={onSubmitNewView} />}
      </Restricted>
    </ButtonGroup>
  )

  return (
    <>
      <PageHeader actions={actions} key={'page-header'}>
        Result Views
      </PageHeader>
      <InlineMessage type="info" secondaryText="What are result views?">
        <p>
          Here you can create and edit result views for your project. You can assign them to any collection in your
          project to appear as a scan result for Laava Smart Fingerprints scanned in that collection.
        </p>
      </InlineMessage>
      <br />
      <br />
      <DynamicTable
        head={viewsHead}
        rows={viewsCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching}
        isFixedSize
        defaultSortKey="created_at"
        defaultSortOrder="DESC"
        emptyView={<h2>No result views found.</h2>}
        key={'views-table'}
      />
    </>
  )
}

export default connect(mapStateToProps)(ProjectViews)
