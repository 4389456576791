import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Container, Row } from 'react-grid-system'
import styled from 'styled-components'

import Form, { Field, ErrorMessage as FormErrorMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { passwordValidator } from '../../helpers'
import { LandingRightCol, LandingLeftCol, FormCol } from '../../components/styled/Column'
import { OrangeButton } from '../../components/styled/Button'
import ErrorMessage from '../../components/react/error-message'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'

const SubmitButton = styled(OrangeButton)`
  margin: auto;
  display: block !important;
`

interface Props {
  token?: string
}

const mapStateToProps = (_state: any, ownProps: any) => {
  const token = ownProps.match.params.token
  return {
    token,
  }
}

const ResetPassword: React.FC<Props> = ({ token }) => {
  const [requestOk, setRequestOk] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const handleSubmit = (values: { password: string }) => {
    const password = values.password
    axios({
      // Activate the invitation
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/${token}/activate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { password },
    })
      .then(() => {
        setRequestOk(true)
      })
      .catch((error) => {
        console.log(error)
        setRequestError(error)
      })
  }
  return (
    <>
      {!token && <Redirect to={{ pathname: '/' }} />}
      <Container fluid>
        <Row nowrap={true}>
          <LandingRightCol>
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto' }} alt="Laava Manage" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ textAlign: 'center' }}>Set a new password</h1>
            <br />
            <br />
            <FormCol>
              {!requestOk && !requestError && (
                <Form onSubmit={handleSubmit}>
                  {({ formProps }) => (
                    <form {...formProps}>
                      <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Field
                          name="password"
                          defaultValue=""
                          label="New password"
                          isRequired
                          validate={passwordValidator}
                        >
                          {({ fieldProps, error }) => (
                            <Fragment>
                              <TextField {...fieldProps} type="password" />
                              {error === 'TOO_SHORT' && (
                                <FormErrorMessage>Must be 8 or more characters</FormErrorMessage>
                              )}
                              {error === 'NO_NUMBERS' && <FormErrorMessage>Must include a number</FormErrorMessage>}
                              {error === 'NO_LETTERS' && <FormErrorMessage>Must include a letter</FormErrorMessage>}
                            </Fragment>
                          )}
                        </Field>
                      </Row>
                      <br />
                      <SubmitButton type="submit" appearance="primary">
                        Submit
                      </SubmitButton>
                      <br />
                      <br />
                      <p style={{ textAlign: 'center' }}>
                        Don't have an account? <a href="https://laava.id/register">Sign up</a> instead.
                      </p>
                    </form>
                  )}
                </Form>
              )}

              {requestOk && !requestError && (
                <p style={{ textAlign: 'center' }}>
                  Your password reset was successful. <a href="/login">Login</a> again with your new password.
                </p>
              )}

              {requestError && (
                <ErrorMessage
                  title={'Error'}
                  description={'Your invitation may have expired, or is no longer valid.'}
                  goBackLink={'/login'}
                />
              )}
            </FormCol>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ width: 'auto', height: '100vh' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </>
  )
}

export default connect(mapStateToProps)(ResetPassword)
