import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactJson from 'react-json-view'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'

import Modal, { ModalTransition, ModalFooter, ModalBody, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'
import Lozenge from '@atlaskit/lozenge'
import SectionMessage from '@atlaskit/section-message'
import { SimpleTag as Tag } from '@atlaskit/tag'

import { ScanEventVerbose } from '../../types'

// New style for scan events..

// const ScanEventCard = styled.div`
//   display: flex;
//   margin-bottom: 10px;
//   padding: 10px;
//   border: 1px solid #e0e0e0;
//   border-radius: 4px;
// `

// const ScanEventCardImage = styled.img`
//   height: 100px;
//   width: 100px;
//   object-fit: cover;
//   margin-right: 10px;
// `

// const ScanEventCardDetails = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-around;
// `

// End new style for scan events...

const ScanSessionBox = styled.div`
  margin-bottom: 10px;
  border: 2px solid #e0e0e0;
  :hover {
    opacity: 50%;
  }
`

const ScanSessionImageBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 223px;
`

const ScanSessionImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: block;
  margin: 1px;
`

const ScanSessionInfoBox = styled.div`
  margin: 0 10px 10px;
  display: flex;
  justify-content: space-between;
`

const ScanEventBox = styled.div`
  border-radius: 4px;
  :hover {
    opacity: 50%;
  }
`

const ScanEventInfoBox = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c0c0c0;
  min-height: 24px;
`

const ScanEventImage = styled.img`
  height: 225px;
  width: 225px;
  object-fit: cover;
  display: block;
`

const ActiveScanEventImage = styled.img`
  height: 160px;
  width: 160px;
`

interface Props {
  pages: any
  selectedPage: any
}

const sortScanEventsByDate = (scanEvents: ScanEventVerbose[]) => {
  return scanEvents.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
}

const SessionView: React.FC<Props> = ({ pages, selectedPage }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeScanSession, setActiveScanSession] = useState<ScanEventVerbose[]>()
  const [activeScanEvent, setActiveScanEvent] = useState<ScanEventVerbose>()
  const [activeScanEventPristine, setActiveScanEventPristine] = useState<string>()
  const [activeCollectionName, setActiveCollectionName] = useState<string>()

  useEffect(() => {
    try {
      if (activeScanEvent?.tagId && activeScanEvent.projectId && activeScanEvent.collectionId) {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          project_id: activeScanEvent.projectId,
        }

        axios
          .get(`${process.env.REACT_APP_TMAPI_BASE_URL}/tags/${activeScanEvent.tagId}/png?size=160`, {
            headers,
            responseType: 'blob',
          })
          .then((response: any) => {
            const imgUrl = URL.createObjectURL(response.data)
            setActiveScanEventPristine(imgUrl)
          })

        axios
          .get(`${process.env.REACT_APP_TMAPI_BASE_URL}/collections/${activeScanEvent.collectionId}`, {
            headers,
          })
          .then((response: any) => {
            setActiveCollectionName(response.data.name)
          })
      }
    } catch (error) {
      console.log(error)
    }
  }, [activeScanEvent])

  function closeModal() {
    setActiveScanEventPristine(undefined)
    setActiveScanSession(undefined)
    setActiveScanEvent(undefined)
    setIsOpen(false)
  }

  function openModalWithScanSession(newActiveScanSession: ScanEventVerbose[]) {
    const sortedScanSession = sortScanEventsByDate(newActiveScanSession)
    setActiveScanSession(sortedScanSession)
    setActiveScanEvent(sortedScanSession[0])
    setIsOpen(true)
  }

  // New list view for scan events
  // const scanEventListView = activeScanSession && (
  //   <div>
  //     {activeScanSession.map((scanEvent: ScanEventVerbose, index) => (
  //       <ScanEventCard key={scanEvent.id}>
  //         <ScanEventCardImage
  //           src={
  //             scanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]?.image_in_url ||
  //             'placeholder-image-url' // Replace with an actual placeholder image URL if necessary
  //           }
  //           alt="Scan Event"
  //         />
  //         <ScanEventCardDetails>
  //           <div>Time: {new Date(scanEvent.createdAt).toLocaleTimeString('en-US')}</div>
  //           <div>Scanner Type: {scanEvent.clientAgent || 'N/A'}</div>
  //           <div>Status: {scanEvent.scanStatus}</div>
  //           {/* Add other details you want to display */}
  //         </ScanEventCardDetails>
  //       </ScanEventCard>
  //     ))}
  //   </div>
  // )

  return (
    <>
      <Row justify="start" key={'row'}>
        <>
          {pages[selectedPage]?.map((cols: any, rowIndex: number) =>
            cols?.map((scanSession: ScanEventVerbose[], colIndex: number) => (
              <Col width={254} key={`asset-col-${rowIndex}-${colIndex}`}>
                <ScanSessionBox
                  key={`asset-box-${rowIndex}-${colIndex}`}
                  onClick={() => openModalWithScanSession(scanSession)}
                >
                  <ScanSessionImageBox>
                    {scanSession?.length > 0 &&
                      scanSession.map(
                        (scanEvent: ScanEventVerbose, index) =>
                          index < 4 && (
                            <ScanSessionImage
                              key={`asset-image-${index}`}
                              src={
                                scanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]
                                  ?.image_in_url
                              }
                            />
                          ),
                      )}
                  </ScanSessionImageBox>

                  <ScanSessionInfoBox>
                    {scanSession?.length > 4 && <p>+ {scanSession.length - 4} more</p>}
                    <div style={{ marginLeft: 'auto' }}>
                      {scanSession?.length > 0 && (
                        <>
                          <Lozenge appearance="success">
                            {scanSession.filter((scanEvent) => scanEvent.scanStatus === 'SUCCESS').length}
                          </Lozenge>
                          <Lozenge appearance="moved">
                            {scanSession.filter((scanEvent) => scanEvent.scanStatus === 'DETECT_FAILED').length}
                          </Lozenge>
                          <Lozenge appearance="removed">
                            {scanSession.filter((scanEvent) => scanEvent.scanStatus === 'MATCH_FAILED').length}
                          </Lozenge>
                        </>
                      )}
                    </div>
                  </ScanSessionInfoBox>
                </ScanSessionBox>
              </Col>
            )),
          )}
        </>
      </Row>

      <ModalTransition>
        {isOpen && activeScanSession && (
          <Modal onClose={closeModal} width={'75%'} autoFocus={false}>
            <ModalHeader>
              <ModalTitle>Scan Session</ModalTitle>
              <a
                href={`${process.env.REACT_APP_AWS_RUM_LOGS_URL}/log-events$3FfilterPattern$3D$257B+$2524.metadata.sessionId+$253D+$2522${activeScanSession[0].clientSessionId}$2522+$257D`}
                rel="noopener noreferrer"
                target="_blank"
              >
                View in AWS RUM
              </a>
            </ModalHeader>

            <ModalBody>
              <Row>
                <Col md={7}>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {/* Render the new list view here, above the ScanSessionImageBoxes */}
                    {/* scanEventListView - In Progress */}

                    {activeScanSession &&
                      activeScanSession.map((scanEvent: ScanEventVerbose, index) => (
                        <ScanEventBox
                          key={`activeScanSession-image-${index}`}
                          style={{
                            marginRight: '15px',
                            marginBottom: '15px',
                            border: activeScanEvent?.id === scanEvent.id ? '2px solid #0052cc' : '',
                          }}
                          onClick={() => setActiveScanEvent(scanEvent)}
                        >
                          <div style={{ display: 'flex' }}>
                            <ScanEventImage
                              src={
                                scanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]
                                  ?.image_in_url
                              }
                            />
                            <div style={{ margin: '10px', position: 'absolute' }}>
                              {scanEvent.scanStatus === 'SUCCESS' && <Lozenge appearance="success">MATCHED</Lozenge>}
                              {scanEvent.scanStatus === 'MATCH_FAILED' && (
                                <Lozenge appearance="removed">MATCH FAILED</Lozenge>
                              )}
                              {scanEvent.scanStatus === 'DETECT_FAILED' && (
                                <Lozenge appearance="moved">DETECT FAILED</Lozenge>
                              )}
                            </div>
                          </div>
                          <ScanEventInfoBox>
                            {new Date(scanEvent.createdAt).toLocaleTimeString('en-US', {
                              hour12: true,
                            })}
                            {index !== 0 && (
                              <Tag
                                text={`+${(
                                  (new Date(scanEvent.createdAt).getTime() -
                                    new Date(activeScanSession[0].createdAt).getTime()) /
                                  1000
                                ).toFixed(0)}s`}
                              />
                            )}
                          </ScanEventInfoBox>
                        </ScanEventBox>
                      ))}
                  </div>
                </Col>
                <Col md={5}>
                  {activeScanEvent && (
                    <>
                      <h3>Selected Scan Event Details</h3>
                      <SectionMessage>
                        <p>
                          <b> Date: </b>
                          {new Date(activeScanEvent.createdAt).toLocaleString('en-AU')}
                          <br />
                          <b> Location: </b>
                          {activeScanEvent.city}, {activeScanEvent.country} (
                          {activeScanEvent.geolocationEnabled ? 'GPS' : 'IP'})
                          <br />
                          <b> Scanner Type: </b>
                          {activeScanEvent.clientAgent}
                        </p>

                        <p>
                          <b> Project: </b>
                          {activeScanEvent?.project ? (
                            <Link to={`/projects/${activeScanEvent.project.id}/collections`} target="_blank">
                              {activeScanEvent.project.name}
                            </Link>
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          )}
                          <br />
                          <b> Collection: </b>
                          {activeScanEvent?.projectId && activeScanEvent.collectionId ? (
                            <Link
                              to={`/projects/${activeScanEvent.projectId}/collections/${activeScanEvent.collectionId}/fingerprints`}
                              target="_blank"
                            >
                              {activeCollectionName || activeScanEvent.collectionId}
                            </Link>
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          )}
                          <br />
                          <b> Company: </b>
                          {activeScanEvent?.project ? activeScanEvent.project.company.name : <Lozenge>N/A</Lozenge>}
                        </p>
                        <p>
                          <b> Tag Metadata: </b>
                          {activeScanEvent?.tagMetadata ? (
                            <ReactJson
                              iconStyle="triangle"
                              displayObjectSize={false}
                              collapsed={0}
                              displayDataTypes={false}
                              src={activeScanEvent?.tagMetadata || {}}
                              quotesOnKeys={false}
                              name={null}
                              enableClipboard={(copy: any) => {
                                navigator.clipboard.writeText(JSON.stringify(activeScanEvent.tagMetadata))
                              }}
                              style={{ fontSize: '12px' }}
                              indentWidth={1}
                            />
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          )}
                          <br />
                          <b> Hook Data: </b>
                          {activeScanEvent?.hookData ? (
                            <ReactJson
                              iconStyle="triangle"
                              displayObjectSize={false}
                              collapsed={1}
                              displayDataTypes={false}
                              src={activeScanEvent?.hookData || {}}
                              quotesOnKeys={false}
                              name={null}
                              enableClipboard={(copy: any) => {
                                navigator.clipboard.writeText(JSON.stringify(activeScanEvent.hookData))
                              }}
                              style={{ fontSize: '12px' }}
                              indentWidth={1}
                            />
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          )}
                          <br />
                          <b> Third-party Scan Data: </b>
                          {activeScanEvent?.thirdPartyScanResponse ? (
                            <ReactJson
                              iconStyle="triangle"
                              displayObjectSize={false}
                              collapsed={1}
                              displayDataTypes={false}
                              src={activeScanEvent?.thirdPartyScanResponse || {}}
                              quotesOnKeys={false}
                              name={null}
                              enableClipboard={(copy: any) => {
                                navigator.clipboard.writeText(JSON.stringify(activeScanEvent.thirdPartyScanResponse))
                              }}
                              style={{ fontSize: '12px' }}
                              indentWidth={1}
                            />
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          )}
                          <br />
                        </p>
                      </SectionMessage>
                      <br />
                      <ScanEventBox key={`activeScanEvent-input-box`}>
                        <h3>Scanned Image</h3>
                        {activeScanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0] ? (
                          <img
                            style={{ width: '100%' }}
                            key={`scan-input-${activeScanEvent.id}`}
                            src={
                              activeScanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]
                                ?.image_in_url
                            }
                            onClick={() =>
                              window.open(
                                activeScanEvent.detectData?.filter((detectData) => detectData.image_in_url !== null)[0]
                                  ?.image_in_url,
                                '_blank',
                              )
                            }
                            alt={'Scan Input'}
                          />
                        ) : (
                          <Lozenge>N/A</Lozenge>
                        )}
                        <br />
                      </ScanEventBox>
                      <br />
                      <Row>
                        <Col sm={6}>
                          <h3>Detected Fingerprint</h3>
                          {activeScanEvent.detectData?.map((detectData) =>
                            detectData.image_out_url ? (
                              <ActiveScanEventImage
                                key={`detected-fingerprint-${detectData.id}`}
                                src={detectData.image_out_url}
                                onClick={() => window.open(detectData.image_out_url, '_blank')}
                              />
                            ) : (
                              <Lozenge>N/A</Lozenge>
                            ),
                          )}
                        </Col>
                        <Col sm={6}>
                          <h3>Matched Fingerprint</h3>
                          {activeScanEvent.tagId && activeScanEventPristine ? (
                            <ActiveScanEventImage
                              key={`matched-fingerprint-${activeScanEvent.id}`}
                              src={activeScanEventPristine}
                            />
                          ) : (
                            <Lozenge>N/A</Lozenge>
                          )}
                        </Col>
                      </Row>
                      <h3>All Details</h3>
                      <ReactJson
                        iconStyle="triangle"
                        displayObjectSize={false}
                        collapsed={0}
                        displayDataTypes={false}
                        src={activeScanEvent || {}}
                        quotesOnKeys={false}
                        name={null}
                        enableClipboard={(copy: any) => {
                          navigator.clipboard.writeText(JSON.stringify(copy.src))
                        }}
                      />
                      <h3>Detect Details</h3>
                      <ReactJson
                        iconStyle="triangle"
                        displayObjectSize={false}
                        collapsed={0}
                        displayDataTypes={false}
                        src={activeScanEvent?.detectData || {}}
                        quotesOnKeys={false}
                        name={null}
                        enableClipboard={(copy: any) => {
                          navigator.clipboard.writeText(JSON.stringify(copy.src))
                        }}
                      />
                      <h3>Match Details</h3>
                      <ReactJson
                        iconStyle="triangle"
                        displayObjectSize={false}
                        collapsed={0}
                        displayDataTypes={false}
                        src={activeScanEvent?.matchData[0]}
                        quotesOnKeys={false}
                        name={null}
                        enableClipboard={(copy: any) => {
                          navigator.clipboard.writeText(JSON.stringify(copy.src))
                        }}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button appearance="default" onClick={closeModal} autoFocus={false}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  )
}

export default SessionView
