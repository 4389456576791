import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Container, Row } from 'react-grid-system'
import styled from 'styled-components'

import TextField from '@atlaskit/textfield'
import Form, { Field, ErrorMessage as FormErrorMessage } from '@atlaskit/form'
import Spinner from '@atlaskit/spinner'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'

import { Invitation, State } from '../../types'
import { LandingRightCol, LandingLeftCol, FormCol } from '../../components/styled/Column'
import { OrangeButton } from '../../components/styled/Button'
import { login } from '../../state/account/actions'
import Logo from '../../assets/logo_laava_manage_alpha.svg'
import OnboardingBanner from '../../assets/onboarding-banner.png'
import ErrorMessage from '../../components/react/error-message'

const LoginButton = styled(OrangeButton)`
  margin: auto;
  display: block !important;
`

const PrefilledSectionMessage: React.FC<any> = ({ invitation }) => {
  let sectionTitle: string
  if (invitation?.project?.name) {
    sectionTitle = `Log in to join the ${invitation?.project?.name} project`
  } else {
    sectionTitle = `Log in to join the ${invitation?.company?.name} company`
  }
  return (
    <SectionMessage
      title={sectionTitle}
      appearance="information"
      actions={<SectionMessageAction href={'/logout'}>Go back</SectionMessageAction>}>
    </SectionMessage>
  )
}

interface Props {
  isLoggingIn?: boolean
  loginError?: boolean
  history?: {
    token?: string
    invitation?: Invitation
    error?: any
  }
  loggedIn?: boolean
  dispatch?: any
}

const mapStateToProps = (state: State, ownProps: any) => {
  const isLoggingIn = state.account.login.isLoggingIn
  const loggedIn = state.account.login.loggedIn
  const loginError = state.account.login.loginError
  return {
    isLoggingIn,
    loggedIn,
    loginError,
    history: ownProps.history.location.state,
  }
}

const Login: React.FC<Props> = ({ isLoggingIn, loggedIn, loginError, history, dispatch }) => {
  const isPrefilled = history?.token ? true : false // Tells us if the user arrived via an invite link
  const redirectError = history?.error ? true : false // Tells us if there was an error from an invite/redirect link

  const handleLogin = (data: Object) => {
    dispatch(login(data))
  }

  // If the user arrived via an invitation link, we activate the activation before logging them in
  const handleCompanyInviteLogin = (data: Object) => {
    axios({
      // Activate the invitation
      method: 'post',
      url: `${process.env.REACT_APP_ACCMAN_URL}/invitations/${history?.token}/activate`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        return handleLogin(data)
      })
      .catch((error) => {
        console.log(error)
        return handleLogin(data)
      })
  }
  return (
    <div>
      {loggedIn && <Redirect to={{ pathname: '/' }} />}
      <Container fluid style={{ width: '100vw', padding: 'unset' }}>
        <Row nowrap={true}>
          <LandingRightCol>
            <img src={Logo} style={{ width: '200px', display: 'block', margin: 'auto', padding: '100px' }} alt="Laava Manage" />
            <h1 style={{ textAlign: 'center', paddingBottom: '60px' }}>Login</h1>
            <FormCol>
              {redirectError && <ErrorMessage title={'Error'} description={'Your invitation may have expired, or is no longer valid.'} />}
              {loginError && (
                <Row style={{ justifyContent: 'center' }}>
                  <FormErrorMessage>
                    Incorrect email or password.
                  </FormErrorMessage>
                </Row>
              )}
              <Form onSubmit={isPrefilled ? handleCompanyInviteLogin : handleLogin}>
                {({ formProps }) => (
                  <form {...formProps}>
                    {isPrefilled ? (
                      <>
                        <PrefilledSectionMessage invitation={history?.invitation}/>
                        {/* If the user arrived via an invitation link, we prefill the email field and disable it. */}
                        <Field name="email" defaultValue={history?.invitation?.email} label="Email" isDisabled>
                          {({ fieldProps }) => (
                            <Fragment>
                              <TextField {...fieldProps} />
                            </Fragment>
                          )}
                        </Field>
                      </>
                    ) : (
                      // Otherwise we render the normal email field.
                      <Field name="email" defaultValue="" label="Email" isRequired>
                        {({ fieldProps }) => (
                          <Fragment>
                            <TextField {...fieldProps} />
                          </Fragment>
                        )}
                      </Field>
                    )}

                    <Field name="password" defaultValue="" label="Password" isRequired>
                      {({ fieldProps }) => (
                        <Fragment>
                          <TextField {...fieldProps} type="password" />
                        </Fragment>
                      )}
                    </Field>

                    <p style={{paddingBottom: '20px'}}>
                      <a href="/forgot-your-password">Forgot your password?</a>
                    </p>

                    <p style={{paddingBottom: '20px'}}>
                      By logging into Laava Manage, you agree to our
                      <a href="https://laava.id/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                        {' '}
                        terms and conditions{' '}
                      </a>
                      and have read and understood the{' '}
                      <a href="https://laava.id/privacy-policy/" target="_blank" rel="noopener noreferrer">
                        privacy policy
                      </a>
                      .
                    </p>

                    <LoginButton appearance="primary" type="submit" style={{marginBottom: '50px'}}>
                      {isLoggingIn && !loginError ? <Spinner appearance="invert" /> : <p>Log in</p>}
                    </LoginButton>

                    <p style={{ textAlign: 'center' }}>
                      Don't have an account? <a href="https://laava.id/register/">Sign up</a> instead.
                    </p>
                  </form>
                )}
              </Form>
            </FormCol>
          </LandingRightCol>
          <LandingLeftCol>
            <img src={OnboardingBanner} style={{ height: '100vh', minHeight: '720px' }} alt="Onboarding Banner" />
          </LandingLeftCol>
        </Row>
      </Container>
    </div>
  )
}

export default connect(mapStateToProps)(Login)
