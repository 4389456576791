import {
  COMPANIES_REQUEST,
  COMPANIES_RECEIVE,
  COMPANIES_INVALIDATE,
  COMPANIES_ERROR,
  INVITATIONS_REQUEST,
  INVITATIONS_RECEIVE,
  INVITATIONS_INVALIDATE,
  INVITATIONS_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REFRESH_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  GET_PROFILE_SUCCESS,
} from './actions'

const initialLoginState: any = {
  isLoggingIn: false,
  loggedIn: false,
  loginError: false,
  tokens: {
    access: {
      token: null,
      expiry: null,
    },
    refresh: {
      token: null,
      expiry: null,
    },
  },
  user: {},
}

const initialRegisterState = {
  registerSuccess: false,
  registerError: false,
  verifyEmailSuccess: false,
  verifyEmailError: false,
}

const initialInvitationsState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

const initialCompanyState = {
  isFetching: false,
  items: [],
  lastFetched: null,
  didInvalidate: false,
}

const initialAccountState = {
  login: initialLoginState,
  company: initialCompanyState,
  register: initialRegisterState,
  invitations: initialInvitationsState,
}

export const account = (state = initialAccountState, action: any) => {
  switch (action.type) {
    // Login
    case LOGIN_REQUEST:
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      return Object.assign({}, state, {
        login: {
          isLoggingIn: true,
          loggedIn: false,
          loginError: false,
        },
      })
    case LOGIN_SUCCESS:
      localStorage.setItem('access_token', action.data.tokens.access.token)
      localStorage.setItem('refresh_token', action.data.tokens.refresh.token)
      return Object.assign({}, state, {
        login: {
          isLoggingIn: false,
          loggedIn: true,
          loginError: false,
          user: action.data.user,
          tokens: action.data.tokens,
        },
      })
    case GET_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        login: {
          user: action.data.user
        },
      })
    case REFRESH_SUCCESS:
      localStorage.setItem('access_token', action.data.tokens.access.token)
      localStorage.setItem('refresh_token', action.data.tokens.refresh.token)
      return Object.assign({}, state, {
        login: {
          ...state.login,
          tokens: action.data.tokens,
        },
      })
    case LOGIN_ERROR:
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      return Object.assign({}, state, {
        login: {
          isLoggingIn: false,
          loggedIn: false,
          loginError: true,
        },
      })
    case LOGOUT:
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      return Object.assign({}, state, { login: initialLoginState })

    // Register
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {
        register: {
          registerSuccess: true,
          registerError: false,
        },
      })
    case REGISTER_ERROR:
      return Object.assign({}, state, {
        register: {
          registerSuccess: false,
          registerError: true,
        },
      })
    case VERIFY_EMAIL_SUCCESS:
      return Object.assign({}, state, {
        register: {
          verifyEmailSuccess: true,
          verifyEmailError: false,
        },
      })
    case VERIFY_EMAIL_ERROR:
      return Object.assign({}, state, {
        register: {
          verifyEmailSuccess: false,
          verifyEmailError: true,
        },
      })

    // Company
    case COMPANIES_REQUEST:
      return Object.assign({}, state, {
        company: {
          isFetching: true,
        },
      })
    case COMPANIES_RECEIVE:
      return Object.assign({}, state, {
        company: {
          isFetching: false,
          items: action.companies,
          lastFetched: Date.now(),
          didInvalidate: false,
        },
      })
    case COMPANIES_INVALIDATE:
      return Object.assign({}, state, {
        company: {
          didInvalidate: true,
        },
      })
    case COMPANIES_ERROR:
      return Object.assign({}, state, {
        company: {
          isFetching: false
        },
      })

    // Invitations
    case INVITATIONS_REQUEST:
      return Object.assign({}, state, {
        invitations: {
          isFetching: true,
        },
      })
    case INVITATIONS_RECEIVE:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false,
          items: action.invitations,
          lastFetched: Date.now(),
          didInvalidate: false,
        },
      })
    case INVITATIONS_INVALIDATE:
      return Object.assign({}, state, {
        invitations: {
          didInvalidate: true,
        },
      })
    case INVITATIONS_ERROR:
      return Object.assign({}, state, {
        invitations: {
          isFetching: false
        },
      })
    default:
      return state
  }
}
