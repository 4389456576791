import React, { useState } from 'react'

import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { OrangeButton } from '../../../styled/Button'

interface NewViewModalProps {
  onSubmit: any
}

const NewViewModal: React.FC<NewViewModalProps> = ({ onSubmit }) => {
  // Modal open/close states
  const [isOpen, setIsOpen] = useState(false)
  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  const onFormSubmit = (data: Object) => {
    onSubmit(data)
    close()
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter style={{ zIndex: 0, position: 'relative' }} showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Create
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div>
      <OrangeButton appearance="primary" onClick={open}>
        Create a Result View
      </OrangeButton>
      <ModalTransition>
        {isOpen && (
          <ModalDialog
            scrollBehavior={'outside'}
            heading="Create a Result View"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onFormSubmit}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: footer,
            }}
          >
            <Field name="name" defaultValue="" label="Result view name" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>

            <Field name="description" defaultValue="" label="Result view description" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  )
}

export default NewViewModal
