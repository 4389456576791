import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Checkbox } from '@atlaskit/checkbox'
import { CheckboxField, Fieldset } from '@atlaskit/form'
import DynamicTable from '@atlaskit/dynamic-table'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Select, { ValueType } from '@atlaskit/select'

import { State, User, Company } from '../../../../types'
import { OrangeButton } from '../../../styled/Button'
import { fetchCompaniesIfNeeded } from '../../../../state/account/actions'
import { PermissionProvider, Restricted } from '../../../../providers/permission'

interface Props {
  user: User
  company: Company
  companies: Company[]
  isFetching: boolean
  onSubmit: any
  dispatch: any
}

const mapStateToProps = (state: State) => {
  const company = state.account.company.items?.filter(
    (company: Company) => company.id === state.account.login.user.company_id,
  )[0]

  return {
    company,
    companies: state.account.company.items,
    user: state.account.login.user,
    isFetching: state.account.company.isFetching,
  }
}

const NewProjectModal: React.FC<Props> = ({ user, company, companies, isFetching, onSubmit, dispatch }) => {
  const [selectedCompany, setSelectedCompany] = useState<Company>(company)
  const [users, setUsers] = useState<any>([])

  const [isOpen, setIsOpen] = useState(false)
  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  const onFormSubmit = (data: { users: { id: number }[], name: string }) => {
    data.users = data.users
      ? data.users.map((userId: any) => {
          return { id: Number(userId) }
        })
      : []
    onSubmit({ name: data.name, users: data.users, companyId: Number(selectedCompany?.id) })
    close()
  }

  useEffect(() => {
    dispatch(fetchCompaniesIfNeeded())
  })

  useEffect(() => {
    setUsers(selectedCompany?.users.filter((companyUser: User) => companyUser.id !== user.id))
  }, [selectedCompany, user])
  
  const handleStatusChange = (newValue: ValueType<{ label: string, value: string }>) => {
    setSelectedCompany(companies.filter(
     (company: Company) => Number(company.id) === Number(newValue?.value)
    )[0])
  }
  const projectUsersHead = {
    cells: [
      {
        content: 'Name',
        shouldTruncate: true,
      },
      {
        content: 'Email',
        shouldTruncate: true,
        key: 'email',
      },
      {
        content: 'Add User to Project',
      },
    ],
  }

  const projectUsersCells = users?.map((user: User) => {
    return {
      cells: [
        {
          content: user.first_name,
        },
        {
          content: user.email,
          key: user.email,
        },
        {
          content: (
            <CheckboxField name="users" value={user.id.toString()} defaultIsChecked>
              {({ fieldProps }) => <Checkbox {...fieldProps} label="" />}
            </CheckboxField>
          ),
        },
      ],
    }
  })

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Create
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <PermissionProvider>
      <OrangeButton appearance="primary" onClick={open}>
        Create a New Project
      </OrangeButton>

      <ModalTransition>
        {isOpen && (
          <ModalDialog
            heading="Create a New Project"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onFormSubmit}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: footer,
            }}
          >
            <Field name="name" label="Project Name" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Restricted>
              <div style={{ marginTop: '10px' }}>
                <div style={{ color: '#6B778C', fontWeight: 600, fontSize: '13px'}}>
                 <label htmlFor="select">Company</label>
                </div>
                <Select
                  inputId="select"
                  options={companies?.map(company => { return {
                      label: company.name,
                      value: company.id.toString()
                    }
                  })}
                  value={{label: selectedCompany?.name, value: selectedCompany?.id.toString()}}
                  onChange={handleStatusChange}
                  maxMenuHeight={150}
                />
              </div>
            </Restricted>
            <br/>
            <div style={{ color: '#6B778C', fontWeight: 600,fontSize: '13px', marginBottom: '-8px' }}>
              Users
            </div>
            <Fieldset>
              <DynamicTable
                head={projectUsersHead}
                rows={projectUsersCells}
                rowsPerPage={15}
                defaultPage={1}
                loadingSpinnerSize="large"
                isLoading={isFetching}
                isFixedSize
                defaultSortKey="email"
                defaultSortOrder="DESC"
                emptyView={<h2>No company users found to add.</h2>}
              />
            </Fieldset>
          </ModalDialog>
        )}
      </ModalTransition>
    </PermissionProvider>
  )
}

export default connect(mapStateToProps)(NewProjectModal)
