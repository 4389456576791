import axios from 'axios'

export const TAGS_REQUEST = 'TAGS_REQUEST'
export const TAGS_RECEIVE = 'TAGS_RECEIVE'
export const TAGS_INVALIDATE = 'TAGS_INVALIDATE'
export const TAGS_ERROR = 'TAGS_ERROR'
export const TAGS_FETCH_PROGRESS = 'TAGS_FETCH_PROGRESS'

const tagsRequest = () => {
  return {
    type: TAGS_REQUEST,
  }
}

const tagsFetchProgress = (percentageComplete: number) => {
  return {
    type: TAGS_FETCH_PROGRESS,
    percentageComplete,
  }
}

const tagsReceive = (collectionId: string, tags: any) => {
  return {
    type: TAGS_RECEIVE,
    collectionId,
    tags,
  }
}

export const tagsInvalidate = () => {
  return {
    type: TAGS_INVALIDATE,
  }
}

export const tagsError = () => {
  return {
    type: TAGS_ERROR,
  }
}

export const fetchTags = (projectId: string, collectionId: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(tagsRequest())
    const collectionResults = await axios.get(`${process.env.REACT_APP_TMAPI_BASE_URL}/collection/${collectionId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        project_id: projectId,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    const { tagCount } = collectionResults.data

    const CONCURRENT_CONNECTIONS = 10
    const DATA_PAGE_SIZE = 10000

    let tagPromises = []
    const tagData: any[] = []

    let completedPromises = 0

    const tagsToProcess = tagCount
    const totalPromiseCount = Math.ceil(tagCount / DATA_PAGE_SIZE)

    for (var i = 0; i < tagsToProcess; i += DATA_PAGE_SIZE * CONCURRENT_CONNECTIONS) {
      tagPromises = []

      for (var c = 0; c < CONCURRENT_CONNECTIONS; c += 1) {
        let offset = i + c * DATA_PAGE_SIZE
        if (offset < tagCount) {
          // totalPromises += 1
          const promise = axios.get(
            `${process.env.REACT_APP_TMAPI_BASE_URL}/tags?collectionIds[]=${collectionId}&fields=id,collectionId,createdAt,updatedAt&offset=${offset}&limit=${DATA_PAGE_SIZE}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                project_id: projectId,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          )
          tagPromises.push(promise)
        }
      }

      // eslint-disable-next-line no-loop-func
      tagPromises.forEach((promise) => {
        promise.then((res: any) => {
          completedPromises += 1
          const percentageComplete = Math.ceil(100 * (completedPromises / totalPromiseCount))
          dispatch(tagsFetchProgress(percentageComplete))
          tagData.push(res)
        })
      })
      await Promise.all(tagPromises)
    }

    const tags = tagData.reduce((arr, value) => {
      return arr.concat(value.data)
    }, [])

    dispatch(tagsReceive(collectionId, tags))
  }
}

const shouldFetchTags = (collectionId: string, state: any): boolean => {
  const lastFetched = state.tags.lastFetched
  const isFetching = state.tags.isFetching

  if (!isFetching) {
    if (!lastFetched || state.tags.collectionId !== collectionId) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const fetchTagsIfNeeded = (projectId: string, collectionId: string) => {
  return (dispatch: any, getState: any) => {
    if (projectId && collectionId && shouldFetchTags(collectionId, getState())) {
      dispatch(fetchTags(projectId, collectionId))
    }
  }
}
