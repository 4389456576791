import React from 'react'
import styled from 'styled-components'
import { Container, Row } from 'react-grid-system'
import { ImSigma } from 'react-icons/im'

const Box = styled.div`
  min-width: 0;
  text-align: center;
  border: 2px solid #dfe1e5;
  border-radius: 3px;
`

interface Props {
  data: Object[]
}

const TotalCountBox: React.FC<Props> = ({ data }) => {
  const totalCount = data.length
  return (
    <Box>
      <Container>
        <Row style={{ display: 'unset' }}>
          <h1>{totalCount}</h1>
        </Row>
        <Row style={{ display: 'unset' }}>
          <h3>
            Total Scan Count <ImSigma />
          </h3>
        </Row>
      </Container>
    </Box>
  )
}

export default TotalCountBox
