import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Main } from '@atlaskit/page-layout'
import PageHeader from '@atlaskit/page-header'
import DynamicTable from '@atlaskit/dynamic-table'
import SearchIcon from '@atlaskit/icon/glyph/search'
import Textfield from '@atlaskit/textfield'

import { OrangeButton } from '../../components/styled/Button'
import { Project, User, State } from '../../types'
import NewProjectModal from '../../components/react/modals/new-project-modal'
import { dateFormatter } from '../../helpers'
import { createProject, fetchProjects } from '../../state/projects/actions'

const Wrapper = styled.div`
  padding: 8px 40px;
`

interface Props {
  user: User
  isFetching: boolean
  projects: Project[]
  dispatch?: any
}

const mapStateToProps = (state: State) => {
  return {
    user: state.account.login.user,
    isFetching: state.projects.isFetching,
    projects: state.projects.items,
  }
}

const Projects: React.FC<Props> = ({ user, isFetching, projects, dispatch }) => {
  const [searchText, setSearchText] = useState<string>('')
  const isCompanyUser = user?.company_id !== null ? true : false // Is the user part of a company?

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  const EmptyView = () => {
    if (isCompanyUser) {
      return <h2>No projects found.</h2>
    } else {
      return (
        <div>
          <h2>You must be part of a team to access projects.</h2>
          <p>Get in touch with us to help you get started.</p>
          <br />
          <OrangeButton appearance="primary" href={'/need-help'}>
            Get in touch
          </OrangeButton>
        </div>
      )
    }
  }

  if (searchText) {
    projects = projects?.filter((project) => project.name.toUpperCase().includes(searchText.toUpperCase()))
  }

  const head = {
    cells: [
      {
        content: 'Name',
        shouldTruncate: true,
        key: 'name',
        isSortable: true,
      },
      {
        content: 'Owner',
        shouldTruncate: true,
        key: 'owner',
        isSortable: true,
      },
      {
        content: 'Date Created',
        key: 'created_at',
        isSortable: true,
      },
    ],
  }
  const cells = projects?.map((project: Project, index: number) => {
    return {
      cells: [
        {
          content: <Link to={`/projects/${project.id}/collections`}>{project.name}</Link>,
          key: project.name,
          height: 36,
        },
        {
          content: project.company.name,
          key: project.company.name,
        },
        {
          content: dateFormatter(project.created_at),
          key: project.created_at,
        },
      ],
    }
  })

  interface NewProjectData {
    name: string
    users: { id: number }[]
    companyId: number
  }

  const onSubmitNewProject = (data: NewProjectData) => {
    dispatch(createProject({ name: data.name, users: data.users, companyId: data.companyId }))
  }

  const bottomBar = (
    <Textfield
      placeholder="Search"
      width="250"
      onChange={(event) => {
        const element = event.currentTarget as HTMLInputElement
        setSearchText(element.value)
      }}
      elemAfterInput={<SearchIcon label="search" />}
    />
  )

  const actions = <>{isCompanyUser && user.system_role > 1 && <NewProjectModal onSubmit={onSubmitNewProject} />}</>

  return (
    <Main>
      <Wrapper>
        <PageHeader actions={actions} bottomBar={bottomBar}>
          Your Projects
        </PageHeader>
        <p style={{ fontWeight: 'lighter' }}>
          Showing {projects?.length} {projects?.length === 1 ? 'project' : 'projects'}
        </p>
        <DynamicTable
          head={head}
          rows={cells}
          rowsPerPage={15}
          defaultPage={1}
          loadingSpinnerSize="large"
          isLoading={isFetching}
          isFixedSize
          defaultSortKey="created_at"
          defaultSortOrder="DESC"
          emptyView={<EmptyView />}
        />
      </Wrapper>
    </Main>
  )
}

export default connect(mapStateToProps)(Projects)
