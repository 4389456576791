import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import { Row, Col } from 'react-grid-system'

import DynamicTable from '@atlaskit/dynamic-table'
import Button, { ButtonGroup } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import InlineMessage from '@atlaskit/inline-message'
import { CreatableSelect } from '@atlaskit/select'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Form, { ErrorMessage } from '@atlaskit/form'
import Select from '@atlaskit/select'
import CrossIcon from '@atlaskit/icon/glyph/cross';
import CheckIcon from '@atlaskit/icon/glyph/check';

import { Restricted } from '../../providers/permission'
import { OrangeButton } from '../../components/styled/Button'
import { Project, User, Company, State, Invitation, Role } from '../../types'
import { isValidEmailAddress, dateFormatter } from '../../helpers'
import { fetchProjectsIfNeeded, addUsersToProject, deleteProjectUser } from '../../state/projects/actions'
import { fetchCompaniesIfNeeded, fetchInvitationsIfNeeded, deleteInvitation } from '../../state/account/actions'
import { Roles } from '../../components/accounts'
import { projectRoleCanAccess } from '../../components/accounts/roles'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import UserProject from '../../types/UserProject'

const SubmitButton = styled(OrangeButton)`
  margin: 0;
`
const StyledTable = styled.table`
  font-size: 12px;

  th,
  td,
  tbody {
    padding: 2px;
    border: 1px solid black;
    border-collapse: collapse;
  }
`

interface Props {
  user: User
  isFetching: {
    projects: boolean
    account: {
      invitations: boolean
    }
  }
  project: Project
  company: Company
  pendingInvitations: Invitation[]
  dispatch: any
}

type SelectValue = {
  label: string
  value: string | number
}

type ErrorMessage = string

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter(
    (project: Project) => project.id === ownProps.match.params.projectId,
  )[0]
  const company = state.account.company.items?.filter((company: Company) => company.id === project.company_id)[0]
  const pendingInvitations = state.account.invitations.items?.filter(
    (invitation: Invitation) =>
      invitation.is_active === true &&
      invitation.type === 'PROJECT:NEW_EXTERNAL_USER:USER' &&
      invitation.project_id === project.id,
  )

  return {
    user: state.account.login.user,
    isFetching: {
      projects: state.projects.isFetching,
      account: {
        invitations: state.account.invitations.isFetching,
      },
    },
    project,
    company,
    pendingInvitations,
  }
}

const createOption = (label: string) => ({
  label,
  value: label,
})

const ProjectUsers: React.FC<Props> = ({ user, isFetching, project, company, pendingInvitations, dispatch }) => {
  // This mapping gets the company users that are not in the project, available to be added
  const companyUsersNotInProject = company?.users.filter(
    (companyUser: User) => !project.userProjects.some((userProject: UserProject) => userProject.user_id === companyUser.id),
  )
  const initialUserOptions =
    companyUsersNotInProject?.map((companyUser: User) => {
      return { label: companyUser.email, value: companyUser.id }
    }) || []
  const userProject = project.userProjects?.filter((userProject) => userProject.user_id === user.id)[0]

  const [userOptions, setUserOptions] = useState<SelectValue[]>(initialUserOptions)
  const [userToAdd, setUserToAdd] = useState<SelectValue>()
  const [roles, setRoles] = useState<Role[]>([])
  const [projectRoleOptions, setProjectRoleOptions] = useState<SelectValue[]>()
  const [projectRoleSelected, setProjectRoleSelected] = useState<SelectValue>()
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>()

  // Delete invitation modal
  const [deleteInvitationModalIsOpen, setDeleteInvitationModalIsOpen] = useState(false)
  const [invitationToDelete, setInvitationToDelete] = useState<any>()
  const openDeleteInvitationModal = (invitation: Invitation) => {
    setInvitationToDelete(invitation)
    setDeleteInvitationModalIsOpen(true)
  }
  const closeDeleteInvitationModal = () => {
    setDeleteInvitationModalIsOpen(false)
  }

  // Delete project user modal
  const [deleteProjectUserModalIsOpen, setDeleteProjectUserModalIsOpen] = useState(false)
  const [projectUserToDelete, setProjectUserToDelete] = useState<any>()
  const openDeleteProjectUserModal = (user: User) => {
    setProjectUserToDelete(user)
    setDeleteProjectUserModalIsOpen(true)
  }
  const closeDeleteProjectUserModal = () => {
    setDeleteProjectUserModalIsOpen(false)
  }

  const handleUserSelectChange = (newValue: any) => {
    setUserToAdd(newValue)
  }

  const handleUserSelectCreate = (inputValue: string) => {
    const newOption = createOption(inputValue)
    setUserToAdd(newOption)
    setUserOptions([newOption, ...userOptions])
  }

  // Invite user to project submission
  const onSubmitUserInvite = (event: any) => {
    event.preventDefault()
    if (!userToAdd) {
      setErrorMessage('Please select a person to invite')
    } else if (!projectRoleSelected) {
      setErrorMessage(`Select a project role for this person`)
    } else if (!isValidEmailAddress(userToAdd.label)) {
      setErrorMessage('Invalid email')
    } else {
      let users: any[]
      if (isNaN(Number(userToAdd.value))) {
        users = [{ email: userToAdd.value, projectRoleId: projectRoleSelected.value }]
      } else {
        // Company user was selected, so we will treat this as an internal user invite
        users = [{ id: parseInt(userToAdd.value as string), projectRoleId: projectRoleSelected.value }]
      }
      dispatch(addUsersToProject({ users, projectId: project.id }))
    }
  }

  // Delete invitation submission
  const onSubmitInvitationDelete = () => {
    dispatch(deleteInvitation({ invitation: invitationToDelete, projectId: project.id }))
    closeDeleteInvitationModal()
  }

  // Delete invitation submission
  const onSubmitDeleteProjectUser = () => {
    dispatch(deleteProjectUser({ user: projectUserToDelete, projectId: project.id }))
    closeDeleteProjectUserModal()
  }

  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
    dispatch(fetchCompaniesIfNeeded())
    dispatch(fetchInvitationsIfNeeded())
  })

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_ACCMAN_URL}/roles`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
      .then((response: { data: Role[] }) => {
        setRoles(response.data)
        setProjectRoleOptions(
          response.data
            .filter(
              (role) => role.type === 'projects' &&
              role?.name !== Roles.ProjectRoles.SUPER_USER &&
              projectRoleCanAccess({ userRole: userProject?.role.name as string, roleRequired: role.name, user })
            )
            .map((role) => {
              return {
                label: Roles.ProjectRolesLabels[role.name],
                value: role.id,
              }
            }),
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }, [project.id, user, userProject])

  const projectUsersHead = {
    cells: [
      {
        content: 'Name',
        shouldTruncate: true,
        width: 2,
      },
      {
        content: 'Company',
        shouldTruncate: true,
        key: 'company',
        isSortable: true,
        width: 2,
      },
      {
        content: 'Email',
        shouldTruncate: true,
        key: 'email',
        width: 4,
      },
      {
        content: 'Role',
        shouldTruncate: true,
        key: 'role',
        width: 2,
      },
      {
        content: '',
        width: 2,
      },
    ],
  }

  const projectUsersCells = project?.userProjects.map((userProject: UserProject) => {
    const userProjectRole = roles.filter(
      (role) =>
        role.id === userProject?.role_id,
    )[0]
    const isCurrentUser = userProject.user_id === user.id
    const currentUserProjectRole = roles.filter(
      (role) => role.id === project?.userProjects.filter((userProject: UserProject) => userProject.user_id === user.id)[0]?.role_id
    )[0]
    return {
      cells: [
        {
          content: <>{userProject?.user?.first_name} {userProject?.user?.last_name} { isCurrentUser && '(You)'}</>,
        },
        {
          content: userProject?.user?.company?.name,
          key: userProject?.user?.company?.name,
        },
        {
          content: userProject?.user?.email,
        },
        {
          content: (
            <p>
              {
                Roles.ProjectRolesLabels[userProjectRole?.name]
              }
            </p>
          ),
        },
        {
          content: (projectRoleCanAccess({ roleRequired: userProjectRole?.name, userRole: currentUserProjectRole?.name, user }) && project?.userProjects.length > 1 &&
          <Restricted to="tag-metadata:update" type="projects">
            <Button
              onClick={() => {
                openDeleteProjectUserModal(userProject.user)
              }}
              appearance="subtle"
              iconBefore={<TrashIcon label="uninvite" />}
              />
              </Restricted>
          ),
        },
      ],
    }
  })

  const pendingHead = {
    cells: [
      {
        content: 'Email',
        shouldTruncate: true,
        key: 'email',
        width: 5,
      },
      {
        content: 'Sent at',
        shouldTruncate: true,
        key: 'created_at',
        isSortable: true,
        width: 5,
      },
      {
        content: '',
        width: 2,
      },
    ],
  }

  const pendingCells = pendingInvitations?.map((invitation: Invitation) => {
    return {
      cells: [
        {
          content: invitation.email,
        },
        {
          content: dateFormatter(invitation.created_at),
          key: invitation.created_at,
        },
        {
          content: (
            <Button
              onClick={() => {
                openDeleteInvitationModal(invitation)
              }}
              appearance="subtle"
              iconBefore={<TrashIcon label="uninvite" />}
            />
          ),
        },
      ],
    }
  })

  const deleteInvitationModalFooter = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {deleteInvitationModalIsOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={closeDeleteInvitationModal}>
              Close
            </Button>
            <SubmitButton appearance="primary" type="submit">
              Cancel invitation
            </SubmitButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  const deleteProjectUserModalFooter = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {deleteProjectUserModalIsOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={closeDeleteProjectUserModal}>
              Close
            </Button>
            <SubmitButton appearance="primary" type="submit">
              Remove Project User
            </SubmitButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div>
      <PageHeader>Project team</PageHeader>

      <DynamicTable
        head={projectUsersHead}
        rows={projectUsersCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching.projects}
        isFixedSize
        defaultSortKey="company"
        defaultSortOrder="ASC"
        emptyView={<h2>This project doesn't have users.</h2>}
      />
      <Restricted to="user:create" type="projects">
        <h3>Add Person to Project</h3>
        <br/>
        <SectionMessage
          title="Adding people"
          appearance="discovery"
          actions={
            <SectionMessageAction>
              <InlineMessage type="info" secondaryText="Role Permissions">
                <StyledTable>
                  <tbody>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Guest</th>
                      <th>Administrator</th>
                    </tr>
                    <tr>
                      <td>Collections</td>
                      <td>Create</td>
                      <td><CrossIcon label="cross" /></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Read</td>
                      <td><CheckIcon label="check"/></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td>Tag Orders</td>
                      <td>Create</td>
                      <td><CrossIcon label="cross" /></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Read</td>
                      <td><CheckIcon label="check"/></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td>Result Views</td>
                      <td>Create</td>
                      <td><CrossIcon label="cross" /></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Read</td>
                      <td><CheckIcon label="check"/></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td>Project Users</td>
                      <td>Create (add)</td>
                      <td><CrossIcon label="cross" /></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Read</td>
                      <td><CheckIcon label="check"/></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td>API Keys</td>
                      <td>Create</td>
                      <td><CrossIcon label="cross" /></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Read</td>
                      <td><CheckIcon label="check"/></td>
                      <td><CheckIcon label="check"/></td>
                    </tr>
                  </tbody>
                </StyledTable>
              </InlineMessage>
            </SectionMessageAction>
          }>
          <p>
            Here you can add users to your project. You may add existing users from your company, or other users via email. Users added via email will be subject to our account onboarding process. 
          </p>
        </SectionMessage>
        <form onSubmit={onSubmitUserInvite}>
          <Row>
            <Col md={6}>
              <CreatableSelect
                isClearable
                onChange={handleUserSelectChange}
                onCreateOption={handleUserSelectCreate}
                options={userOptions}
                value={userToAdd}
                placeholder="Enter an e-mail or select someone from your company"
              />
            </Col>
            <Col md={6}>
              <Select
                options={projectRoleOptions}
                value={projectRoleSelected}
                onChange={async (value) => value && setProjectRoleSelected(value)}
                placeholder="Select a project role for this person"
              />
            </Col>
          </Row>

          <br />
          {errorMessage && (
            <>
              <InlineMessage type="error" secondaryText={errorMessage} />
              <br />
              <br />
            </>
          )}
          <SubmitButton appearance="primary" type="submit">
            Add Person
          </SubmitButton>
          <br />
          <br />
        </form>
      </Restricted>
      <h3>Pending users</h3>
      <br />
      <DynamicTable
        head={pendingHead}
        rows={pendingCells}
        rowsPerPage={15}
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={isFetching.account.invitations}
        isFixedSize
        defaultSortKey="created_at"
        defaultSortOrder="DESC"
        emptyView={<h2>No pending users</h2>}
      />

      <ModalTransition>
        {deleteInvitationModalIsOpen && (
          <ModalDialog
            heading="Cancel invitation"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onSubmitInvitationDelete}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: deleteInvitationModalFooter,
            }}
          >
            <p>
              Are you sure you want to cancel the project invitation for: <b>{invitationToDelete.email}</b>?<br />
              <br />
              <i>The invitation link that was sent to this email will no longer be activatable.</i>
            </p>
          </ModalDialog>
        )}
      </ModalTransition>

      <ModalTransition>
        {deleteProjectUserModalIsOpen && (
          <ModalDialog
            heading="Remove user from project"
            components={{
              Container: ({ children, className }) => (
                <Form onSubmit={onSubmitDeleteProjectUser}>
                  {({ formProps }) => (
                    <form {...formProps} className={className}>
                      {children}
                    </form>
                  )}
                </Form>
              ),
              Footer: deleteProjectUserModalFooter,
            }}
          >
            <p>
              Are you sure you want to remove the user: <b>{projectUserToDelete.name} {projectUserToDelete.email}</b>?<br />
              <br />
              <i>They will be notified and will no longer have access to this project.</i>
            </p>
          </ModalDialog>
        )}
      </ModalTransition>
    </div>
  )
}

export default connect(mapStateToProps)(ProjectUsers)
