import React from 'react'

import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import ModalDialog, { ModalFooter, ModalTransition } from '@atlaskit/modal-dialog'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, ErrorMessage } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'

import { OrangeButton } from '../../../styled/Button'
import { Project } from '../../../../types'

interface NewTagOrderModalProps {
  project: Project
  onSubmit: any
  isOpen: boolean
  setIsOpen: any
  isDisabled: boolean
}

const NewTagOrderModal: React.FC<NewTagOrderModalProps> = ({ project, onSubmit, isOpen, setIsOpen, isDisabled }) => {
  const totalCompanyFingerprints = Number(project.company.total_fingerprint_count)
  const maxCompanyFingerprints = Number(project.company.max_fingerprints)
  const fingerprintsAvailableToOrder = maxCompanyFingerprints - totalCompanyFingerprints

  const open = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  const onFormSubmit = (data: { numberOfTags: number }) => {
    onSubmit(data)
    close()
  }

  const validateNumberOfTags = (value: any) => {
    if (isNaN(value) || Number(value) < 1) {
      return 'NOT_A_NUMBER'
    } else if (Number(value) + totalCompanyFingerprints > maxCompanyFingerprints) {
      return 'LIMIT_REACHED'
    }
  }

  const footer = (props: { showKeyline?: boolean }) => (
    <ModalTransition>
      {isOpen && (
        <ModalFooter showKeyline={props.showKeyline}>
          <span />
          <ButtonGroup>
            <Button appearance="subtle" onClick={close}>
              Cancel
            </Button>
            <OrangeButton appearance="primary" type="submit">
              Order
            </OrangeButton>
          </ButtonGroup>
        </ModalFooter>
      )}
    </ModalTransition>
  )

  return (
    <div key={'new-tag-order-container'}>
      <OrangeButton
        disabled={isDisabled}
        onClick={open}
        appearance="primary"
        iconBefore={<AddCircleIcon primaryColor="white" secondaryColor="#FF5500" label="manage" />}
      >
        Order Smart Fingerprints
      </OrangeButton>
      {isOpen && (
        <ModalTransition>
          <ModalDialog
            heading="Order Smart Fingerprints"
            components={{
              Container: ({ children, className }) => (
                <>
                  <Form onSubmit={onFormSubmit}>
                    {({ formProps }) => (
                      <form {...formProps} className={className}>
                        {children}
                      </form>
                    )}
                  </Form>
                </>
              ),
              Footer: footer,
            }}
          >
            <Field
              name="numberOfTags"
              label="Number of Fingerprints"
              validate={validateNumberOfTags}
              isRequired
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField {...fieldProps} />
                  {error === 'NOT_A_NUMBER' && <ErrorMessage>Not a valid quantity</ErrorMessage>}
                  {error === 'LIMIT_REACHED' && (
                    <ErrorMessage>
                      <p style={{ margin: 'unset' }}>
                        This is over the project company's Laava Smart Fingerprint limit. You may order up to{' '}
                        {fingerprintsAvailableToOrder > 0
                          ? (fingerprintsAvailableToOrder).toLocaleString()
                          : 0}{' '}
                        Fingerprints.
                        <a href={'/need-help'}> Get in touch with us</a> if you require this limit increased.
                      </p>
                    </ErrorMessage>
                  )}
                </>
              )}
            </Field>
          </ModalDialog>
        </ModalTransition>
      )}
    </div>
  )
}

export default NewTagOrderModal
