import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import JSZip from 'jszip'

import { TagStyle, TagData, TagRender, HubImageData } from '@laava/tag-generator'

import Button, { ButtonGroup } from '@atlaskit/button'
import DynamicTable from '@atlaskit/dynamic-table'
import Lozenge from '@atlaskit/lozenge'

import PageHeader from '@atlaskit/page-header'
import ProgressBar from '@atlaskit/progress-bar'

import jsPDF from 'jspdf'
import 'svg2pdf.js'

import {
  addTagDownload,
  updateTagDownloadStatus,
  updateTagDownloadPercentageComplete,
} from '../../state/tag-downloads/actions'

import {
  Asset,
  Project,
  Collection,
  Tag,
  TagOrder,
  State,
  TagDownloadImageFormatOption,
  TagDownloadHubImageOption,
  TagDownloadStatus,
} from '../../types'

import NewTagBatchDownloadModal from '../../components/react/modals/new-tag-batch-download'
import { fetchProjectsIfNeeded } from '../../state/projects/actions'
import { fetchCollectionsIfNeeded, collectionsInvalidate } from '../../state/collections/actions'
import { fetchTagOrdersIfNeeded, tagOrdersInvalidate } from '../../state/tag-orders/actions'
import { getCsvMetadataString } from '../../helpers'

interface Props {
  isFetching?: boolean
  collections?: Collection[]
  tagDownloads?: TagDownload[]
  tagOrders?: TagOrder[]
  tagBatchDownloads?: any[]
  project: Project
  dispatch?: any
  assets: Asset[]
}

interface TagDownload {
  id: string
  fileName: string
  collectionId: string
  projectId: string
  startTagOffset: number
  tagCount: number
  imageFormatOption: TagDownloadImageFormatOption
  hubImageOption: TagDownloadHubImageOption
  pdfTagSize?: number
  pdfTagMargin?: number
  hubImageBase64Data: string
  tagsQueued: number
  tagsComplete: number
  status: string
  archiveNumber: number
  archiveTotal: number
  percentageComplete: number
}

interface TagBatchDownload {
  id: string
  selectedCollections: string[]
  tagImageFormatOption: TagDownloadImageFormatOption
  hubImageOption: TagDownloadHubImageOption
  hubImageBase64Data: string
  numberOfTagsPerArchive: number
  hubImageAssetId: string
  tagCount: number
  tagsComplete: number
}

const mapStateToProps = (state: State, ownProps: any) => {
  const project = state.projects.items.filter((project: Project) => project.id === ownProps.match.params.projectId)[0]
  const assets = state.content.assets.items
  const tagOrders = state.tagOrders.items.filter(
    (tagOrder: TagOrder) => tagOrder.projectId === ownProps.match.params.projectId,
  )

  const tagDownloads: TagDownload[] = state.tagDownloads.items.filter(
    (tagDownload: TagDownload) => tagDownload.projectId === ownProps.match.params.projectId,
  )

  const collections = state.collections.items

  return {
    isFetching: state.projects.isFetching || state.collections.isFetching || state.tagOrders.isFetching,
    collections,
    tagDownloads,
    tagOrders: tagOrders,
    project,
    assets,
  }
}

const ProjectCollections: React.FC<Props> = ({
  isFetching,
  project,
  collections = [],
  tagOrders = [],
  tagDownloads = [],
  tagBatchDownloads = [],
  dispatch,
  assets,
}) => {

  const [isNewTagBatchDownloadModalOpen, setIsNewTagBatchDownloadModalOpen] = useState(false)

  useEffect(() => {
    dispatch(collectionsInvalidate())
    dispatch(tagOrdersInvalidate())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchProjectsIfNeeded())
    dispatch(fetchCollectionsIfNeeded(project?.id))
    dispatch(fetchTagOrdersIfNeeded(project?.id))
  })

  interface ReturnedNewCollectionData {
    collectionName: string
  }

  const getMimeType = (url: string) => {
    const extension = url.split('.').pop()?.toLowerCase()
    const mimeTypes: { [key: string]: string } = {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      // add more types as needed
    }
    return mimeTypes[extension || ''] || 'application/octet-stream'
  }

  const getAssetAsBase64 = async (assetId: string): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const hubImageAsset = assets.filter((item: any) => item.id === assetId);
      const hubImageAssetUrl = hubImageAsset[0].formats.small?.url || hubImageAsset[0].url;
  
      if (hubImageAssetUrl) {
        const response = await axios.get(hubImageAssetUrl, { responseType: 'blob' });
  
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          if (typeof reader.result === 'string') {
            const img = new Image();
            img.src = reader.result;
  
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              const size = Math.max(img.width, img.height);
              canvas.width = size;
              canvas.height = size;
  
              const offsetX = (size - img.width) / 2;
              const offsetY = (size - img.height) / 2;
  
              if (ctx) {
                ctx.drawImage(img, offsetX, offsetY, img.width, img.height);
                const squaredBase64 = canvas.toDataURL();
                const mimeType = getMimeType(hubImageAssetUrl);
                const correctBase64String = squaredBase64.replace('application/octet-stream', mimeType);
                resolve(correctBase64String);
              } else {
                reject(new Error('Could not get 2D context from canvas'));
              }
            };
          } else {
            // Handle the ArrayBuffer case
          }
        };
      } else {
        alert('There was a problem selecting this asset.');
      }
    });
  };

  const getTagData = async (collection: Collection, startTagOffset: number, tagCount: number): Promise<Tag[]> => {
    return new Promise(async (resolve, reject) => {
      const CONCURRENT_CONNECTIONS = 10
      const DATA_PAGE_SIZE = 5000

      let tagPromises = []
      const tagData: any[] = []
      const tagsToProcess = tagCount
      const totalPromiseCount = Math.ceil(tagCount / DATA_PAGE_SIZE)

      for (var i = 0; i < tagsToProcess; i += DATA_PAGE_SIZE * CONCURRENT_CONNECTIONS) {
        tagPromises = []

        for (var c = 0; c < totalPromiseCount; c += 1) {
          let offset = startTagOffset + i + c * DATA_PAGE_SIZE
          let limit = tagCount < DATA_PAGE_SIZE ? tagCount : DATA_PAGE_SIZE

          const promise = axios.get(
            `${process.env.REACT_APP_TMAPI_BASE_URL}/tags?collectionIds[]=${collection.id}&fields=id,collectionId,createdAt,updatedAt&offset=${offset}&limit=${limit}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                project_id: project.id,
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            },
          )
          tagPromises.push(promise)
        }

        tagPromises.forEach((promise) => {
          promise.then((res: any) => {
            tagData.push(res)
          })
        })
        await Promise.all(tagPromises)
      }

      const tags = tagData.reduce((arr, value) => {
        return arr.concat(value.data)
      }, [])

      resolve(tags)
    })
  }

  const getHubImageData = async (hubImageOption: TagDownloadHubImageOption, hubImageBase64Data: string) => {
    const BLANK_HUB_IMAGE_DATA: HubImageData = {
      hubImageDataString: '',
      hubImageDisplayHeight: '348.16',
      hubImageDisplayWidth: '348.16',
      hubImageDisplayX: 337.92,
      hubImageDisplayY: 337.92,
      hubImageHeight: 350,
      hubImageWidth: 350,
    }

    let tagStyle

    switch (hubImageOption) {
      case 'custom-hub-image':
        if (hubImageBase64Data !== '') {
          tagStyle = TagStyle.version('1', hubImageBase64Data)
          const tagData = TagData.generate(tagStyle)
          const imageData = await TagRender.epsHubImageData(tagData)
          return imageData
        }
        break
      case 'laava-hub-image':
        tagStyle = TagStyle.version('1', TagStyle.laavaLogoBase64)
        const tagData = TagData.generate(tagStyle)
        const imageData = await TagRender.epsHubImageData(tagData)
        return imageData
      case 'no-hub-image':
        return BLANK_HUB_IMAGE_DATA
      default:
        return BLANK_HUB_IMAGE_DATA
    }
  }

  const onCollectionsSelected = (data: any) => {
    prepareTagDownloads(data)
  }

  const prepareTagDownloads = async (data: any) => {

    const { imageFormatOption, hubImageOption, tagsPerArchive, hubImageAssetId, selectedCollections } = data

    let hubImageBase64 = ''

    if (hubImageOption === 'custom-hub-image' && hubImageAssetId) {
      hubImageBase64 = await getAssetAsBase64(hubImageAssetId)
    }

    const startTagOffset = 0

    selectedCollections.forEach((collectionId: any) => {
      const collection = collections.filter((collection) => collection.id === collectionId)[0]

      const { tagCount } = collection

      const filenamePrefix = `${collection.name}_${collection.id}_${imageFormatOption}`

      const maxTagsPerArchive = tagsPerArchive || 5000
      const downloadArchives: any = []

      if (!hubImageBase64) hubImageBase64 = ''

      const tagsToGenerate = Math.min(tagCount, collection.tagCount - startTagOffset)
      const numberOfArchives = Math.ceil(tagsToGenerate / maxTagsPerArchive)

      for (var i = 0; i < numberOfArchives; i += 1) {
        const fileName = `${filenamePrefix.split('/').join('_')}_${i + 1}.zip`
        const status: TagDownloadStatus = 'queued'
        const tagsLeftToGenerate = tagsToGenerate - maxTagsPerArchive * i
        const tagCount = tagsLeftToGenerate > maxTagsPerArchive ? maxTagsPerArchive : tagsLeftToGenerate

        if (!downloadArchives[i])
          downloadArchives[i] = {
            fileName,
            collectionId: collection.id,
            projectId: collection.projectId,
            archiveNumber: i + 1,
            archiveTotal: numberOfArchives,
            startTagOffset: startTagOffset + maxTagsPerArchive * i,
            tagCount,
            hubImageOption,
            imageFormatOption,
            hubImageBase64Data: hubImageBase64,
            tagsComplete: 0,
            status,
          }
      }

      downloadArchives.forEach((downloadArchive: any) => {
        dispatch(addTagDownload(downloadArchive))
      })
    })
  }

  const processTagDownloads = async () => {
    const cache = await caches.open('laava-manage-tag-download')

    if (tagDownloads) {
      for (var i = 0; i < tagDownloads.length; i += 1) {
        const {
          id,
          fileName,
          startTagOffset,
          tagCount,
          collectionId,
          tagsComplete,
          hubImageOption,
          imageFormatOption,
          hubImageBase64Data,
          pdfTagSize = 20,
          pdfTagMargin = 5,
        } = tagDownloads[i]

        if (tagsComplete === 0) {
          var zip = new JSZip()

          const hubImageData = await getHubImageData(hubImageOption, hubImageBase64Data)

          const collection = collections.filter((c) => c.id === collectionId)[0]

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'downloading data' }))
          const tags = await getTagData(collection, startTagOffset, tagCount)
          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'generating' }))

          for (var j = 0; j < tags.length; j += 1) {
            const tag = tags[j]

            let tagStyle

            switch (hubImageOption) {
              case 'custom-hub-image':
                tagStyle = TagStyle.version(tag.version, hubImageBase64Data)
                break
              case 'laava-hub-image':
                tagStyle = TagStyle.version(tag.version, TagStyle.laavaLogoBase64)
                break
              case 'no-hub-image':
                tagStyle = TagStyle.version(tag.version, TagStyle.blankLogoBase64)
                break
              default:
                tagStyle = TagStyle.version(tag.version, TagStyle.laavaLogoBase64)
            }

            const tagData = TagData.generate(tagStyle, tag.seed)

            let blob = null
            let tagFileName = ''

            if (imageFormatOption === 'EPS') {
              // render tag as EPS

              const epsTag = await TagRender.eps(tagData, hubImageData)

              blob = new Blob([epsTag], { type: 'application/eps' })
              tagFileName = `${tag.id}.eps`

              // save the files to the cache here
              const response = new Response(blob)
              await cache.put(tagFileName, response)
            } else if (imageFormatOption === 'PNG') {
              // render tag as PNG
              const pngTag = await TagRender.png(tagData)

              // turn the buffer into a file and save it to the cache
              const finalBlob = new Blob([pngTag as BlobPart], { type: 'image/png' })
              const response = new Response(finalBlob)
              tagFileName = `${tag.id}.png`
              await cache.put(tagFileName, response)
            } else if (imageFormatOption === 'PDF') {
              const pdfDocumentSize: number = pdfTagSize + pdfTagMargin * 2

              // Initialize jsPDF instance
              const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [pdfDocumentSize, pdfDocumentSize],
              })

              // Generate SVG content for the tag
              const svgTag = await TagRender.svg(tagData)

              // Create a virtual SVG element (you can also directly query an SVG element in the DOM)
              const svgElement = new DOMParser().parseFromString(svgTag, 'image/svg+xml').documentElement

              // Convert SVG to PDF using the jsPDF instance (svg2pdf.js automatically hooks into jsPDF)

              try {
                await pdf.svg(svgElement, {
                  x: pdfTagMargin,
                  y: pdfTagMargin,
                  width: pdfTagSize,
                  height: pdfTagSize,
                })
              } catch (e) {
                console.error(e)
              }

              // Save the PDF to a blob
              const pdfBlob = pdf.output('blob')

              // Cache the blob
              const response = new Response(pdfBlob)
              tagFileName = `${tag.id}.pdf`
              await cache.put(tagFileName, response)
            }

            const newPercentageComplete = Math.round(((j + 1) / tags.length) * 100)

            dispatch(
              updateTagDownloadPercentageComplete({ tagDownloadId: id, percentageComplete: newPercentageComplete }),
            )

          }

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'archiving' }))

          for (let k = 0; k < tags.length; k++) {
            const tag = tags[k]
            const fileName = `${tag.id}.${imageFormatOption.toLowerCase()}`
            const epsResponse = await cache.match(fileName)
            const epsBlob = await epsResponse?.blob()
            if (epsBlob) zip.file(fileName, epsBlob)
          }

          const csvString = getCsvMetadataString(tags)
          zip.file('manifest.csv', csvString)
          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'finalising' }))

          const content = await zip.generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              level: 9,
            },
          })

          const response = new Response(content)
          await cache.put(fileName, response)

          const arrayBuffer = await content.arrayBuffer()
          const buffer = Buffer.from(arrayBuffer)

          const downloadBuffer = (buffer: Buffer, fileName: string, mimeType: string) => {
            // Create a blob from the buffer
            const blob = new Blob([buffer], { type: mimeType })

            // Create an object URL for the blob
            const url = URL.createObjectURL(blob)

            // Create an anchor element and set its attributes
            const a = document.createElement('a')
            a.href = url
            a.download = fileName

            // Append the anchor to the DOM (this part is necessary for Firefox)
            document.body.appendChild(a)

            // Programmatically click the anchor to trigger the download
            a.click()

            // Remove the anchor from the DOM and revoke the object URL
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
          }

          // Example usage
          downloadBuffer(buffer, fileName, 'application/zip')

          // now delete all the tags in the cache

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'cleaning up' }))

          for (let k = 0; k < tags.length; k++) {
            const tag = tags[k]
            const fileName = `${tag.id}.${imageFormatOption.toLowerCase()}`

            const deleteResponse = await cache.delete(fileName)
            if (!deleteResponse) {
              console.error('There was a problem deleting a cache entry.')
            }
          }

          dispatch(updateTagDownloadStatus({ tagDownloadId: id, status: 'complete' }))
        }
      }
    }
  }

  const handleStartDownloadButtonClick = async (event: any) => {
    processTagDownloads()
  }

  const tagDownloadsHead = {
    cells: [
      {
        content: 'Collection',
        key: 'collectionName',
        isSortable: false,
        height: 36,
        width: 35,
      },
      {
        content: 'Archive',
        width: 10,
      },
      {
        content: 'Format',
        width: 10,
      },
      {
        content: 'Number of Fingerprints',
        width: 10,
      },
      {
        content: 'Progress',
        width: 30,
      },
      {
        content: 'Status',
        width: 15,
      },
    ],
  }

  const tagDownloadsCells =
    tagDownloads &&
    tagDownloads.map((tagDownload: TagDownload) => {
      let statusLozengeAppearance: 'success' | 'inprogress' | 'default' | 'moved' | 'new' | 'removed'
      let statusLozengeText = tagDownload.status
      const collection = collections.filter((c) => c.id === tagDownload.collectionId)[0]
      const { name: collectionName } = collection

      statusLozengeAppearance = 'default'

      const progressBar = (tagDownload: TagDownload) => {
        const { percentageComplete, status } = tagDownload

        const progress = percentageComplete / 100

        switch (status) {
          case 'archiving':
            return <ProgressBar isIndeterminate />
          default:
            return <ProgressBar value={progress} />
        }
      }

      return {
        cells: [
          {
            content: collectionName,
            key: collection.createdAt,
            height: 36,
          },
          {
            content: `${tagDownload.archiveNumber}/${tagDownload.archiveTotal}`,
            key: `${tagDownload.id}_imageFormat`,
          },
          {
            content: tagDownload.imageFormatOption,
            key: `${tagDownload.id}_imageFormat`,
          },
          {
            content: tagDownload.tagCount,
            key: `${tagDownload.id}_numberOfTags`,
          },
          {
            content: progressBar(tagDownload),
          },
          {
            content: <Lozenge appearance={statusLozengeAppearance}>{statusLozengeText}</Lozenge>,
          },
        ],
      }
    })

  const actions = (
    <ButtonGroup>
      {}
      {tagDownloads?.length && (
        <Button key={'new-tag-download-button'} onClick={handleStartDownloadButtonClick}>
          Start Downloads
        </Button>
      )}
    </ButtonGroup>
  )

  return (
    <>
      <PageHeader>Bulk Download Fingerprints</PageHeader>

      <p>Here you can bulk download fingerprints from multiple collections, directly to your system.</p>

      <p>As the fingerprints for each Collection is generated, a file download will commence. Fingerprint generation will continue in the background, so you may wish to wait until all Collections are generated before interacting with the file downloads.</p>

      <p>Note: your browser will very likely ask permission for the page to download multiple files. You will need to allow this for the downloads to work.</p>

      <br />

      <>
        <ButtonGroup>
          {
            <NewTagBatchDownloadModal
              key={'new-tag-setup-button'}
              assets={assets}
              project={project}
              collections={collections}
              tagOrders={tagOrders}
              onSubmit={onCollectionsSelected}
              isOpen={isNewTagBatchDownloadModalOpen}
              setIsOpen={setIsNewTagBatchDownloadModalOpen}
            />
          }
        </ButtonGroup>

        <PageHeader key={'page-header'} actions={actions}>
          Queued Downloads
        </PageHeader>

        <DynamicTable
          head={tagDownloadsHead}
          rows={tagDownloadsCells}
          rowsPerPage={100}
          loadingSpinnerSize="large"
          isLoading={isFetching}
          isFixedSize
          defaultSortKey="collectionName"
          defaultSortOrder="DESC"
          emptyView={<h3>Set up a new tag download to get started.</h3>}
          key={'tag-downloads-table'}
        />
      </>
    </>
  )
}

export default connect(mapStateToProps)(ProjectCollections)
